import gql from "graphql-tag";

export const CATEGORIES = gql`
  query categoriesData($slug: ID!, $categorySlug: String, $offset: Int) {
    posts(
      where: {
        categoryName: $categorySlug
        offsetPagination: { offset: $offset, size: 12 }
        status: PUBLISH
      }
    ) {
      nodes {
        dateGmt
        title
        uri
        slug
        databaseId
        readingTime
        categories(where: { exclude: "64" }) {
          edges {
            node {
              slug
              name
              uri
              parent {
                node {
                  name
                  uri
                  slug
                  parent {
                    node {
                      name
                    }
                  }
                }
              }
            }
          }
        }
        author {
          node {
            name
          }
        }
        sottotitolo {
          sottotitolo
        }
        featuredImage {
          node {
            link
            sourceUrl
            altText
            mediaDetails {
              sizes {
                height
                width
              }
            }
          }
        }
      }
      pageInfo {
        offsetPagination {
          hasMore
          hasPrevious
          total
        }
      }
    }
    category(id: $slug, idType: SLUG) {
      description
      slug
      name
      uri
      parentId
      id
      imagecategory {
        caddysbeautystar
      }
      posts(first: 4, where: { status: PUBLISH }) {
        nodes {
          dateGmt
          title
          uri
          slug
          databaseId
          readingTime
          categories(where: { exclude: "64" }) {
            edges {
              node {
                slug
                name
                uri
                parent {
                  node {
                    name
                    uri
                    slug
                    parent {
                      node {
                        name
                      }
                    }
                  }
                }
              }
            }
          }
          author {
            node {
              name
            }
          }
          sottotitolo {
            sottotitolo
          }
          featuredImage {
            node {
              link
              sourceUrl
              altText
              mediaDetails {
                sizes {
                  height
                  width
                }
              }
            }
          }
        }
      }
      children(first: 50) {
        nodes {
          name
          slug
          uri
          imagecategory {
            caddysbeautystar
          }
          posts(first: 4, where: { status: PUBLISH }) {
            nodes {
              dateGmt
              title
              uri
              slug
              databaseId
              readingTime
              categories(where: { exclude: "64" }) {
                edges {
                  node {
                    slug
                    name
                    uri
                    parent {
                      node {
                        name
                        uri
                        slug
                        parent {
                          node {
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
              author {
                node {
                  name
                }
              }
              sottotitolo {
                sottotitolo
              }
              featuredImage {
                node {
                  link
                  sourceUrl
                  altText
                  mediaDetails {
                    sizes {
                      height
                      width
                    }
                  }
                }
              }
            }
          }
        }
      }
      ancestors(first: 50) {
        edges {
          node {
            imagecategory {
              caddysbeautystar
            }
            parent {
              node {
                name
              }
            }
          }
        }
        nodes {
          id
          parentId
          uri
          name
        }
      }
      parent {
        node {
          name
          slug
        }
      }
    }
  }
`;
