import style from "./style.module.scss";
import { filterCategory } from "../../tools/filter";
import { Link } from "react-router-dom";

export default function BigCardVertical(props) {
  const date = new Date(Date.parse(props.data.dateGmt));
  return (
    <div className={style.cardContainer}>
      <Link to={props.data.uri} className={style.imageContainer}>
        <div className={style.thumbnailWrapImage}>
          <div>
            <img
              className={style.image}
              src={props.data.featuredImage?.node.sourceUrl}
              alt={props.data.featuredImage?.node.altText}
            />
          </div>
        </div>
      </Link>
      <div className={style.cardContent}>
        <div className={style.data}>
          <Link
            className={style.category}
            to={filterCategory(props.data.categories)?.uri}
          >
            {filterCategory(props.data.categories)?.name}
          </Link>
          <h6 className={style.date}>
            {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
          </h6>
        </div>
        <Link className={style.title} to={props.data.uri}>
          {props.data.title}
        </Link>
        <Link className={style.content} to={props.data.uri}>
          {props.data.sottotitolo.sottotitolo}
        </Link>
        <p className={style.duration}>{props.data.readingTime} min</p>
      </div>
    </div>
  );
}
