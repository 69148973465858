import style from "./style.module.scss";

export default function LocationCard(props) {
  return (
    <div className={style.container}>
      <div className={style.location}>
        <div className={style.iconContainer}>
          <img
            src="/assets/svgs/location.svg"
            alt="Location"
            className={style.icon}
          />
        </div>
        <div className={style.locationLabel}>{props.data.title}</div>
      </div>
      <div className={style.date}>
        <div className={style.iconContainer}>
          <img
            src="/assets/svgs/calendar.svg"
            alt="Location"
            className={style.icon}
          />
        </div>
        <p className={style.dateLabel}>{props.data.LocationsMeta.dateLocations}</p>
      </div>
    </div>
  );
}
