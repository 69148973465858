import { useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { Fragment, useState } from "react";
import { storeToken } from "../../store/actions/auth";
import { RESET } from "./querys";
import { store } from "../../store";

import style from "./style.module.scss";

export default function ResetPage(props) {
  const location = useLocation();
  const router = useHistory();
  const controllRouter = useHistory();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [succes, setSucces] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConf, setPasswordConf] = useState("");

  const [submitRESET, setSubmitRESET] = useMutation(RESET);

  const token = useSelector((state) => state.authReducer.token);

  async function handleSubmit(event) {
    event.preventDefault();

    const url = new URLSearchParams(location.search);

    setLoading(true);
    if (password !== passwordConf) {
      let err = [];
      err.push({ message: "Le password non coincidono" });
      setError(err);
      setLoading(false);
      return;
    } else {
      submitRESET({
        variables: {
          key: url.get("key"),
          login: url.get("login"),
          password,
        },
      })
        .catch((error) => {
          setError(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  if (token.length) {
    controllRouter.push("/");
  }

  if (setSubmitRESET.data) {
    router.push("/login?pr=true");
  }

  return (
    <Fragment>
      <div className={style.background}>
        <form className={style.form} action="submit" onSubmit={handleSubmit}>
          <h5 className={style.title}>Reimposta la password</h5>
          <h6 className={style.description}>
            Crea una nuova password per il tuo account
          </h6>
          {Boolean(error) && (
            <div className="error">
              <div className={style.error}>{error}</div>
            </div>
          )}
          {succes ? (
            <div className={style.succes}>
              La sua password è stata reimpostata
            </div>
          ) : null}
          <label className={style.label}>Nuova password</label>
          <input
            required="required"
            className={style.input}
            type="password"
            placeholder="Inserisci la tua nuova password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <label className={style.label}>Conferma password</label>
          <input
            required="required"
            className={style.input}
            type="password"
            placeholder="Conferma la tua nuova password"
            value={passwordConf}
            onChange={(event) => setPasswordConf(event.target.value)}
          />
          <button className={style.button} disabled={loading}>
            {loading ? (
              <svg viewBox="0 0 512 512">
                <path
                  fill="currentColor"
                  d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"
                />
              </svg>
            ) : (
              "Reimposta la password"
            )}
          </button>
        </form>
      </div>
    </Fragment>
  );
}
