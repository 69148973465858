import { useEffect, useState } from 'react';
import style from './style.module.scss';

export default function Arrow() {
  const [active, setActive] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 600 && !active) {
        setActive(true);
      } else if (window.scrollY <= 600 && active) {
        setActive(false);
      }
    });
  }, [active]);

  const isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  try {
    return (
      <div
        className={style.container}
        style={{ opacity: active ? 1 : 0 }}
        onClick={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}>
        <svg className={style.icon} viewBox='0 0 320 512'>
          <path
            fill='var(--color-background)'
            d='M177 255.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 351.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 425.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1zm-34-192L7 199.7c-9.4 9.4-9.4 24.6 0 33.9l22.6 22.6c9.4 9.4 24.6 9.4 33.9 0l96.4-96.4 96.4 96.4c9.4 9.4 24.6 9.4 33.9 0l22.6-22.6c9.4-9.4 9.4-24.6 0-33.9l-136-136c-9.2-9.4-24.4-9.4-33.8 0z'></path>
        </svg>
      </div>
    );
  } catch (err) {
    console.log(err);
  }
}
