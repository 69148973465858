import BigCardServices from "../components/Big-Card-Servies";
import SmallCardServices from "../components/Small-Card-Services";
import BigCardVertical from "../components/BigCardVertical";

export function filterCategory(categories) {
  if (categories.nodes) {
    for (const category of categories.nodes) {
      if (category.parent === null) {
        return category;
      }
    }
  } 
  
  if (categories.edges){
    for (const category of categories.edges) {
      if (category.node.parent === null) {
        return category.node;
      }
    }
  }


}

export function cardType(slug, data) {
  if (slug === "best-practice" || slug === "direttori-del-personale") {
    return <BigCardVertical data={data} />;
  } else if (slug === "formazione-hr" || slug === "servizi-hr") {
    return <BigCardServices data={data} />;
  } else {
    return <SmallCardServices data={data} />;
  }
}

export function maxSize(sizes) {
  const max = {
    height: 0,
    width: 0,
  };
  sizes.forEach((size) => {
    if (parseInt(size.width) > max.width) {
      max.height = size.height;
      max.width = size.width;
    }
  });
  return max;
}
