import style from "./big-card-vertical.module.css";
import { Link } from "react-router-dom";

export default function BigCardVertical(props) {
  return (
    <div>
      {props.data.overlay ? (
        <div className={style.cardContainer}>
          <div className={style.yellowBorder}>
            <div className={style.yellowBorderTitle}>{props.data?.label}</div>
          </div>
          <div className="position-relative">
            <div className={style.disabled}>
              <div className={style.disabledTitle}>
                {props.data.overlay?.title}
              </div>
              <div className={style.disabledContent}>
                {props.data?.overlay?.content}
              </div>
              <Link
                to={props.data?.overlay?.url}
                className={style.strumentiButton}
              >
                Iscriviti
              </Link>
            </div>
            <div className={style.imageContainer}>
              <img className="w-100" src={props.data.image}></img>
            </div>
            <div className={style.cardContent}>
              <div className={style.data}>
                <div className={style.category}>{props.data.category}</div>
              </div>
              <div className={style.title}>{props.data.title}</div>
              <div className={style.content}>{props.data.content}</div>
            </div>
          </div>
        </div>
      ) : (
        <Link to={props.data.url} className={style.cardContainer}>
          <div className={style.yellowBorder}>
            <div className={style.yellowBorderTitle}>{props.data.label}</div>
          </div>
          <div className="position-relative">
            <div className={style.imageContainer}>
              <img className="w-100" src={props.data.image}></img>
            </div>
            <div className={style.cardContent}>
              <div className={style.data}>
                <div className={style.category}>{props.data.category}</div>
              </div>
              <div className={style.title}>{props.data.title}</div>
              <div className={style.content}>{props.data.content}</div>
            </div>
          </div>
        </Link>
      )}
    </div>
  );
}
