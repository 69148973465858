import { TFR_API_URL } from "../../../config";
import { useState } from "react";
import style from "./style.module.css";

import { uuidv4 } from "../../../tools/helpers";

import { useSelector } from "react-redux";

export default function Conclusions({ config, module }) {
  const [state, setState] = useState({});
  const [submited, setSubmited] = useState(false);

  const userId = useSelector((state) => state.authReducer.id);

  const handleChange = (value, key) => {
    const temp = { ...state };
    temp[key] = value;
    setState(temp);
  };

  const handleSubmit = () => {
    const uuid = window.localStorage.getItem("tfr_token");

    if (!uuid) {
      uuid = uuidv4();
      window.localStorage.setItem("tfr_token", uuid);
    }

    const formName = `Conclusioni: Capitolo ${module.data?.tFRModule?.parent.node.menuOrder}`;
    const formType = "Survey form";

    const formData = new FormData();
    formData.append(
      "chapter_id",
      module.data?.tFRModule?.parent.node.databaseId
    );
    formData.append(
      "chapter_order",
      module.data?.tFRModule?.parent.node.menuOrder
    );
    formData.append("chapter_answers", JSON.stringify(state));
    formData.append("userId", userId);

    fetch(`${TFR_API_URL}/chapterdata/${uuid}`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setSubmited(true);

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "tfr_invio_form",
          form_name: formName,
          form_type: formType,
        });
      });
  };

  return (
    <div className={style.container}>
      {config.questions.map((question, qIndex) => (
        <div key={qIndex} className={style.questionWrapper}>
          <p className={style.questionTitle}>{question.title}</p>
          <div className={style.optionsWrapper}>
            {question.options.map((option, oIndex) => (
              <div
                key={oIndex}
                className={`${style.optionWrapper} ${
                  submited &&
                  option === question.options[question.solution] &&
                  style.success
                } ${
                  submited &&
                  state[question.title] === option &&
                  option !== question.options[question.solution] &&
                  style.mistake
                }`}
              >
                <input
                  className={style.indicator}
                  type="radio"
                  name={question.title}
                  id={option}
                  checked={state[question.title] === option}
                  onChange={() => handleChange(option, question.title)}
                  disabled={submited}
                  required
                />
                <label className={style.label} htmlFor={option}>
                  {option}
                </label>
              </div>
            ))}
          </div>
        </div>
      ))}
      {!submited && (
        <div className={style.buttonsContainer}>
          <button
            className={style.prevButton}
            type="submit"
            onClick={() => handleSubmit()}
            disabled={Object.keys(state).length < config.questions.length}
          >
            Controlla le risposte
          </button>
        </div>
      )}
    </div>
  );
}
