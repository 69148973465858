import {
  SET_NOTIFICATIONS,
  UPDATE_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
  MARK_NOTIFICATION_AS_READ,
} from "../actions/notificationsActions.js";

const initialState = [];

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return action.payload;
    case MARK_NOTIFICATION_AS_READ:
      return state.map((notification) =>
        notification.id === action.payload
          ? { ...notification, is_read: 1 }
          : notification
      );

    case UPDATE_NOTIFICATION:
      return state.map((notification) =>
        notification.id === action.payload.id ? action.payload : notification
      );
    case CLEAR_NOTIFICATIONS:
      return [];
    default:
      return state;
  }
};

export default notificationsReducer;
