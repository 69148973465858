import style from "./style.module.css";
import { useRef } from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Header(props) {
  //   const link = props.data.data.tFRModules.parent.nodes[0];

  const [chapter, setChapter] = useState();
  const [active, setActive] = useState();
  const chapterRef = useRef();
  const activeRef = useRef();
  const data = props.data.data?.tFRModules?.nodes?.filter(
    (chapter) => chapter.chapterStatus === "active"
  );

  useEffect(() => {
    const activeIndex =
      data?.findIndex((chapter) => props.state.chapter == chapter.menuOrder) ||
      0;

    const activeChapterElement = chapterRef.current?.children?.[activeIndex];

    chapterRef.current.scrollLeft =
      activeChapterElement?.offsetLeft -
      chapterRef.current?.clientWidth / 2 +
      activeChapterElement?.clientWidth / 2 -
      10;
  }, []);

  return (
    <div className={style.headerWrapper}>
      <div className={style.chaptersWrapper} ref={chapterRef}>
        {data?.map((chapter, chIndex) => (
          <div
            key={chIndex}
            className={`${style.chapterWrapper} ${
              props.state.chapter == chapter.menuOrder && style.activeChapter
            } ${chapter.menuOrder <= props.state.chapter && style.completed}`}
          >
            <Link to={chapter.children.nodes[0].uri.replace(/\/$/g, "")}>
              <div className={style.chapterLinkWrapper}>
                <span className={style.chapterLink}>{chapter.title}</span>
              </div>
            </Link>
          </div>
        ))}
      </div>
      <div className={style.modulesHolder}>
        <div className={style.modulesWrapper}>
          {props.data.data?.tFRModules?.nodes[
            props.state.chapter - 1
          ]?.children.nodes.map((module, mIndex) => (
            <div
              key={mIndex}
              className={`${style.moduleWrapper} ${
                module.menuOrder <= props.state.module && style.passed
              }`}
            >
              <span className={style.number}>{module.menuOrder}</span>
              <div className={style.moduleContent}>
                <Link to={module.uri.replace(/\/$/g, "")}>
                  <p className={style.moduleTitle}>
                    {module?.shortcodeName != "conclusion"
                      ? `Modulo ${module.menuOrder}`
                      : `Conclusioni`}
                  </p>

                  {module?.shortcodeName != "conclusion" && (
                    <p
                      className={`${style.moduleContent} ${style.stepperTitle}`}
                    >
                      {module.title}
                    </p>
                  )}
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
