import gql from "graphql-tag";

export const CATEGORIES = gql`
  query jobsData($offset: Int) {
    jobs(
      where: {
        offsetPagination: { offset: $offset, size: 12 }
        status: PUBLISH
      }
    ) {
      nodes {
        title
        company
        uri
        dateGmt
        featuredImage {
          node {
            altText
            sourceUrl
          }
        }
      }
      pageInfo {
        offsetPagination {
          hasMore
          hasPrevious
          total
        }
      }
    }
  }
`;
