import style from "./style.module.scss";

export default function BookCard(props) {
  return (
    <a href={`/ebook/${props.data.slug}`}>
      <div className={style.cardContainer}>
        <div className={style.imageContainer}>
          <img
            className={style.image}
            src={props.data.EbooksMeta.ebookImage.sourceUrl}
            alt={props.data.EbooksMeta.ebookImage.altText}
          />
          <a className={style.title} href={`/ebook/${props.data.slug}`}>
            {props.data.title}
          </a>
        </div>
        <div className={style.cardContent}>
          <a className={style.description} href={`/ebook/${props.data.slug}`}>
            {props.data.EbooksMeta.ebookSottotitolo}
          </a>
          <a href={`/ebook/${props.data.slug}`} className={style.icon}>
            <svg viewBox="0 0 128 128">
              <g>
                <path d="m24.4 59.4c10-2.8 20.6-2.8 30.6 0 .9.3 1.9-.3 2.2-1.2s-.3-1.9-1.2-2.1c-10.6-3-21.9-3-32.5 0-.9.3-1.5 1.2-1.2 2.2.2.8 1.1 1.4 2.1 1.1z" />
                <path d="m24.4 47.4c10-2.8 20.6-2.8 30.6 0 .9.3 1.9-.3 2.2-1.2s-.3-1.9-1.2-2.1c-10.6-3-21.9-3-32.5 0-.9.3-1.5 1.2-1.2 2.2.2.9 1.1 1.4 2.1 1.1z" />
                <path d="m24.4 35.5c10-2.8 20.6-2.8 30.6 0 .9.3 1.9-.3 2.2-1.2s-.3-1.9-1.2-2.1c-10.6-3-21.9-3-32.5 0-.9.3-1.5 1.2-1.2 2.2.2.8 1.1 1.3 2.1 1.1z" />
                <path d="m24.4 71.4c10-2.8 20.6-2.8 30.6 0 .9.3 1.9-.3 2.2-1.2s-.3-1.9-1.2-2.1c-10.6-3-21.9-3-32.5 0-.9.3-1.5 1.2-1.2 2.2.2.8 1.1 1.4 2.1 1.1z" />
                <path d="m24.4 83.4c10-2.8 20.6-2.8 30.6 0 .9.3 1.9-.3 2.2-1.2s-.3-1.9-1.2-2.1c-10.6-3-21.9-3-32.5 0-.9.3-1.5 1.2-1.2 2.2.2.8 1.1 1.3 2.1 1.1z" />
                <path d="m73 59.4c10-2.8 20.6-2.8 30.6 0 .9.3 1.9-.3 2.2-1.2s-.3-1.9-1.2-2.1c-10.6-3-21.9-3-32.5 0-.9.3-1.5 1.2-1.2 2.2.3.8 1.2 1.4 2.1 1.1z" />
                <path d="m73 47.4c10-2.8 20.6-2.8 30.6 0 .9.3 1.9-.3 2.2-1.2s-.3-1.9-1.2-2.1c-10.6-3-21.9-3-32.5 0-.9.3-1.5 1.2-1.2 2.2.3.9 1.2 1.4 2.1 1.1z" />
                <path d="m73 35.5c10-2.8 20.6-2.8 30.6 0 .9.3 1.9-.3 2.2-1.2s-.3-1.9-1.2-2.1c-10.6-3-21.9-3-32.5 0-.9.3-1.5 1.2-1.2 2.2.3.8 1.2 1.3 2.1 1.1z" />
                <path d="m73 71.4c10-2.8 20.6-2.8 30.6 0 .9.3 1.9-.3 2.2-1.2s-.3-1.9-1.2-2.1c-10.6-3-21.9-3-32.5 0-.9.3-1.5 1.2-1.2 2.2.3.8 1.2 1.4 2.1 1.1z" />
                <path d="m104.6 80c-10.6-3-21.9-3-32.5 0-.9.3-1.5 1.2-1.2 2.2.3.9 1.2 1.4 2.1 1.2 10-2.8 20.6-2.8 30.6 0 .9.3 1.9-.3 2.2-1.2.3-1-.3-1.9-1.2-2.2z" />
                <path d="m121.7 30.4h-7.3v-5.3c0-.6-.3-1.2-.9-1.5-.4-.2-10.1-5.8-25.2-5.8-8.4-.1-16.7 1.7-24.3 5.3-7.6-3.6-15.9-5.4-24.3-5.3-15.2 0-24.8 5.6-25.2 5.8-.5.3-.9.9-.9 1.5v5.3h-7.3c-1 0-1.8.8-1.8 1.8v71.9c0 1 .8 1.7 1.7 1.8h49.1c.8 2.6 3.1 4.3 5.8 4.3h5.6c2.7 0 5-1.8 5.8-4.3h49.1c1 0 1.7-.8 1.8-1.7v-72c0-1-.7-1.8-1.7-1.8zm-10.8-4.2v68c-7.1-3-14.8-4.6-22.6-4.5-7.8-.1-15.4 1.5-22.6 4.5v-68c7.1-3.3 14.8-4.9 22.6-4.9s15.6 1.7 22.6 4.9zm-93.8 0c7.1-3.3 14.8-4.9 22.6-4.9 7.8-.1 15.5 1.6 22.6 4.9v68c-7.1-3-14.8-4.6-22.6-4.5-7.8-.1-15.4 1.5-22.6 4.5zm102.8 76.1h-48.8c-1 0-1.7.8-1.8 1.8 0 1.4-1.2 2.6-2.6 2.6h-5.6c-1.4 0-2.6-1.2-2.6-2.6 0-1-.8-1.7-1.8-1.8h-48.6v-68.4h5.5v63.1c0 1.4 1.5 2.2 2.6 1.5.1-.1 9.2-5.3 23.5-5.3 14.2 0 23.4 5.3 23.5 5.3.5.3 1.3.3 1.8 0 .1-.1 9.2-5.3 23.5-5.3 14.2 0 23.4 5.3 23.5 5.3 1.2.7 2.6-.2 2.6-1.5v-63.1h5.5z" />
              </g>
            </svg>
          </a>
        </div>
      </div>
    </a>
  );
}
