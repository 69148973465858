import { useQuery } from "@apollo/client";
import { Fragment } from "react";
import { useRouteMatch } from "react-router-dom";
import lodash from "lodash";

import Slider from "../../components/Slider";
import VideoCard from "../../components/VideoCard";
import LinkSection from "../../components/LinkSection";
import BigCardVertical from "../../components/BigCardVertical";
import Breadcrumb from "../../components/BreadcrumbSpecial";
import Error from "../../components/Error";

import { PODCAST } from "./querys";

import style from "./style.module.scss";
import PodcastCorrelati from "./podcastCorelati";

export default function PodcastPage() {
  const router = useRouteMatch();

  const slug = lodash.nth(lodash.compact(router.url.split("/")), -1);

  const podcast = useQuery(PODCAST, {
    variables: { slug },
  });

  if (podcast.loading) {
    return null;
  }

  if (!podcast.data.podcast) {
    return <Error />;
  }

  const podcastData = podcast.data.podcast;

  let okPost = podcastData.content;
  const date = new Date(Date.parse(podcastData.dateGmt));
  const category = podcastData.categories.nodes.find(
    (category) => category.parent
  );
  const categories = [
    { name: "Podcast", url: "/podcast" },
    { name: category.name, url: `/podcast/tema/${category.slug}` },
    { name: podcastData.title, url: podcastData.slug },
  ];

  const postsData = podcast.data.posts.nodes;

  const videoUltimiData = podcast.data.videos.nodes;

  return (
    <Fragment>
      <div className="container pt-4">
        <Breadcrumb categories={categories} />
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className={style.videoContainer}>
              <div className={style.container}>
                <img
                  className={style.image}
                  src={podcastData.featuredImage.node.sourceUrl}
                  alt={podcastData.featuredImage.node.altText}
                />
              </div>
              <a
                className={style.playIcon}
                target="_blank"
                rel="noreferrer"
                href={podcastData.podcast_meta.podcastAudioUrl}
              >
                <img
                  src="/assets/svgs/podcast.svg"
                  alt="icon"
                  height="24"
                  width="16"
                />
              </a>
            </div>
            <div className={`${style.doubleSideFlex} pb-3 pt-4`}>
              <a
                className={style.category}
                href={`/podcast/tema/${podcastData.categories.nodes[0].slug}`}
              >
                {podcastData.categories.nodes[0].name}
              </a>

              <h6 className={style.date}>
                {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
              </h6>
            </div>
            <h1 className={style.mainTitle}>{podcastData.title}</h1>

            <div
              className={style.postWraper}
              dangerouslySetInnerHTML={{
                __html: okPost,
              }}
            />
            <div className={style.tagContainer}>
              {podcastData.tags.nodes.map((tag) => (
                <a
                  className={style.tag}
                  href={`/tag/${tag.slug}`}
                  key={tag.slug}
                >
                  {tag.name}
                </a>
              ))}
            </div>
          </div>
          <PodcastCorrelati
            slug={podcastData.categories.nodes[0].slug}
            id={podcastData.podcastId}
          />
        </div>
        <div className="row">
          <div className={style.doubleSideFlex}>
            <h2 className={style.sectionTitle}>Articoli correlati</h2>
            <a
              className={`${style.moreArticles} d-none d-lg-block`}
              href="/tema"
            >
              Vedi altri articoli
            </a>
          </div>
          <Slider className="row" more="/tema">
            {postsData.map((data) => (
              <div className="col-12 col-md-4 my-2" key={data.slug}>
                <BigCardVertical data={data} />
              </div>
            ))}
          </Slider>
        </div>
        <div className="row py-5">
          <div className={style.doubleSideFlex}>
            <h2 className={style.sectionTitle}>Ultimi video</h2>
            <a
              className={`${style.moreArticles} d-none d-lg-block`}
              href="/video"
            >
              Vedi altri video
            </a>
          </div>
          <Slider className="row m-0" more="/video">
            {videoUltimiData.map((data) => (
              <div className="col-12 col-lg-4 my-2" key={data.slug}>
                <VideoCard data={data} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="mt-5">
        <LinkSection book video />
      </div>
    </Fragment>
  );
}
