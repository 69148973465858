import { Link } from "react-router-dom";

import style from "./style.module.scss";

export default function Footer() {
  return (
    <div className={style.container}>
      <div className="container">
        <div className="d-flex">
          <img
            src="/assets/svgs/logo-white.svg"
            alt=""
            className={style.logo}
          />
          <img
            className={style.logo}
            style={{ marginLeft: "32px" }}
            src="/assets/svgs/dmo-logo-white.svg"
            alt=""
          />
        </div>
        <div className="row">
          <div className="col-12 col-lg-3">
            <p className={style.paragraph}>
              Laborability for nasce con l'obiettivo di portare l'autorevolezza
              di laborability.com dentro le comunità aziendali, per collaborare
              alla creazione di una comunicazione efficace, che sappia
              informare, consigliare, coinvolgere e anche divertire.
            </p>
          </div>
          <div className="col-12 my-5 col-lg-3 my-lg-0 offset-lg-1">
            <p className={style.paragraph}>
              Laborability for è la piattaforma di comunicazione scelta dal
              Gruppo DMO per condividere notizie, storie e iniziative attraverso
              articoli, interviste e video. Attraverso un linguaggio semplice e
              coinvolgente, adatto a tutti.
            </p>
          </div>
          <div className="col-6 col-lg-1 offset-lg-1">
            <Link to="/privacy-policy" className={style.link}>
              Privacy Policy
            </Link>
          </div>
          <div className="col-6 col-lg-1 offset-lg-1"></div>
        </div>
        <div className={style.bottom}>
          <p className={style.content}>
            LABORABILITY FOR ® è un progetto di GIANLUCA SPOLVERATO | P.IVA
            IT03471600282 |{" "}
            <Link to="/privacy-policy" className={style.bottomLink}>
              Privacy Policy
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
