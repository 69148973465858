import BreadCrumb from "../../components/Breadcrumb";
import Slider from "../../components/Slider";
import BigCardVertical from "../../components/BigCardVertical";

import style from "./style.module.scss";
import { useQuery } from "@apollo/client";
import { QUERY_ARTICOLI_PIU_LETTI } from "./querys";

export default function ArticoliPiuLetti() {
  const { data, loading } = useQuery(QUERY_ARTICOLI_PIU_LETTI);

  if (loading) {
    return null;
  }

  return (
    <div className="container pt-4">
      <BreadCrumb uri="/Ultimi-articoli" />
      <h1 className={style.mainTitle}>Ultimi articoli</h1>
      <Slider className="row pb-4">
        {data.posts.nodes.map((postData) => (
          <div className="col-12 col-md-6 col-lg-4 my-5" key={postData.slug}>
            <BigCardVertical data={postData} />
          </div>
        ))}
      </Slider>
    </div>
  );
}
