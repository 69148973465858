import { useQuery } from "@apollo/client";
import { Fragment } from "react";

import Slider from "../../components/Slider";
import SmallCardVertical from "../../components/SmallCardVertical";
import { ARTICOLI_CORELATI } from "./querys";

import style from "./style.module.scss";
import UltimiArticoli from "./ultimiArticoli";

export default function ArticoliCorelati(props) {
  const { id, category } = props;
  const articoliCorelati = useQuery(ARTICOLI_CORELATI, { id, category });

  if (articoliCorelati.loading) {
    return null;
  }

  const articoliCorelatiData = articoliCorelati.data.posts.nodes;

  return articoliCorelatiData.length === 0 ? null : (
    <Fragment>
      <h2 className={style.sectionTitle}>Articoli correlati</h2>
      <Slider className={style.verticalFlex}>
        {articoliCorelatiData.map((data) => (
          <div className="my-3" key={data.slug}>
            <SmallCardVertical data={data} desktop />
          </div>
        ))}
      </Slider>
      <UltimiArticoli
        ids={[...articoliCorelatiData.map((post) => post.databaseId), id]}
      />
    </Fragment>
  );
}
