import { Link } from "react-router-dom";

import style from "./style.module.scss";

export default function VideoCard(props) {
  return (
    <div className={style.container}>
      <div className={style.imageContainer}>
        <Link to={props.data.uri}>
          <img
            className={style.image}
            src={props.data.VideosMeta?.videoImage.sourceUrl}
            alt={props.data.VideosMeta?.videoImage.altText}
          />
        </Link>
      </div>
      <p className={style.title}>{props.data.title}</p>
      <div className={style.content}>
        <img
          src="/assets/svgs/video-icon.svg"
          alt="Icon"
          className={style.icon}
        />
        <p className={style.hoverTitle}>
          {props.data.VideosMeta.sottotitolo}
        </p>
        <p className={style.hoverDescription}>
          {props.data.VideosMeta.description}
        </p>
      </div>
      <Link to={props.data.uri} className={style.more}>
        GUARDA IL VIDEO
      </Link>
    </div>
  );
}
