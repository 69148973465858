import gql from "graphql-tag";

export const TFR_URIS = gql`
  query TfrUrisQuery {
    tFRModules(
      where: { parent: 0, orderby: { field: MENU_ORDER, order: ASC } }
    ) {
      nodes {
        children(where: { orderby: { field: MENU_ORDER, order: ASC } }) {
          nodes {
            ... on TFRModule {
              uri
            }
          }
        }
      }
    }
  }
`;

export const TFR_HEADER_DATA = gql`
  query TfrUrisQuery {
    tFRModules(
      where: { parent: 0, orderby: { field: MENU_ORDER, order: ASC } }
    ) {
      nodes {
        title
        menuOrder
        chapterStatus
        shortcodeName
        children(where: { orderby: { field: MENU_ORDER, order: ASC } }) {
          nodes {
            ... on TFRModule {
              uri
              menuOrder
              title
              shortcodeName
            }
          }
        }
      }
    }
  }
`;

export const TFR_MODULE_QUERY = gql`
  query TfrSingleModule($slug: ID!) {
    tFRModule(id: $slug, idType: URI) {
      title
      menuOrder
      moduleLength
      content
      shortcodeName
      shortcodeConfig
      showNewsletterForm
      showLeadGenerationForm
      databaseId
      moduleNavs {
        prev
        next
      }
      seo {
        title
        metaDesc
      }
      parent {
        node {
          ... on TFRModule {
            menuOrder
            databaseId
            title
          }
        }
      }
    }
  }
`;
