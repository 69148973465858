import gql from "graphql-tag";

export const POST_DATA = gql`
  query postData($slug: ID!) {
    post(id: $slug, idType: SLUG) { 
      title
      postId
      slug
      dateGmt
      databaseId
      readingTime
      uri
      comment_foto { 
        commentFoto
      }
      Link_Mondo_HR {
        linkMondoHr
      }
      categories(where: { exclude: "64" }) {
        edges {
          isPrimary
          node {
            name
            slug
            parentId
            id
            databaseId
            parentDatabaseId
            uri
            categoryId
            imagecategory {
              caddysbeautystar
            }
            parent {
              node {
                categoryId
                name
                slug
                parent {
                  node {
                    parent {
                      node {
                        databaseId
                        name
                        slug
                        uri
                      }
                    }
                    name
                    uri
                  }
                }
              }
            }
          }
        }
        nodes {
          name
          slug
        }
      }
      sottotitolo {
        sottotitolo
      }
      featuredImage {
        node {
          altText
          link
          sourceUrl
          mediaDetails {
            sizes {
              height
              width
            }
          }
        }
      }
      tags(first: 30) {
        nodes {
          name
          slug
        }
      }
      content
    }
  }
`;

export const ARTICOLI_CORELATI = gql`
  query articoliCorelati($id: [ID], $category: String) {
    posts(
      first: 3
      where: { notIn: $id, categoryName: $category, status: PUBLISH }
    ) {
      nodes {
        dateGmt
        title
        uri
        slug
        databaseId
        readingTime
        categories(where: { exclude: "64" }) { 
          edges {
            node {
              slug
              name
              uri
              parent {
                node {
                  name
                  uri
                  slug
                  parent {
                    node {
                      name
                    }
                  }
                }
              }
            }
          }
        }
        author {
          node {
            name
          }
        }
        sottotitolo {
          sottotitolo
        }
        featuredImage {
          node {
            link
            sourceUrl
            altText
            mediaDetails {
              sizes {
                height
                width
              }
            }
          }
        }
      }
    }
  }
`;

export const ULTIMI_ARTICOLI = gql`
  query ultimiArticoli($ids: [ID]) {
    posts(first: 3, where: { notIn: $ids, status: PUBLISH }) { 
      nodes {
        dateGmt
        title
        uri
        slug
        databaseId
        readingTime
        categories(where: { exclude: "64" }) {
          edges {
            node {
              slug
              name
              uri
              parent {
                node {
                  name
                  uri
                  slug
                  parent {
                    node {
                      name
                    }
                  }
                }
              }
            }
          }
        }
        author {
          node {
            name
          }
        }
        sottotitolo {
          sottotitolo
        }
        featuredImage {
          node {
            link
            sourceUrl
            altText
            mediaDetails {
              sizes {
                height
                width
              }
            }
          }
        }
      }
    }
  }
`;
