import style from './style.module.css';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useEffect, useState, useLayoutEffect } from 'react';
import { map, update } from 'lodash';

Chart.register(ChartDataLabels);
const formater = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
});

export default function ChartsInAzienda({ data, reset }) {
    
    const chartOptions = (isVertical) => ({
        plugins: {
            legend: {
                display: false,
                labels: {
                    color: 'rgb(255, 99, 132)',
                },
            },
            formatter: function (value, context) {
                return Math.round(value * 100);
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return new Intl.NumberFormat('it-IT', {
                            style: 'currency',
                            currency: 'EUR',
                            maximumFractionDigits: 0,
                            minimumFractionDigits: 0,
                        }).format(tooltipItem.raw);
                    },
                },
            },
            datalabels: {
                // display: isVertical ? true : false,
                formatter: function (value, context) {
                    return Math.round(value * 100);
                },
                anchor: (context) => {
                    const anchor = [];
                    if (context.dataset.data[context.dataIndex] >= 0) {
                        anchor.push('end');
                    } else {
                        anchor.push('start');
                    }
                    return anchor;
                },
                align: (context) => {
                    const align = [];
                    if (context.dataset.data[context.dataIndex] >= 0) {
                        align.push('end');
                    } else {
                        align.push('start');
                    }
                    return align;
                },
                rotation: isVertical ? 360 : -90,
                color: '#FE777B',
                formatter: function (value) {
                    return formater.format(value);
                },
                labels: {
                    display: false,
                    value: {
                        color: '#000000',
                    },
                },
                font: {
                    weight: '500',
                    family: 'Montserrat',
                    size: 9.5,
                },
            },
        },
        responsive: true,
        interaction: {
            intersect: true,
        },
    
        // indexAxis: isVertical ? 'x' : 'y',
        scales: {
            yaxis: {
                id: 'bar-stack',
                beginAtZero: true,
                title: {
                    display: false,
                    text: 'psd',
                },
    
                suggestedMin: data.body.graph.min_value,
                suggestedMax: data.body.graph.max_value,
    
                ticks: {
                    beginAtZero: true,
                    // stepSize: 4500,
                    callback: function (value) {
                        return formater.format(value);
                    },
                    padding: 10,
                    color: '#BCBCBC',
                    font: {
                        weight: 600,
                        family: 'Montserrat',
                    },
                },
                grid: {
                    ticks: {
                        show: false,
                    },
                    drawBorder: true,
                    borderDash: [7, 4],
                    circular: true,
                    color: '#f3f3f3',
                    color: function (context) {
                        if (context.type === 'tick') {
                            if (context.tick.value === 0) {
                                return '#C4C4C4';
                            } else if (context.tick.value < 0) {
                                return '#f3f3f3';
                            } else if (context.tick.value > 0) {
                                return '#f3f3f3';
                            }
                        }
                        return '#f3f3f3';
                    },
                },
            },
            xaxis: {
                display: isVertical ? true : false,
                ticks: {
                    show: false,
                    color: '#BCBCBC',
                    font: {
                        wheight: '600',
                        size: 12,
                        family: 'Montserrat',
                    },
                    padding: 15,
                },
                grid: {
                    drawBorder: false,
                    // borderWidth: [2],
                    color: '#f3f3f3',
                    opacity: '0.4',
                },
                gridLines: {
                    zeroLineColor: 'transparent',
                },
            },
        },
    });    

    const [isVertical, setIsVertical] = useState(true);
    const barWidth = isVertical ? 60 : 20;
    let chartData = {
        labels: ['', '', ''],
        datasets: [
            {
                label: '',
                data: data.body.graph.graph_data[0] ?? [], //['100273', '79946', '-38353'],
                borderRadius: 8,
                backgroundColor: ['#024FCC', '#0263FF', '#E9B623'],
                borderColor: ['#024FCC', '#0263FF', '#E9B623'],
                borderWidth: 1,
                barThickness: barWidth,
            },
        ],
    };

    const [actions, setActions] = useState({});
    const handleChange = (event) => setIsVertical(event.matches);
    useLayoutEffect(() => {
        const watcher = window.matchMedia('(min-width: 768px)');
        handleChange(watcher);
        watcher.addEventListener('change', handleChange);
        return () => watcher.removeEventListener('change', handleChange);
    }, []);
    return (
        <div className={`${style.container} container`}>
            <div className={`${style.bgWrapper} `}>
                <div className={`${style.title}`}>
                    Simula il calcolo del tuo TFR in azienda
                </div>
                <div className={`${style.subtitle}`}>
                    In base ai dati che ci hai fornito, alle ipotesi su crescita
                    della RAL e inflazione e agli attuali parametri di legge,
                    questo è un possibile scenario sul tuo TFR.
                </div>
                <div className={`${style.legendWrapper}`}>
                    <div className={`${style.legendContainer}`}>
                        <div className={`${style.legendContent}`}>
                            <div className={`${style.legendNetto}`}>
                                TFR lordo:{' '}
                                <span className={`${style.netto}`}>
                                    <b>
                                        {' '}
                                        {formater.format(
                                            data.body.graph.raw_values.tfr_lordo
                                        )}{' '}
                                    </b>
                                </span>
                            </div>
                            <div className={`${style.divider}`}></div>
                            <div className={`${style.legendNetto}`}>
                                TFR netto:{' '}
                                <span className={`${style.netto}`}>
                                    <b>
                                        {' '}
                                        {formater.format(
                                            data.body.graph.raw_values.tfr_netto
                                        )}{' '}
                                    </b>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={`${style.legendSubtitle} `}>
                        <div className={`${style.wrap}`}>
                            <div
                                className={`${style.legendSquare}`}
                                style={{ background: '#0263FF' }}></div>
                            <div className={`${style.legendNetto} me-4`}>
                                Capitale versato:{' '}
                                <span className={`${style.netto1}`}>
                                    <b>
                                        {' '}
                                        {formater.format(
                                            data.body.graph.raw_values
                                                .capitale_versato
                                        )}{' '}
                                    </b>
                                </span>
                            </div>
                        </div>
                        <div className={`${style.wrap}`}>
                            <div
                                className={`${style.legendSquare}`}
                                style={{ background: '#3582FF' }}></div>
                            <div className={`${style.legendNetto} me-4`}>
                                Rivalutazione:{' '}
                                <span className={`${style.netto1}`}>
                                    <b>
                                        {' '}
                                        {formater.format(
                                            data.body.graph.raw_values
                                                .rivalutazione
                                        )}{' '}
                                    </b>
                                </span>
                            </div>
                        </div>
                        <div className={`${style.wrap}`}>
                            <div
                                className={`${style.legendSquare}`}
                                style={{ background: '#E9B623' }}></div>
                            <div className={`${style.legendNetto}`}>
                                Tasse da versare:{' '}
                                <span className={`${style.netto1}`}>
                                    <b>
                                        {' '}
                                        {formater.format(
                                            data.body.graph.raw_values
                                                .tasse_da_versare
                                        )}{' '}
                                    </b>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {isVertical ? (
                    <Bar
                        key='vertical'
                        id='chart'
                        data={chartData}
                        options={chartOptions(true)}
                        width={'400'}
                        height={'150'}
                    />
                ) : (
                    <Bar
                        key='horizontal'
                        id='chart'
                        data={chartData}
                        options={chartOptions(false)}
                        width={'150'}
                        height={'100%'}
                    />
                )}
                <div className={`${style.text}`}>
                    Il capitale versato più la rivalutazione corrispondono al
                    TFR lordo. Sottraendo le tasse da versare al momento della
                    liquidazione (cioè quando terminerà il rapporto di lavoro),
                    si ottiene il TFR netto.
                </div>
                <div className={`${style.buttonWrapper}`}>
                    <button
                        className={`${style.calcolaButton} cursor-pointer btn btn-lg btn-block btn-primary`}
                        onClick={reset}>
                        Ricalcola
                    </button>
                </div>
            </div>
        </div>
    );
}
