import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyCd5JPbNMboHqm4iNYOv1L8CqnglVV-L1Q',
  authDomain: 'laborability-demo.firebaseapp.com',
  projectId: 'laborability-demo',
  storageBucket: 'laborability-demo.appspot.com',
  messagingSenderId: '804096349053',
  appId: '1:804096349053:web:c0db46198a1de6d1f57214',
  measurementId: 'G-8MQ64DHVJB',
};

export const app = initializeApp(firebaseConfig);
