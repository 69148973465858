import gql from "graphql-tag";

export const TFR_DATA = gql`
  query MyQuery {
    tFRModules(where: {parent: 0, orderby: {field: MENU_ORDER, order: ASC}}) {
      nodes {
        menuOrder
        databaseId
        title
        excerpt
        moduleLength
        chapterStatus
        featuredImage{
          node{
            sourceUrl
            altText
          }
        }        
        children(where: {orderby: {field: MENU_ORDER, order: ASC}}) {
          nodes {
            ... on TFRModule {
              menuOrder
              databaseId
              title
              content
              moduleLength
              shortcodeName
              uri
              featuredImage {
                node {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  }
`;