import { useQuery } from "@apollo/client";

import SmallCardMinimal from "../../components/Small-Card-Minimal-Logo";

import { PODCAST_CORELATI } from "./querys";

import style from "./style.module.scss";

export default function PodcastCorrelati(props) {
  const { slug, id } = props;
  const podcastCorelati = useQuery(PODCAST_CORELATI, {
    variables: {
      id,
      slug,
    },
  });

  if (podcastCorelati.loading) {
    return null;
  }

  const podcastCorrelatiData = podcastCorelati.data.podcasts.nodes;

  return (
    <div className={`${style.postColumn} col-12 col-lg-4`}>
      <h5 className={style.sectionTitle}>Podcast correlati</h5>
      <div className={style.verticalFlex}>
        {podcastCorrelatiData.map((data) => (
          <div className="my-3" key={data.slug}>
            <SmallCardMinimal data={data} podcast />
          </div>
        ))}
      </div>
    </div>
  );
}
