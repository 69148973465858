import style from "./style.module.scss";

export default function PrivacyPolicy() {
  return (
    <div className="container">
      <h1 className={style.title}>Privacy policy</h1>
      <div className={style.content}>
        <p>
          Informativa ai sensi dell’art. 13 del Regolamento UE 2016/679 – GDPR
        </p>

        <h3>1. TITOLARE DEL TRATTAMENTO</h3>

        <p>
          Il Titolare del trattamento è la società Laborability S.r.l. con sede
          legale in Padova (PD), Via Rismondo n. 2/E (di seguito “Il Titolare o
          la Società”). I dati di contatto del Titolare sono: Telefono: 049 798
          5454 – e-mail:{" "}
          <a href="mailto:laborability@pec.laborability.com">
            laborability@pec.laborability.com
          </a>
        </p>

        <p>
          Il Titolare potrà raccogliere direttamente i suoi dati attraverso il
          sito internet www.laborability.com , nonché a seguito del caricamento
          dei dati personali sulla piattaforma collegata a detto sito. I dati
          saranno trattati dal Titolare quale Titolare autonomo.
        </p>
        <h3>2. CATEGORIE DI DATI PERSONALI TRATTATI</h3>
        <p>
          Per la <b>registrazione</b> al sito internet verranno raccolti i
          seguenti dati, di cui alcuni obbligatori e necessari:
        </p>
        <p>
          A. Nome, cognome, nome azienda, ruolo aziendale, numero di cellulare,
          telefono, indirizzo, Provincia, Citta, CAP, P.Iva, Codice Fiscale,
          Codice SDI, E-mail PEC (dati “<b>comuni</b>”);
        </p>
        <b>Dati di navigazione</b>
        <p>
          I sistemi informatici del Sito raccolgono alcuni Dati Personali la cui
          trasmissione è implicita nell’uso dei protocolli di comunicazione di
          Internet. Si tratta di informazioni che non sono raccolte per essere
          associate a te, ma che per loro stessa natura potrebbero, attraverso
          elaborazioni ed associazioni con dati detenuti da terzi, permettere di
          identificarti. Tra questi ci sono gli indirizzi IP o i nomi a dominio
          dei dispositivi utilizzati per connetterti al Sito, gli indirizzi in
          notazione URI (Uniform Resource Identifier) delle risorse richieste,
          l’orario della richiesta, il metodo utilizzato nel sottoporre la
          richiesta al server, la dimensione del file ottenuto in risposta, il
          codice numerico indicante lo stato della risposta data dal server
          (buon fine, errore, ecc.) ed altri parametri relativi al tuo sistema
          operativo e ambiente informatico. Questi dati vengono utilizzati al
          fine di ricavare informazioni statistiche anonime sull’uso del Sito e
          per controllarne il corretto funzionamento; per permettere – vista
          l’architettura dei sistemi utilizzati – la corretta erogazione delle
          varie funzionalità da te richieste, per ragioni di sicurezza e di
          accertamento di responsabilità in caso di ipotetici reati informatici
          ai danni del Sito o di terzi e vengono cancellati dopo 7 giorni.
        </p>
        <b>Cookie e tecnologie affini</b>
        <p>
          Il Titolare raccoglie Dati Personali attraverso cookies. Maggiori
          informazioni sull’uso dei cookie e tecnologie affini sono disponibili
          nella policy specifica a cui si rinvia.
        </p>
        <h3>
          3. FINALITÀ DEL TRATTAMENTO, BASE GIURIDICA E TEMPI DI CONSERVAZIONE
        </h3>
        <p>
          I suoi dati verranno trattati per le seguenti finalità e nel rispetto
          delle basi giuridiche che meglio precisato. In particolare:
        </p>
        <ul>
          <li>
            A) per instaurare, concludere e dare esecuzione ad un contratto
            avente ad oggetto i servizi offerti dal Titolare ivi compresa
            l’esecuzione di attività di consulenza. In tal caso la{" "}
            <b>base giuridica</b> del trattamento è l’esecuzione del contratto.
            I dati verranno conservati per la durata del contratto in essere e,
            dopo la cessazione, per 10 anni. Nel caso di contenzioso giudiziale,
            per tutta la durata dello stesso, fino all’esaurimento dei termini
            di esperibilità delle azioni di impugnazione;
          </li>
          <li>
            B) per rispondere a Sue richieste successive alla conclusione del
            rapporto contrattuale. In tal caso la <b>base giuridica</b> del
            trattamento è l’esecuzione del contratto. I dati verranno conservati
            per la durata del contratto in essere e, dopo la cessazione, per 10
            anni. Nel caso di contenzioso giudiziale, per tutta la durata dello
            stesso, fino all’esaurimento dei termini di esperibilità delle
            azioni di impugnazione;
          </li>
          <li>
            C) per adempiere gli obblighi amministrativo, contabili, fiscali a
            cui il Titolare è soggetto. In tal caso la <b>base giuridica</b> è
            quella di adempiere obblighi legali cui è soggetto il Titolare. I
            dati verranno conservati per la durata del contratto in essere e,
            dopo la cessazione, per 10 anni. Nel caso di contenzioso giudiziale,
            per tutta la durata dello stesso, fino all’esaurimento dei termini
            di esperibilità delle azioni di impugnazione;
          </li>
          <li>
            D) per far valere o difendere un diritto in sede giudiziaria, sia
            direttamente che indirettamente, nonché in sede amministrativa o
            nelle procedure di arbitrato e di conciliazione nei casi previsti
            dalle leggi, dalla normativa comunitaria, dai regolamenti. In tal
            caso la <b>base giuridica</b> deve intendersi il legittimo interesse
            del Titolare. Nel caso di contenzioso giudiziale, i dati verranno
            conservati per tutta la durata dello stesso, fino all’esaurimento
            dei termini di esperibilità delle azioni di impugnazione.
          </li>
          <p>
            La informiamo, inoltre, che il trattamento dei Suoi dati personali
            per le finalità di cui sopra è necessario per l’erogazione dei
            servizi da parte del Titolare e/o all’esecuzione dei contratti in
            essere. Pertanto, La informiamo che per le finalità del trattamento
            come sopra illustrate <b>il conferimento dei dati è obbligatorio</b>{" "}
            ed il loro mancato, parziale o inesatto conferimento potrà avere,
            come conseguenza, l'impossibilità di svolgere le attività richieste
            e preclude a Laborability S.r.l. di assolvere gli adempimenti
            contrattuali come previsti dai contratti in essere.
          </p>
          <p>
            Sempre in qualità di Titolare autonomo del trattamento, Laborability
            S.r.l. potrà utilizzare i Suoi dati raccolti direttamente o per il
            tramite di terzi:
          </p>
          <li>
            E) <b>previo suo consenso</b>, per svolgere attività funzionali alla
            promozione e vendita dei servizi di Laborability S.r.l. attraverso
            lettere, telefono, Internet, SMS ed altri sistemi di comunicazione.
            In tal caso la <b>base giuridica</b> deve intendersi il
            <b>consenso</b> prestato dall’interessato. I dati verranno trattati
            e conservati per 24 mesi dalla raccolta del consenso. Nel caso di
            contenzioso giudiziale, i dati verranno conservati per tutta la
            durata dello stesso, fino all’esaurimento dei termini di
            esperibilità delle azioni di impugnazione.
          </li>
          <p>
            Il conferimento dei Suoi dati e del Suo consenso per le finalità
            sopra indicate è <b>facoltativo</b>. Il mancato rilascio non incide
            sull’erogazione dei nostri servizi oggetto del contratto stipulato
            con Laborability S.r.l.
          </p>
          <p>
            Decorsi i termini di conservazione sopra indicati, i dati saranno
            distrutti, cancellati o resi anonimi, compatibilmente con le
            procedure tecniche di cancellazione e backup.
          </p>
        </ul>
        <h3>4. MODALITÀ DEL TRATTAMENTO DEI DATI.</h3>
        <p>
          Laborability S.r.l. procede al trattamento dei dati mediante strumenti
          cartacei, informatici e telematici. I dati cartacei sono custoditi
          presso la sede la sede della Società, ovvero presso supporti
          informatici presso la Società e/o in gestione a soggetti terzi
          debitamente individuati e nominati. Il trattamento e la custodia dei
          dati avvengono con modalità tali da garantirne la riservatezza e la
          sicurezza, e con logiche e mediante forme di organizzazione
          strettamente correlate alle finalità di cui sopra ed agli incarichi da
          Lei conferiti. Il trattamento potrà essere effettuato anche attraverso
          strumento automatizzati atti a memorizzare, gestire e trasmettere i
          dati stessi. E’ <b>esclusa la profilazione</b> nominativa ed anonima
          dell’interessato dal trattamento dei dati, ovverosia qualsiasi forma
          di trattamento automatizzato di dati personali consistente
          nell’utilizzo di tali dati personali per valutare determinati aspetti
          personali relativi a una persona fisica, in particolare per analizzare
          o prevedere aspetti riguardanti il rendimento professionale, la
          situazione economica, la salute, le preferenze personali, gli
          interessi, l’affidabilità̀, il comportamento, l’ubicazione o gli
          spostamenti di detta persona fisica.
        </p>
        <h3>
          5. AMBITO DEL TRATTAMENTO E CATEGORIE DI SOGGETTI AI QUALI I DATI
          POSSONO ESSERE COMUNICATI O CHE POSSONO VENIRNE A CONOSCENZA IN
          QUALITÀ DI RESPONSABILI ESTERNI, DI SOGGETTI AUTORIZZATI E DI TITOLARI
          AUTONOMI.
        </h3>
        <p>
          Per il perseguimento delle finalità sopra indicate, Laborability potrà
          comunicare e far trattare i dati personali, in ambito nazionale, a
          soggetti terzi con i quali ha rapporti, e segnatamente:
        </p>
        <ul>
          <li>
            a) Per quanto concerne i <b>soggetti autorizzati al trattamento</b>{" "}
            da intendersi quali dipendenti o collaboratori del Titolare, i suoi
            dati potranno essere trattati da questi ultimo per il perseguimento
            delle finalità sopra indicate;
          </li>
          <li>
            b) I dati potranno essere trattati, per conto del titolare, da
            soggetti designati come <b>responsabili del trattamento</b>, fra
            cui, in particolare, società che forniscono servizi informatici, di
            sicurezza, ovvero studio professionali genericamente intesi che
            possono fornire consulenza o assistenza di qualsivoglia genere al
            Titolare del trattamento, società di assicurazione.
          </li>
          <li>
            c) I dati possono essere comunicati a soggetti operanti in qualità
            di <b>titolari autonomi del trattamento</b>, qualora ciò fosse
            necessario per l’adempimento di obbligo di legge.
          </li>
        </ul>
        <p>
          In ogni caso una lista aggiornata dei soggetti autorizzati e dei
          responsabili del trattamento è presente presso Laborability S.r.l.
        </p>
        <h3>
          6. COME PROTEGGIAMO I DATI PERSONALI. TRASFERIMENTO DATI ALL’ESTERO.
        </h3>
        <p>
          Laborability S.r.l. adotta misure di sicurezza tecniche ed
          amministrative finalizzate a ridurre il rischio di perdita, uso non
          corretto, accesso non autorizzato, divulgazione e manomissione dei
          dati. Tra i sistemi di sicurezza utilizzati vi sono, a titolo
          esemplificativo, i firewall, i controlli dell'accesso fisico ai nostri
          computer, mediante previsione di sistemi di autenticazione, ed
          all’archivio cartaceo.
        </p>
        <p>
          Alcuni dei tuoi Dati Personali sono trasferiti a Destinatari che si
          potrebbero trovare al di fuori dello Spazio Economico Europeo.
          Laborability S.r.l. assicura che il trattamento elettronico e cartaceo
          dei Suoi Dati Personali da parte dei Destinatari avviene nel rispetto
          della Normativa Applicabile, il tutto sempre in conformità dei
          principi indicati agli articoli 45 e 46 del RGPD relativamente alla
          sussistenza di una decisione di adeguatezza da parte della Commissione
          europea ovvero a garanzie adeguate.
        </p>
        <h3>7. DIRITTI DELL’INTERESSATO.</h3>
        <p>
          Ricordiamo che è Sua facoltà esercitare i diritti stabiliti dagli
          articoli da 15 a 22 del Regolamento UE 2016/679, con la conseguenza
          che Lei potrà conoscere quali sono i suoi dati trattati presso
          Laborability S.r.l. e, ove ne ricorrano le condizioni, esercitare i
          diversi diritti relativi al loro utilizzo (diritto di accesso,
          rettifica, aggiornamento, integrazione, cancellazione, limitazione al
          trattamento, alla portabilità, alla revoca del consenso al trattamento
          e di ottenere una copia dei propri dati laddove questi siano
          conservati in paesi al di fuori dell’Unione Europea, nonché di
          ottenere indicazione del luogo nel quale tali dati vengono conservati
          o trasferiti) nonché opporsi per motivi legittimi ad un loro
          particolare trattamento e comunque al loro uso a fini commerciali, in
          tutto o in parte anche per quanto riguarda l’uso di modalità
          automatizzate rivolgendosi mediante lettera raccomandata A.R. a
          Laborability S.r.l. sita in Padova (PD), Via Rismondo, 2/E oppure
          mezzo mail pec all’indirizzo{" "}
          <a href="mailto:laborability@pec.laborability.com">
            laborability@pec.laborability.com
          </a>
          .
        </p>
        <p>
          In caso di mancata risposta entro il termine previsto per legge,
          ovvero nel caso in cui non ritenga congrua la risposta, Lei potrà
          rivolgersi al Garante Privacy italiano.
        </p>
        <h3>8. MODALITÀ DI ESERCIZIO DEI DIRITTI DELL’INTERESSATO.</h3>
        <p>
          In ogni momento Lei ha diritto di esercitare i diritti di cui sopra
          inviando una mail a Laborability S.r.l., con oggetto “
          <b>RIF. PRIVACY</b>”, al seguente indirizzo di posta elettronica:{" "}
          <a href="mailto:laborability@pec.laborability.com">
            laborability@pec.laborability.com
          </a>
          .
        </p>
      </div>
    </div>
  );
}
