import style from "./style.module.scss";
import { filterCategory } from "../../tools/filter";
import { Link } from "react-router-dom";

export default function SmallCardVertical(props) {
  const date = new Date(Date.parse(props.data.dateGmt));
  return (
    <div
      className={`${style.cardContainer} ${
        props.mobile ? style.mobile : null
      } ${props.desktop ? style.desktop : null}`}
    >
      <div className={style.imageContainer}>
        <Link to={props.data.uri}>
          <img
            className={style.image}
            src={props.data.featuredImage?.node.sourceUrl}
            alt={props.data.featuredImage?.node.altText}
          />
        </Link>
      </div>
      <div className={style.cardContent}>
        <div className={style.data}>
          <Link
            className={style.category}
            to={filterCategory(props.data.categories)?.uri}
          >
            {filterCategory(props.data.categories)?.name}
          </Link>
          <h6 className={style.date}>
            {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
          </h6>
        </div>
        <h3>
          <Link className={style.title} to={props.data.uri}>
            {props.data.title}
          </Link>
        </h3>
        <Link className={style.content} to={props.data.uri}>
          {props.data.sottotitolo.sottotitolo}
        </Link>
        <p className={style.duration}>{props.data.readingTime} min</p>
      </div>
    </div>
  );
}
