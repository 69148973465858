import { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, { EffectCoverflow } from 'swiper';

import style from './style.module.scss';
import './exception.css';
import { Link } from 'react-router-dom';

SwiperCore.use([EffectCoverflow]);

export default function PeopleSlider(props) {
  const data = props.data.map((post) => ({
    data: post.nameandposition,
    image: post.secondThumbnail,
    uri: post.uri,
  }));
  const [activeIndex, setActiveIndex] = useState(0);
  const [hidden, setHidden] = useState(false);
  const swiperRef = useRef();
  return (
    <div className={style.container}>
      <p className={style.title}>Nuovi ingressi</p>
      <div className={style.sliderContainer}>
        <div
          className={style.iconContainer}
          onClick={() => swiperRef.current.swiper.slidePrev()}>
          <svg className={style.icon} viewBox='0 0 24 24'>
            <path d='M10.6,12.71a1,1,0,0,1,0-1.42l4.59-4.58a1,1,0,0,0,0-1.42,1,1,0,0,0-1.41,0L9.19,9.88a3,3,0,0,0,0,4.24l4.59,4.59a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.42Z' />
          </svg>
        </div>
        <div className={style.sliderContent}>
          <Swiper
            className={`${style.sliderWrapper} exception`}
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={3}
            loop={data.length === 1 ? false : true}
            coverflowEffect={{
              rotate: 0,
              stretch: -20,
              depth: 240,
              modifier: 1,
              slideShadows: false,
            }}
            pagination={true}
            ref={swiperRef}
            onSlideChangeTransitionStart={() => {
              setHidden(true);
            }}
            onSlideChangeTransitionEnd={({ realIndex }) => {
              setActiveIndex(realIndex);
              setHidden(false);
            }}>
            {data.map((post, index) => (
              <SwiperSlide key={index}>
                {({ isActive }) => (
                  <Link
                    to={post.uri}
                    className={`${style.imageContainer} ${
                      isActive ? style.active : style.pasive
                    } d-block`}>
                    <img src={post.image} alt='User' className={style.image} />
                  </Link>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div
          className={style.iconContainer}
          onClick={() => swiperRef.current.swiper.slideNext()}>
          <svg className={style.icon} viewBox='0 0 24 24'>
            <path d='M15.4,9.88,10.81,5.29a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42L14,11.29a1,1,0,0,1,0,1.42L9.4,17.29a1,1,0,0,0,1.41,1.42l4.59-4.59A3,3,0,0,0,15.4,9.88Z' />
          </svg>
        </div>
      </div>
      <div className={`${style.infoContainer} ${hidden ? style.hidden : ''}`}>
        <p className={style.fullName}>{data[activeIndex]?.data?.nome ?? ''}</p>
        <p className={style.position}>
          {data[activeIndex]?.data?.posizione ?? ''}
        </p>
        <p className={style.description}>
          {data[activeIndex]?.data?.descrizione ?? ''}
        </p>
      </div>
    </div>
  );
}
