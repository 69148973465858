import { Link } from "react-router-dom";
import style from "./style.module.scss";

export default function LinkSection(props) {
  return (
    <div>
      {props.video ? (
        <div className={style.video}>
          <div className={style.container}>
            <Link className={style.icon} to="/video">
              <svg viewBox="0 0 448 512">
                <path
                  fill="var(--color-secondary)"
                  d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
                ></path>
              </svg>
            </Link>
            <h2>I video del Gruppo DMO</h2>
            <h3>
              I video dedicati a tutte le persone che lavorano nel Gruppo DMO
            </h3>
            <div className={style.buttonContainer}>
              <Link className={style.button} to="/video">
                Scopri tutti i video
              </Link>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
