import { useMutation, useQuery } from "@apollo/client";
import { Fragment, useEffect, useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import lodash from "lodash";

import Breadcrumb from "../../components/Breadcrumb";
import LinkSection from "../../components/LinkSection";
import Share from "../../components/Share";
import Error from "../../components/Error";

import { POST_DATA } from "./querys";

import style from "./style.module.scss";
import ArticoliCorelati from "./articoliCorelati";
import { filterCategory } from "../../tools/filter";
import { SUBMIT_NEWSLETTER } from "./mutations";
import { useSelector } from "react-redux";

export default function PostPagePreview() {
  const router = useRouteMatch();
  const routerController = useHistory();
  const [error, setError] = useState([]);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const userId = useSelector((state) => state.authReducer.id);
  const id = router.params.postId;
  const post = useQuery(POST_DATA, {
    variables: {
      id,
    },
  });
  const [submitNewsLetter, newsLetter] = useMutation(SUBMIT_NEWSLETTER);
  if (post.loading) {
    return null;
  } else if (post.data.post === null) {
    return <Error />;
  }
  const postData = post.data.post;
  const date = new Date(postData.dateGmt);
  let okPost = postData.content;
  var secCounter = 0;
  let listHeadres = "";
  okPost.match(/<h5>(.*?)<\/h5>/g)?.forEach((val) => {
    listHeadres += `<li><a className="target_cls" href="#section_${secCounter++}">${val
      .replace(/<span className="hidden(.*?)<\/span>/g, "")
      .replace(/(<([^>]+)>)/gi, "")}</a></li>`;
  });
  secCounter = 0;
  okPost = okPost.replace(/<h5/g, function () {
    return `<h3 id="section_${secCounter++}"`;
  });
  okPost = okPost.split("</h5>").join("</h3>");
  if (newsLetter.data) {
    // window.dataLayer.push({
    //   event: "opinionSubmitted",
    // });
    routerController.push("/contattaci-grazie");
  } else if (newsLetter.error) {
    console.log(error);
  }
  async function handleSubmit(event) {
    event.preventDefault();
    if (email.length === 0 || message.length === 0) {
      setError([{ message: "Si prega di compilare tutti i campi" }]);
    } else {
      const data = {
        email,
        messaggio: message,
      };
      submitNewsLetter({
        variables: { data },
      });
    }
  }

  return (
    <Fragment>
      <div className={`container pt-4 py-0 px-lg-5 ${style.container}`}>
        <Breadcrumb categories={postData.categories} />
        <div className="row">
          <div
            className={`${style.postColumn} col-12 col-lg-8 ${style.postarticle}`}
          >
            <h1 className={style.mainTitle}>{postData.title}</h1>
            <div className={`${style.doubleSideFlex} py-3`}>
              <h4 className={style.info}>
                {date.toLocaleString("IT-it", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              </h4>
              <h4 className={style.info}>
                Tempo di lettura: {postData.readingTime} min
              </h4>
            </div>
            <div className={style.mainImage}>
              <img
                className={style.image}
                src={postData.featuredImage?.node?.sourceUrl}
                alt={postData.featuredImage?.node?.altText}
              />
            </div>
            <h3 className={style.origin}>
              {postData.comment_foto.commentFoto}
            </h3>
            <h2 className={style.description}>
              {postData.sottotitolo.sottotitolo}
            </h2>
            <Share uri={postData.uri} title={postData.title} />
            <ul
              className={style.mapContainer}
              dangerouslySetInnerHTML={{
                __html: listHeadres,
              }}
            />
            <div
              className={style.postWraper}
              dangerouslySetInnerHTML={{
                __html: okPost,
              }}
            />
            {postData.Link_Mondo_HR.linkMondoHr ? (
              <div className="text-center my-5">
                <a
                  className={style.mondoHrButton}
                  href={postData.Link_Mondo_HR.linkMondoHr}
                >
                  Scopri di più
                </a>
              </div>
            ) : null}
            <div className={style.tagContainer}>
              {postData.tags.nodes.map((tag) => (
                <a
                  className={style.tag}
                  href={`/tag/${tag.slug}`}
                  key={tag.slug}
                >
                  {tag.name}
                </a>
              ))}
            </div>
            <form
              className={style.message}
              action="submit"
              onSubmit={(event) => handleSubmit(event)}
            >
              <p className={style.messageTitle}>
                La tua opinione ci sta a cuore. Se vuoi proporre temi o
                raccontare storie di lavoro, saremo contenti di ascoltarti.
                Contattaci.
              </p>
              {error.length !== 0 ? (
                <div className="error">
                  {error?.map((data) => (
                    <div className={style.error} key={data.message}>
                      {data?.message.replaceAll("_", " ")}
                    </div>
                  ))}
                </div>
              ) : null}
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <textarea
                rows="6"
                placeholder="Messaggio"
                value={message}
                onChange={(event) => setMessage(event.target.value)}
              />
              <div className="text-center pt-4">
                <button className={style.messageButton}>
                  {submitNewsLetter.loading ? (
                    <svg viewBox="0 0 512 512">
                      <path
                        fill="var(--color-background)"
                        d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"
                      />
                    </svg>
                  ) : (
                    "Invia"
                  )}
                </button>
              </div>
            </form>
          </div>
          <div
            className={`${style.postColumn} col-12 col-lg-4 ${style.postrightside}`}
          >
            <ArticoliCorelati
              id={postData.postId}
              category={filterCategory(postData.categories).slug}
            />
          </div>
        </div>
      </div>
      <div className="mt-5">
        <LinkSection video book />
      </div>
    </Fragment>
  );
}
