import { METRICS_API_URL } from "../../config";

import { useQuery } from "@apollo/client";
import { Fragment, useState, useEffect } from "react";
import lodash from "lodash";

import Breadcrumb from "../../components/BreadcrumbSpecial";
import LinkSection from "../../components/LinkSection";
import VideoCard from "../../components/VideoCard";
import Slider from "../../components/Slider";
import BigCardVertical from "../../components/BigCardVertical";
import Error from "../../components/Error";
import Share from "../../components/Share";
// import SmallCardMinimal from "../../components/Small-Card-Minimal";

import { VIDEO } from "./querys";

import style from "./style.module.scss";
import { useRouteMatch } from "react-router";
import VideoCorelati from "./videoCorelati";
import { useSelector } from "react-redux";
import { isBrowser } from "react-device-detect";
import InstagramEmbed from "react-instagram-embed";

export default function VideoPage() {
  const router = useRouteMatch();

  const [videoPlaying, setVideoPlaying] = useState(false);
  const userId = useSelector((state) => state.authReducer.id);
  const [device_id, set_device_id] = useState();

  const slug = lodash.nth(lodash.compact(router.url.split("/")), -1);

  const video = useQuery(VIDEO, {
    variables: {
      slug,
    },
  });

  const script = document.createElement("script");
  useEffect(() => {
    script.src = "https://www.tiktok.com/embed.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [script]);

  useEffect(() => {
    set_device_id(isBrowser ? 1 : 2);
  }, []);

  const [nrOfLikes, setNrOfLikes] = useState(0);
  const [liked, setLiked] = useState(false);

  useEffect(() => {
    if (video.data) {
      const interval = setInterval(() => {
        fetch(
          `${METRICS_API_URL}/${video.data.video.videoId}/${userId}/${device_id}`
        );
      }, 5000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [video.data, userId, device_id]);

  if (video.loading) {
    return null;
  }

  if (!video.data.video) {
    return <Error />;
  }

  const videoData = video.data.video;

  const postsData = video.data.posts.nodes;

  const videoUltimiData = video.data.videos.nodes;

  let okPost = videoData.content;
  const date = new Date(Date.parse(videoData.dateGmt));

  const categories = [
    { name: "Video", url: "/video" },
    { name: videoData.title, url: videoData.slug },
  ];

  let videoType = "";
  if (
    videoData.VideosMeta.embedVideo.startsWith(
      '<blockquote class="tiktok-embed"'
    )
  ) {
    videoType = "tiktok";
  } else if (videoData.VideosMeta.embedVideo.startsWith("<iframe")) {
    videoType = "youtube";
  } else if (
    videoData.VideosMeta.embedVideo.startsWith(
      '<blockquote class="instagram-media"'
    )
  ) {
    videoType = "instagram";
  }

  return (
    <Fragment>
      <div className="container pt-4">
        <Breadcrumb categories={categories} />
        <div className="row">
          <div className="col-12 col-lg-8">
            <div
              className={`${style.videoContainer} ${
                videoPlaying ? style.playing : null
              }`}
            >
              {videoType == "youtube" ? (
                <>
                  <div className={style.container}>
                    <img
                      className={style.image}
                      src={videoData.VideosMeta.videoImage.sourceUrl}
                      alt={videoData.VideosMeta.videoImage.altText}
                    />
                  </div>
                  <span
                    className={style.playIcon}
                    onClick={() => setVideoPlaying(true)}
                  >
                    <svg viewBox="0 0 448 512">
                      <path
                        fill="var(--color-secondary)"
                        d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
                      ></path>
                    </svg>
                  </span>
                </>
              ) : videoType == "instagram" ? (
                <div class={style.instagramVideoCont}>
                  <InstagramEmbed url={videoData.VideosMeta.embedVideo} />
                </div>
              ) : null}

              <div
                className={`${style.videoWraper} ${
                  videoType == "instagram" && style.instagramVideoCont
                }`}
                dangerouslySetInnerHTML={{
                  __html: videoData.VideosMeta.embedVideo,
                }}
              />
            </div>

            <Share
              uri={videoData.uri}
              id={videoData.videoId}
              nrOfLikes={nrOfLikes}
              setNrOfLikes={(value) => setNrOfLikes(value)}
              liked={liked}
              setLiked={(value) => setLiked(value)}
            />

            <div className={`${style.doubleSideFlex} pb-3 pt-4`}>
              <a
                className={style.category}
                href={`/video/tema/${videoData.categories.nodes[0].slug}`}
              >
                {videoData.categories.nodes[0].name}
              </a>

              <h6 className={style.date}>
                {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
              </h6>
            </div>
            <h1 className={style.mainTitle}>{videoData.title}</h1>

            <div
              className={style.postWraper}
              dangerouslySetInnerHTML={{
                __html: okPost,
              }}
            />

            <div className={style.tagContainer}>
              {videoData.videoTags.nodes.map((tag) => (
                <a
                  className={style.tag}
                  href={`/tag/${tag.slug}`}
                  key={tag.slug}
                >
                  {tag.name}
                </a>
              ))}
            </div>
          </div>
          <VideoCorelati
            category={videoData.categories.nodes[0].slug}
            id={videoData.videoId}
          />
        </div>
        <div className="row">
          <div className={style.doubleSideFlex}>
            <h2 className={style.sectionTitle}>Articoli correlati</h2>
            <a
              className={`${style.moreArticles} d-none d-lg-block`}
              href="/tema"
            >
              Vedi altri articoli
            </a>
          </div>
          <Slider className="row" more="/tema">
            {postsData.map((data) => (
              <div className="col-12 col-md-4 my-2" key={data.slug}>
                <BigCardVertical data={data} />
              </div>
            ))}
          </Slider>
        </div>
        <div className="row py-5">
          <div className={style.doubleSideFlex}>
            <h2 className={style.sectionTitle}>Ultimi video</h2>
            <a
              className={`${style.moreArticles} d-none d-lg-block`}
              href="/video"
            >
              Vedi altri video
            </a>
          </div>
          <Slider className="row m-0" more="/video">
            {videoUltimiData.map((data) => (
              <div className="col-12 col-lg-4 my-2" key={data.slug}>
                <VideoCard data={data} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="mt-5">
        <LinkSection book />
      </div>
    </Fragment>
  );
}
