import style from "./notifications-list.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  markNotificationAsRead,
} from "../../store/actions/notificationsActions";

import { useMutation } from "@apollo/client";
import { MARK_NOTIFICATION_READ } from "../../queries/notifications/mutations";

export default function NotificationsList(props) {
  const notifications = useSelector((state) => state.notificationsReducer || []);

  const dispatch = useDispatch();

  const [submitMarkNotificationRead, markNotificationRead] = useMutation(MARK_NOTIFICATION_READ);

  async function handleNotificationItemClick(event, notification) {
    event.preventDefault();

    // update on client
    dispatch(markNotificationAsRead(notification));

    if( ! notification?.is_read ){
    // update on server
      await submitMarkNotificationRead({
        variables: {
          id: notification.id
         },
      });
    }

    // redirect to notification link
    if( notification?.link ){
      window.location.href = notification.link;
    }
    
  };

  return (
    <>
      {notifications?.length ? (
        <ul className={style.listUL}>
          {notifications.map((notification) => (
            <li className={style.listLI} key={notification.id}>
              <a
                onClick={(event) => handleNotificationItemClick(event, notification) }
                className={style.notificationLink}
                href={notification.link}
              >
                <div className={style.notificationTitle}>
                  {notification.title}{" "}
                  { notification.is_read == 0 && (
                    <span className={style.redDot}></span>
                  )}
                </div>
                <div className={style.notificationBody}>
                  {notification.body}
                </div>
                <div className="unread-notification"></div>
              </a>
            </li>
          ))}
        </ul>
      ) : (
        <p style={{ textAlign: "center" }}>Non hai nuove notifiche.</p>
      )}
    </>
  );
}
