import style from "./style.module.scss";

export default function PodcastCard(props) {
  const date = new Date(Date.parse(props.data.dateGmt));
  return (
    <a href={props.data.uri}>
      <div className={style.cardContainer}>
        <div className={style.imageContainer}>
          <img
            className={style.image}
            src={props.data.featuredImage.node.sourceUrl}
            alt={props.data.featuredImage.node.altText}
          />
          <a className={style.playIcon} href={props.data.uri}>
            <img
              src="/assets/svgs/podcast.svg"
              alt="icon"
              height="19"
              width="12"
            />
          </a>
        </div>
        <div className={style.cardContent}>
          <div className={style.data}>
            <a
              className={style.category}
              href={`/podcast/tema/${props.data.categories.nodes[0].slug}`}
            >
              {props.data.categories.nodes[0].name}
            </a>
            {props.homepage ? null : (
              <h6 className={style.date}>
                {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
              </h6>
            )}
          </div>
          <h3>
            <a className={style.title} href={props.data.uri}>
              {props.data.title}
            </a>
          </h3>
        </div>
      </div>
    </a>
  );
}
