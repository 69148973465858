import { useQuery } from "@apollo/client";
import { Fragment } from "react";
import { useRouteMatch } from "react-router";
import lodash from "lodash";

import Breadcrumb from "../../components/Breadcrumb";
import LinkSection from "../../components/LinkSection";
import Slider from "../../components/Slider";
import VideoCard from "../../components/VideoCard";
import { VIDEOS } from "./querys";
import Pagination from "../../components/Pagination";

import style from "./style.module.scss";

const paginationSize = 12;

export default function VideoIndexPage() {
  const router = useRouteMatch();

  let index;
  let categorySlug;

  const category = lodash.compact(router.url.split("/"));

  if (lodash.nth(category, -2) === "page") {
    index = lodash.nth(category, -1);
    categorySlug = lodash.nth(category, -3);
  } else {
    index = 1;
    categorySlug = lodash.nth(category, -1);
  }

  const videos = useQuery(VIDEOS, {
    variables: {
      slug: categorySlug,
      categorySlug: categorySlug,
      offset: (index - 1) * paginationSize,
    },
  });

  if (videos.loading) {
    return null;
  }

  const categoryData = videos.data.category;
  const data = videos.data.videos.nodes;
  
  const pageInfo = { ...videos.data.videos.pageInfo };
  pageInfo.current = index;
  pageInfo.size = paginationSize;
  
  // const categoryData = data.category;
  pageInfo.url = "/video";

  return (
    <Fragment>
      <div className="container pt-4">
        <Breadcrumb uri="/video" />
        <h1 className={style.mainTitle}>{categoryData.name}</h1>
        <h3 
          className={style.sectionDescription}
          dangerouslySetInnerHTML={{ __html: categoryData.description }}>  
        </h3>
        <div className="row">
          {data.map((video) => (
            <div className="col-12 col-md-4 my-3" key={data.slug}>
              <VideoCard data={video} />
            </div>
          ))}
        </div>
      </div>
      <div className="mt-5 pt-5">
        <LinkSection book />
      </div>
      {/* {categoryData.children.nodes.length === 0 ? ( */}
      <div className="pt-2 pb-4">
        <Pagination data={pageInfo} />
      </div>
      {/* ) : null} */}
    </Fragment>
  );
}
