import style from "./style.module.scss";

import VideoCard from "../../components/VideoCard";
import PaginationSearch from "../../components/Pagination-Search";
import LinkSection from "../../components/LinkSection";
import SmallCardVertical from "../../components/SmallCardVertical";

import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { QUERY_SEARCH_VIDEOS, QUERY_SEARCH_POSTS } from "./querys";
import { useParams } from "react-router";

const postsPaginationSize = 12;
const videosPaginationSize = 9;

export default function SearchPage(props) {
  const router = useParams();
  const searchString = router[0];
  const [active, setActive] = useState(null);
  const [postsCurrent, setPostsCurrent] = useState(1);
  const [videosCurrent, setVideosCurrent] = useState(1);
  const posts = useQuery(QUERY_SEARCH_POSTS, {
    variables: {
      searchString,
      offset: (postsCurrent - 1) * postsPaginationSize,
    },
  });
  const videos = useQuery(QUERY_SEARCH_VIDEOS, {
    variables: {
      searchString,
      offset: (videosCurrent - 1) * videosPaginationSize,
    },
  });
  const total =
    posts.data?.posts?.pageInfo?.offsetPagination?.total |
    (0 + videos.data?.videos?.pageInfo?.offsetPagination?.total) |
    0;
  useEffect(() => {
    if (!posts.loading && !videos.loading && !active) {
      if (posts.data?.posts?.nodes?.length > 0) {
        setActive("posts");
      } else if (videos.data?.videos?.nodes?.length > 0) {
        setActive("videos");
      }
    }
  }, [
    active,
    posts.data?.posts?.nodes?.length,
    posts.loading,
    videos.data?.videos?.nodes?.length,
    videos.loading,
  ]);
  return (
    <div>
      <div className="container pt-4">
        <h1 className={style.mainTitle}>{props.searchString}</h1>
        {total !== 0 ? (
          <h3 className={style.info}>{total} risultati di ricerca</h3>
        ) : (
          <div>
            <h3 className={`${style.info} mb-3`}>
              Nessun post corrisponde ai tuoi criteri.
            </h3>
            <h4 className="text-center">
              Riprova con alcune parole chiave diverse.
            </h4>
          </div>
        )}
        <div className={style.typeContainer}>
          {posts.data?.posts?.nodes?.length > 0 ? (
            <div
              className={`${style.typeItem} ${
                active === "posts" ? style.active : null
              }`}
              onClick={() => setActive("posts")}
            >
              <h2>Articoli</h2>
            </div>
          ) : null}
          {videos.data?.videos?.nodes?.length > 0 ? (
            <div
              className={`${style.typeItem} ${
                active === "videos" ? style.active : null
              }`}
              onClick={() => setActive("videos")}
            >
              <h2>Video</h2>
            </div>
          ) : null}
        </div>
        <div className="py-3">
          {posts.data?.posts?.nodes?.length > 0 ? (
            <div className={active === "posts" ? null : "d-none"}>
              <div className="row">
                {posts.data.posts.nodes.map((postData) => (
                  <div
                    className="col-12 col-md-6 col-lg-3 my-5"
                    key={postData.slug}
                  >
                    <SmallCardVertical data={postData} />
                  </div>
                ))}
              </div>
              <div className="pt-2 pb-4">
                <PaginationSearch
                  current={postsCurrent}
                  data={posts.data.posts.pageInfo}
                  size={postsPaginationSize}
                  onChangeValue={(value) => {
                    try {
                      window.scrollTo(0, 0);
                    } catch (err) {
                      console.log(err);
                    }
                    setPostsCurrent(value);
                    posts.fetchMore({
                      variables: {
                        searchString,
                        offset: (postsCurrent + 1) * postsPaginationSize,
                      },
                    });
                  }}
                />
              </div>
            </div>
          ) : null}
          {videos.data?.videos?.nodes?.length > 0 ? (
            <div className={`row ${active === "videos" ? null : "d-none"}`}>
              {videos.data.videos.nodes.map((videoData) => (
                <div className="col-12 col-md-4 my-3" key={videoData.slug}>
                  <VideoCard data={videoData} />
                </div>
              ))}
              <div className="pt-2 pb-4">
                <PaginationSearch
                  current={videosCurrent}
                  data={videos.data.videos.pageInfo}
                  size={videosPaginationSize}
                  onChangeValue={(value) => {
                    try {
                      window.scrollTo(0, 0);
                    } catch (err) {
                      console.log(err);
                    }
                    setVideosCurrent(value);
                    videos.fetchMore({
                      variables: {
                        searchString,
                        offset: (videosCurrent + 1) * postsPaginationSize,
                      },
                    });
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="mt-5">
        <LinkSection video book />
      </div>
    </div>
  );
}
