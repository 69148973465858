import gql from "graphql-tag";

export const CHANGE_PASSWORD = gql`
  mutation MyMutation($id: ID!, $password: String) {
    updateUser(input: { id: $id, password: $password }) {
      user {
        email
      }
    }
  }
`;
