import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { Fragment, useState } from "react";
import { storeToken } from "../../store/actions/auth";
import { LOGIN } from "./querys";
import { store } from "../../store";

import style from "./style.module.scss";
import { Router } from "workbox-routing";
import { Link } from "react-router-dom";

export default function LoginPage() {
  const router = useHistory();
  const controllRouter = useHistory();
  const [error, setError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [submitLogin, login] = useMutation(LOGIN);

  const token = useSelector((state) => state.authReducer.token);

  var queryString = new URLSearchParams(router.location.search).toString();
  var isPasswordResetSuccess = queryString?.indexOf("pr=true") != -1;

  if (token.length) {
    controllRouter.push("/");
  }

  function handleSubmit(event) {
    event.preventDefault();
    submitLogin({
      variables: { email, password },
    }).catch((error) => {
      if (error.message === "incorrect_password") {
        setError("Password non corretta. Riprova");
      }
    });
  }

  if (login.data) {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ loginData: login.data.login, event: "userLoggedIN" })
      );
    }

    store.dispatch(
      storeToken(
        login.data.login.authToken,
        login.data.login.refreshToken,
        login.data.login.user.databaseId,
        login.data.login.user.id
      )
    );
    router.push("/");
  }

  return (
    <Fragment>
      <div className={style.background}>
        <form
          className={style.form}
          action="submit"
          onSubmit={(event) => handleSubmit(event)}
        >
          <div className={style.activeHolder}>
            <Link to="/login" className={style.active}>
              Accedi
            </Link>
          </div>
          <div className={`${style.content} pt-5`}>
            {error.length ? <div className={style.error}>{error}</div> : null}

            {isPasswordResetSuccess}
            {Boolean(isPasswordResetSuccess) && (
              <div className={style.succes}>
                La sua password è stata reimpostata
              </div>
            )}

            <label className={style.label}>E-mail</label>
            <input
              className={style.input}
              type="text"
              required="required"
              placeholder="Inserisci nome utente o email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <label className={style.label}>Password</label>
            <input
              className={style.input}
              type="password"
              required="required"
              placeholder="Inserisci la tua password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
            <div className={style.alignFlex}>
              <input
                id="rememberMe"
                type="checkbox"
                checked={rememberMe}
                onChange={(event) => setRememberMe(event.target.checked)}
              />
              <label className={style.note} htmlFor="rememberMe">
                Rimani collegato
              </label>
            </div>
            <button
              className={style.loginButton}
              type="submit"
              enabled={loading ? 1 : 0}
            >
              {loading ? (
                <svg viewBox="0 0 512 512">
                  <path
                    fill="currentColor"
                    d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"
                  />
                </svg>
              ) : (
                "Accedi"
              )}
            </button>
            <p className={style.end}>
              <Link to="/recupera/email">
                <span>Hai dimenticato la password?</span>
              </Link>
            </p>
          </div>
        </form>
      </div>
    </Fragment>
  );
}
