import gql from "graphql-tag";

export const BANNERS_DATA = gql`
  query bannersData {
    banners(first: 100, where: {orderby: {field: DATE, order: ASC}}) {
      nodes {
        title
        image
        follow
        page
        section
      }
    }
  }
`;