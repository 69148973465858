import gql from "graphql-tag";

export const CADDYS_DATA = gql`
  query homeDataCaddys {
    posts(first: 96, where: { status: PUBLISH, categoryNotIn: "2923" }) {
      nodes {
        title
        dateGmt
        slug
        readingTime
        uri
        databaseId
        categories {
          edges {
            node {
              name
              uri
              imagecategory {
                caddysbeautystar
              }
              parent {
                node {
                  name
                  uri
                  slug
                  parent {
                    node {
                      name
                    }
                  }
                }
              }
              slug
            }
          }
        }
        sottotitolo {
          sottotitolo
        }
        featuredImage {
          node {
            link
            sourceUrl
            altText
            mediaDetails {
              sizes {
                height
                width
              }
            }
          }
        }
      }
    }
    categories(
      where: {
        slug: [
          "novita-caddys"
          "storie-e-iniziative-caddys"
          "fondazione"
          "lavoro-in-pillole"
          "mondo-hr"
          "prodotti-caddys"
          "nuovi-ingressi-in-azienda-caddys"
        ]
      }
    ) {
      nodes {
        name
        slug
        uri
        posts(first: 32, where: { status: PUBLISH }) {
          nodes {
            dateGmt
            title
            slug
            readingTime
            uri
            databaseId
            secondThumbnail
            nameandposition {
              descrizione
              nome
              posizione
            }
            categories {
              edges {
                node {
                  name
                  uri
                  imagecategory {
                    caddysbeautystar
                  }
                  parent {
                    node {
                      name
                      uri
                      slug
                      parent {
                        node {
                          name
                        }
                      }
                    }
                  }
                  slug
                }
              }
            }
            sottotitolo {
              sottotitolo
            }
            featuredImage {
              node {
                link
                sourceUrl
                altText
                mediaDetails {
                  sizes {
                    height
                    width
                  }
                }
              }
            }
          }
        }
      }
    }
    videos(first: 6, where: { status: PUBLISH }) {
      nodes {
        title
        dateGmt
        uri
        slug
        categories {
          nodes {
            slug
            uri
            name
          }
        }
        VideosMeta {
          videoUrl
          sottotitolo
          description
          videoImage {
            link
            sourceUrl
            altText
            mediaDetails {
              sizes {
                height
                width
              }
            }
          }
        }
      }
    }
    jobs {
      nodes {
        title
        company
        uri
      }
    }
    locations {
      nodes {
        title
        LocationsMeta {
          dateLocations
        }
      }
    }
    banners(first: 100, where: { orderby: { field: DATE, order: ASC } }) {
      nodes {
        title
        image
        follow
        page
        section
      }
    }
  }
`;

export const BEAUTYSTARS_DATA = gql`
  query homeDataBeautystars {
    posts(first: 96, where: { status: PUBLISH, categoryNotIn: "2923" }) {
      nodes {
        dateGmt
        title
        slug
        readingTime
        uri
        databaseId
        categories {
          edges {
            node {
              name
              uri
              imagecategory {
                caddysbeautystar
              }
              parent {
                node {
                  name
                  uri
                  slug
                  parent {
                    node {
                      name
                    }
                  }
                }
              }
              slug
            }
          }
        }
        sottotitolo {
          sottotitolo
        }
        featuredImage {
          node {
            link
            sourceUrl
            altText
            mediaDetails {
              sizes {
                height
                width
              }
            }
          }
        }
      }
    }
    categories(
      where: {
        slug: [
          "novita-beautystar"
          "storie-e-iniziative-beautystar"
          "fondazione"
          "lavoro-in-pillole"
          "mondo-hr"
          "prodotti-beautystar"
          "nuovi-ingressi-in-azienda-beautystar-novita"
        ]
      }
    ) {
      nodes {
        name
        slug
        uri
        posts(first: 32, where: { status: PUBLISH }) {
          nodes {
            dateGmt
            title
            slug
            readingTime
            uri
            databaseId
            secondThumbnail
            nameandposition {
              descrizione
              nome
              posizione
            }
            categories {
              edges {
                node {
                  name
                  uri
                  imagecategory {
                    caddysbeautystar
                  }
                  parent {
                    node {
                      name
                      uri
                      slug
                      parent {
                        node {
                          name
                        }
                      }
                    }
                  }
                  slug
                }
              }
            }
            sottotitolo {
              sottotitolo
            }
            featuredImage {
              node {
                link
                sourceUrl
                altText
                mediaDetails {
                  sizes {
                    height
                    width
                  }
                }
              }
            }
          }
        }
      }
    }
    videos(first: 6, where: { status: PUBLISH }) {
      nodes {
        title
        dateGmt
        uri
        slug
        categories {
          nodes {
            slug
            uri
            name
          }
        }
        VideosMeta {
          videoUrl
          sottotitolo
          description
          videoImage {
            link
            sourceUrl
            altText
            mediaDetails {
              sizes {
                height
                width
              }
            }
          }
        }
      }
    }
    jobs {
      nodes {
        title
        company
        uri
      }
    }
    locations {
      nodes {
        title
        LocationsMeta {
          dateLocations
        }
      }
    }
    banners(first: 100, where: { orderby: { field: DATE, order: ASC } }) {
      nodes {
        title
        image
        follow
        page
        section
      }
    }
  }
`;

export const STICKY_DATA = gql`
  query getSpecialCategories {
    categories(
      where: {
        slug: [
          "fondazione"
          "nuovi-ingressi-in-azienda-caddys"
          "prodotti-caddys"
          "prodotti-beautystar"
          "nuovi-ingressi-in-azienda-beautystar-novita"
          "storie-e-iniziative-caddys"
          "storie-e-iniziative-beautystar"
          "video"
        ]
      }
    ) {
      nodes {
        name
        slug
        uri
        posts(first: 32, where: { status: PUBLISH, stickyItem: true }) {
          nodes {
            dateGmt
            title
            slug
            readingTime
            uri
            databaseId
            secondThumbnail
            nameandposition {
              descrizione
              nome
              posizione
            }
            categories {
              edges {
                node {
                  name
                  uri
                  imagecategory {
                    caddysbeautystar
                  }
                  parent {
                    node {
                      name
                      uri
                      slug
                      parent {
                        node {
                          name
                        }
                      }
                    }
                  }
                  slug
                }
              }
            }
            sottotitolo {
              sottotitolo
            }
            featuredImage {
              node {
                link
                sourceUrl
                altText
                mediaDetails {
                  sizes {
                    height
                    width
                  }
                }
              }
            }
          }
        }
      }
    }
    posts(
      last: 3
      where: { onlySticky: true, orderby: { field: DATE, order: DESC } }
    ) {
      nodes {
        title
        dateGmt
        slug
        readingTime
        uri
        databaseId
        categories {
          edges {
            node {
              name
              uri
              imagecategory {
                caddysbeautystar
              }
              parent {
                node {
                  name
                  uri
                  slug
                  parent {
                    node {
                      name
                    }
                  }
                }
              }
              slug
            }
          }
        }
        sottotitolo {
          sottotitolo
        }
        featuredImage {
          node {
            link
            sourceUrl
            altText
            mediaDetails {
              sizes {
                height
                width
              }
            }
          }
        }
      }
    }
    contentNodes(
      where: {contentTypes: [POST, VIDEO], orderby: {field: DATE, order: DESC}, stickyContentNodes: true}
    ) {
      edges {
        node {
          contentType {
            node {
              name
            }
          }
          ... on Video {
            title
            dateGmt
            uri
            slug
            databaseId
            categories {
              nodes {
                slug
                uri
                name,
                parent {
                  node {
                    name
                  }
                }
              }
            }
            VideosMeta {
              videoUrl
              sottotitolo
              description
              videoImage {
                link
                sourceUrl
                altText
                mediaDetails {
                  sizes {
                    height
                    width
                  }
                }
              }
            }
          }
          ... on Post {
            title
            dateGmt
            slug
            readingTime
            uri
            databaseId
            categories {
              edges {
                node {
                  name
                  uri
                  imagecategory {
                    caddysbeautystar
                  }
                  parent {
                    node {
                      name
                      uri
                      slug
                      parent {
                        node {
                          name
                        }
                      }
                    }
                  }
                  slug
                }
              }
            }
            sottotitolo {
              sottotitolo
            }
            featuredImage {
              node {
                link
                sourceUrl
                altText
                mediaDetails {
                  sizes {
                    height
                    width
                  }
                }
              }
            }
          }
        }
      }
    }

  }
`;
