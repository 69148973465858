import React from "react";
import NotificationsList from "../../components/NotificationsList";
import style from "./style.module.scss";

export default function NotificationsPage(props) {

  return (
    <React.Fragment>
      <div className={style.background}>
        <form className={style.form} action="submit">
          <h5 className={style.title}>Notifiche</h5>
          <NotificationsList />
        </form>
      </div>
    </React.Fragment>
  );
}
