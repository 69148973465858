import style from './style.module.css';

export default function Result({ reset, data }) {
    const formater = new Intl.NumberFormat('it-IT', {
        currency: 'EUR',
        style: 'currency',
    });
    return (
        <div className={`${style.bgWrapper}`}>
            <div className={`${style.title}`}>Risultato</div>
            <div className={`${style.subtitle}`}>
                In base ai tuoi dati inseriri il tuo <b>TFR lordo annuo</b>{' '}
                ammonta a:
            </div>
            <div className={style.response}>{formater.format(data.value)}</div>
            <div className={`${style.buttonWrapper}`}>
                <button
                    className={`${style.calcolaButton} btn btn-lg btn-block btn-primary`}
                    onClick={() => reset()}>
                    Ricalcola
                </button>
            </div>
        </div>
    );
}
