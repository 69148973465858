import { DECREMENT, INCREMENT, RESET_LOAD_COUNTER } from "../actions/loading";

export function loadingReducer(prevState = { loading: 0 }, action) {
  switch (action.type) {
    case INCREMENT:
      return { ...prevState, loading: prevState.loading + 1 };
    case DECREMENT:
      return {
        ...prevState,
        loading: prevState.loading <= 0 ? 0 : prevState.loading - 1,
      };
    case RESET_LOAD_COUNTER:
      return { ...prevState, loading: 0 };
    default:
      return prevState;
  }
}
