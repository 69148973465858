import style from "./small-card-horizontal.module.css";
import { filterCategory } from "../../tools/filter";
import _ from "lodash";
import { Link } from "react-router-dom";

export default function SmallCardHorizontal(props) {
  const date = new Date(Date.parse(props.data.dateGmt));

  var imageSrc = "";
  var imageAlt = "";

  if (props.data.__typename === "Post") {
    imageSrc = props.data.featuredImage?.node.sourceUrl;
    imageAlt = props.data.featuredImage?.node.altText;
  } else if (props.data.__typename === "Video") {
    imageSrc = props.data.VideosMeta.videoImage.sourceUrl;
    imageAlt = props.data.VideosMeta.videoImage.altText;
  }

  return (
    <div className={style.cardContainer}>
      <Link to={props.data.uri} className={style.imageContainer}>
        <img className={style.image} src={imageSrc} alt={imageAlt} />
      </Link>

      <div className={style.cardContent}>
        <div className={style.data}>
          <Link
            className={style.category}
            to={filterCategory(props.data.categories)?.uri}
          >
            {filterCategory(props.data.categories)?.name}
          </Link>
          <p className={style.date}>
            {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
          </p>
        </div>
        <h3>
          <Link className={style.title} to={props.data.uri}>
            {props.data.title}
          </Link>
        </h3>
        {props.data.__typename === "Post" ? (
          <p className={style.duration}>{props.data.readingTime} min</p>
        ) : null}
      </div>
    </div>
  );
}
