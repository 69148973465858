import { useState } from "react";
import { Link } from "react-router-dom";

import style from "./style.module.css";

export default function DropDown({ data, isActive, index }) {
  const [active, setActive] = useState(isActive ?? false);

  const firstModuleImageUrl =
    data.children.nodes[0] && data.children.nodes[0].featuredImage
      ? data.children.nodes[0].featuredImage.node.sourceUrl
      : null;
  return (
    <div
      className={`${style.dropDownWrapper} ${
        data.chapterStatus !== "active" && style.disabled
      }`}
    >
      <style
        dangerouslySetInnerHTML={{
          __html: `
                    @media screen and (max-width: 768px) {
                        .style_duration__1Hek-{
                            font-size: 14px;
                        }
                        .style_title__3RIc5{
                            font-size: 16px;
                        }
                        .chapterDuration{
                            display: none;
                        }
                        .style_icon__Lt0dx{
                            margin-left: 10px;
                        }
                        .style_title__32O_3 {
                            margin-top: 1rem;
                        }
                    }
                    `,
        }}
      />
      <div
        className={`${style.dropDownHead} ${active && style.activeHead}`}
        onClick={() =>
          setActive(data.chapterStatus === "active" ? !active : false)
        }
      >
        <div className={style.chapterWrapper}>
          <p className={style.chapter}>
            {data.chapterStatus !== "active" && (
              <span className={style.inactiveLabel}>
                PROSSIMAMENTE DISPONIBILE
              </span>
            )}
            <span>Capitolo</span> {data.menuOrder}
          </p>
          <div className={style.titleWrapper}>
            <p className={style.title}>{data.title}</p>
            {data.chapterStatus === "active" && (
              <p className={style.duration}>
                {data.children.nodes.length}{" "}
                {data.children.nodes.length === 1 ? "modulo" : "moduli"}{" "}
                <span className="chapterDuration">– {data.moduleLength}</span>
              </p>
            )}
          </div>
        </div>
        <svg
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          className={style.icon}
        >
          <path
            d="M13 7L7 1L1 7"
            stroke="var(--color-primary)"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div
        className={`${style.dropDownBody} ${
          active && style.dropDownBodyActive
        }`}
      >
        <div
          className={style.subtitle}
          dangerouslySetInnerHTML={{
            __html: data.excerpt,
          }}
        />
        ​
        <div className={style.bodyContent}>
          {firstModuleImageUrl && (
            <div className={style.imageWrapper}>
              <img
                className="w-100"
                src={firstModuleImageUrl}
                // alt={data.featuredImage.node.altText}
              />
            </div>
          )}
          <div className={style.contentColumn}>
            {data.children.nodes.map((module, index) => (
              <div key={index}>
                {module?.shortcodeName != "conclusion" && (
                  <div className={style.moduleWrapper}>
                    <div className={style.moduleContent}>
                      <p className={style.moduleNumber}>
                        Modulo {module.menuOrder}
                      </p>
                      <Link
                        to={module.uri.replace(/\/$/g, "")}
                        className={style.moduleTitle}
                      >
                        {module.title}
                      </Link>
                    </div>
                    <ul ischeckobx="true"></ul>
                    <Link to={module.uri.replace(/\/$/g, "")}>
                      <svg width="8" height="14" viewBox="0 0 8 14" fill="none">
                        <path
                          d="M1 13L7 7L1 1"
                          stroke="color(--color-primary)"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Link>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
