export const STORE_TOKEN = "STORE_TOKEN";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const DELETE_TOKEN = "DELETE_TOKEN";

export function storeToken(token, refreshToken, id, userId) {
  return {
    type: STORE_TOKEN,
    token,
    refreshToken,
    id,
    userId,
  };
}

export function refreshToken(token) {
  return {
    type: REFRESH_TOKEN,
    token,
  };
}

export function deleteToken() {
  return {
    type: DELETE_TOKEN,
  };
}
