import { Link } from "react-router-dom";

import { filterCategory } from "../../tools/filter";

import style from "./style.module.scss";

export default function GreyCardHorizontal(props) {
  const date = new Date(Date.parse(props.data.dateGmt));

  return ( 
    <div className={style.container} >
      <Link to={props.data.uri} className={style.imageContainer}>
        <img
          src={props.data.featuredImage?.node.sourceUrl}
          alt={props.data.featuredImage?.node.altText}
          className={style.image}
        />
      </Link>
      <div className={style.content}>
        <div className={style.data}>
          <Link
            to={filterCategory(props.data.categories)?.uri}
            className={style.category}
          >
            {filterCategory(props.data.categories)?.name}
          </Link>
          <h6 className={style.date}>
            {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
          </h6>
        </div>
        <Link to={props.data.uri} className={style.title}>
          {props.data.title}
        </Link>
        <Link className={style.description} to={props.data.uri}>
          {props.data.sottotitolo.sottotitolo}
        </Link>
        <p className={style.duration}>{props.data.readingTime} min</p>
      </div>
    </div>
  );
}
