import { STORE_TOKEN, REFRESH_TOKEN, DELETE_TOKEN } from "../actions/auth";

export function authReducer(
  prevState = { token: "", refreshToken: "" },
  action
) {
  switch (action.type) {
    case STORE_TOKEN:
      return {
        ...prevState,
        token: action.token,
        refreshToken: action.refreshToken,
        id: action.id,
        userId: action.userId,
      };
    case REFRESH_TOKEN:
      return {
        ...prevState,
        token: action.token,
      };
    case DELETE_TOKEN:
      return { ...prevState, token: "", refreshToken: "" };
    default:
      return prevState;
  }
}
