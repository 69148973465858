import gql from "graphql-tag";

export const GET_NOTIFICATIONS = gql`
  query GetNotificationsQuery($status: String!) {
    notifications(status: $status) {
      id
      title
      body
      content
      payload
      is_sent
      is_read
      link
      scheduled_at
      created_at
      updated_at
    }
  }
`;
