export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
export const MARK_NOTIFICATION_AS_READ = "MARK_NOTIFICATION_AS_READ";

export const setNotifications = (notifications) => ({
  type: SET_NOTIFICATIONS,
  payload: notifications,
});

export const updateNotification = (notification) => ({
  type: UPDATE_NOTIFICATION,
  payload: notification,
});

export const clearNotifications = () => ({
  type: CLEAR_NOTIFICATIONS,
});

export const markNotificationAsRead = (notificationId) => ({
  type: MARK_NOTIFICATION_AS_READ,
  payload: notificationId,
});
