import style from "./style.module.scss";
import { refreshClient } from "../..";
import { store } from "../../store";
import { refreshToken } from "../../store/actions/auth";
import { gql } from "@apollo/client";

export default function GuidaTfr() {
  const handleClick = async () => {
    const tokenResponse = await refreshClient.mutate({
      mutation: gql`
        mutation refreshToken($refreshToken: String!) {
          refreshJwtAuthToken(input: { jwtRefreshToken: $refreshToken }) {
            authToken
          }
        }
      `,
      variables: {
        refreshToken: store.getState().authReducer.refreshToken,
      },
    });

    const newToken = tokenResponse.data.refreshJwtAuthToken.authToken;
    store.dispatch(refreshToken(newToken));

    window.open(
      `https://assegnounico-dmo.laborability.com/585254?newtest=Y&lang=it&tk=${
        store.getState().authReducer.token
      }`,
      "_blank"
    );
  };

  return (
    <div className={`${style.background} pb-5`}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className={style.directoryContainer}>
              <a className={style.pathLink} href="/">
                Homepage
              </a>
              <span>
                <svg className={style.separator} viewBox="0 0 320 512">
                  <path
                    fill="var(--color-primary)"
                    d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                  />
                </svg>
                <a
                  className={style.pathLink}
                  href="/strumenti/guida-assegno-unico-universale"
                >
                  Guida alla compilazione dell’Assegno unico e universale
                </a>
              </span>
            </div>
          </div>
          <div className="col-12 col-lg-8 offset-lg-2">
            <h1 className={style.title}>
              INPS Assegno unico e universale 2022
            </h1>
            <h2 className={style.subTitle}>
              Scopri come utilizzare questa guida
            </h2>
            <div className={style.content}>
              <p className="mb-3">
                <strong>Istruzioni per l’uso</strong>
              </p>
              <p>
                Abbiamo deciso di realizzare questa guida per rendere ogni
                persona capace di{" "}
                <strong>richiedere in completa autonomia</strong> l’Assegno
                Unico.
                <br />
                Ci siamo messi nei panni dei molti genitori che provano a
                compilare la domanda online, chiedendoci se i passaggi fossero
                tutti <strong>chiari</strong>, se il linguaggio utilizzato fosse{" "}
                <strong>semplice</strong> ed <strong>accessibile</strong>.
              </p>
              <p>
                Se sei qui, probabilmente avevamo ragione nel credere che per
                arrivare fino in fondo alla compilazione c’è bisogno di{" "}
                <strong>chiarire alcuni dubbi</strong>, spiegare alcuni
                passaggi, essere rassicurati su alcune scelte.
              </p>
              <p>
                Per usare efficacemente questa guida, tieni aperto il sito
                dell’INPS{" "}
                <strong>
                  <a
                    href="https://www.inps.it/prestazioni-servizi/assegno-unico-e-universale-per-i-figli-a-carico"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    su questa pagina
                  </a>
                </strong>{" "}
                e contemporaneamente segui tutti i passaggi della nostra guida.
                Ti aiuteremo a capire quali sono i{" "}
                <strong>documenti necessari</strong> e come ottenerli, quali
                sono le <strong>informazioni da inserire</strong> e le{" "}
                <strong>opzioni da selezionare</strong> nel tuo specifico caso.
                Ti spiegheremo tutti i passaggi più complicati, come se a
                consigliarti fosse un professionista di cui puoi fidarti.
              </p>
              <p>
                La nostra missione è consentire a tutte le persone che ne hanno
                diritto di poter beneficiare dei sussidi che spettano loro per
                legge, <strong>senza difficoltà</strong> e senza barriere.
              </p>
              <div className="d-flex">
                <button
                  onClick={handleClick}
                  className={`${style.button} mb-0`}
                >
                  Vai alla guida
                </button>
              </div>
              <p className={style.disclaimer}>
                Il Wizard è uno strumento offerto gratuitamente da
                Laborability.com a puro scopo informativo. Laborability.com non
                garantisce l’accoglimento della domanda di assegno unico sulla
                base delle indicazioni fornite con il presente Wizard. L’utente
                esonera espressamente Laborability.com da qualsiasi
                responsabilità derivante dalle informazioni ottenute dalla
                consultazione del presente Wizard. Per qualsiasi dubbio,
                chiarimento e necessità l’utente{" "}
                <a
                  href="https://www.inps.it/prestazioni-servizi/assegno-unico-e-universale-per-i-figli-a-carico"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  deve rivolgersi senza indugio all’INPS
                </a>{" "}
                o ad un proprio professionista di fiducia.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
