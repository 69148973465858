import { useQuery } from "@apollo/client";
import { Fragment } from "react";
import { useSelector } from "react-redux";

import { HOME_DATA } from "./querys";

import Button from "../../components/Button";
import BookCard from "../../components/BookCard";
import VideoCard from "../../components/VideoCard";
import PodcastCard from "../../components/PodcastCard";
import BigCardSquare from "../../components/BigCardSquare";
import BigCardVertical from "../../components/BigCardVertical";
import BigCardHorizontal from "../../components/BigCardHorizontal";
import SmallCardVertical from "../../components/SmallCardVertical";
import SmallCardHorizontal from "../../components/Small-Card-Horizontal";
import Slider from "../../components/Slider";
import Social from "../../components/Social";

import style from "./style.module.scss";
import Caddys from "./caddys";
import BeautyStar from "./beautyStar";

export default function HomePage() {
  // const { loading, data } = useQuery(HOME_DATA);

  const activeTab = useSelector((state) => state.navbarReducer.active);

  // if (loading) {
  //   return null;
  // }

  // const index = new Date(Date.now()).getHours();

  // function shuffle(array) {
  //   var currentIndex = array.length,
  //     temporaryValue,
  //     randomIndex;
  //   while (0 !== currentIndex) {
  //     randomIndex = Math.floor(Math.random() * currentIndex);
  //     currentIndex -= 1;
  //     temporaryValue = array[currentIndex];
  //     array[currentIndex] = array[randomIndex];
  //     array[randomIndex] = temporaryValue;
  //   }
  //   return array;
  // }

  // const posts = data.posts.nodes;

  // const videos = data.videos.nodes;

  // const podcasts = data.podcasts.nodes;

  // const ebooks = data.ebooks.nodes.slice(0, 4);

  // const categories = {};

  // data.categories.nodes.forEach((category) => {
  //   categories[category.slug] = category;
  // });

  // const peoplePosts = posts.filter((post) =>
  //   post.categories.edges.find(
  //     (category) => category.node.imagecategory.peopleebusiness === "people"
  //   )
  // );

  // const businessPosts = posts.filter((post) =>
  //   post.categories.edges.find(
  //     (category) => category.node.imagecategory.peopleebusiness === "company"
  //   )
  // );

  // const peopleArticlesList = {};

  // peoplePosts.forEach((post) => {
  //   const postCategory = post.categories.edges.find((category) =>
  //     Boolean(category.node.imagecategory.peopleebusiness)
  //   ).node.slug;
  //   if (!peopleArticlesList[postCategory]) {
  //     peopleArticlesList[postCategory] = [];
  //   }
  //   peopleArticlesList[postCategory].push(post);
  // });

  // const peopleMaxLength = Math.min(
  //   ...Object.keys(peopleArticlesList).map(
  //     (category) => peopleArticlesList[category].length
  //   )
  // );

  // let peopleArticles;

  // if (Object.keys(peopleArticlesList).length < 4) {
  //   shuffle(peoplePosts);
  //   peopleArticles = peoplePosts.slice(0, 4);
  // } else {
  //   peopleArticles = Object.keys(peopleArticlesList).map(
  //     (category) => peopleArticlesList[category][index % peopleMaxLength]
  //   );
  //   peopleArticles = peopleArticles.slice(0, 4);
  //   shuffle(peopleArticles);
  // }

  // const businessArticlesList = {};

  // businessPosts.forEach((post) => {
  //   const postCategory = post.categories.edges.find((category) =>
  //     Boolean(category.node.imagecategory.peopleebusiness)
  //   ).node.slug;
  //   if (!businessArticlesList[postCategory]) {
  //     businessArticlesList[postCategory] = [];
  //   }
  //   businessArticlesList[postCategory].push(post);
  // });

  // const businessMaxLength = Math.min(
  //   ...Object.keys(businessArticlesList).map(
  //     (category) => businessArticlesList[category].length
  //   )
  // );

  // let businessArticles;

  // if (Object.keys(businessArticlesList).length < 4) {
  //   shuffle(businessPosts);
  //   businessArticles = businessPosts.slice(0, 4);
  // } else {
  //   businessArticles = Object.keys(businessArticlesList).map(
  //     (category) => businessArticlesList[category][index % businessMaxLength]
  //   );
  //   businessArticles = businessArticles.slice(0, 4);
  //   shuffle(businessArticles);
  // }

  // const mondoHr = data.menuItems;

  if (activeTab === "caddys") {
    return <Caddys />;
  } else {
    return <BeautyStar />;
  }
}
