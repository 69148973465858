import { TFR_API_URL } from "../../../../config";
import style from "./style.module.css";
import { useState } from "react";
import Result from "../../Result";

import ChartInAzienda from "../../ChartInAzienda";
import ChartFondoAperto from "../../ChartsFondoAperto";
import ChartFondoChiuso from "../../ChartsFondoChiuso";
import { useSelector } from "react-redux";

function RangeSlider({ value, setValue }) {
  const [isMosueDown, setIsMouseDown] = useState(false);

  return (
    <div className={style.sliderContainer}>
      <div className={style.rangeSlider}>
        <span className={style.number}>0</span>

        <div className={`${style.rangeWrapper} position-relative`}>
          <input
            type="range"
            value={value}
            min={0}
            max={40}
            className={style.slider}
            onChange={(event) => setValue(event.target.value)}
            onMouseDown={() => setIsMouseDown(true)}
            onMouseUp={() => setIsMouseDown(false)}
          />
          <div
            className={style.bubble}
            style={{
              left: `${(value * 100) / 40}%`,
              display: isMosueDown ? "block" : "none",
            }}
          >
            {value}
          </div>
        </div>

        <span className={style.number}>40</span>
      </div>
    </div>
  );
}

export default function GraphCard(props) {
  let {
    title,
    subtitle,
    inputTitle,
    inputSubtitle,
    sliderTitle,
    sliderSubtitle,
    bottomHtml,
    bottomText,
    quote,
    chartType,
    buttonText,
  } = JSON.parse(props.shortcodeConfig);

  const [value, setValue] = useState(0);
  const [amount, setAmount] = useState("");

  const [response, setResponse] = useState(null);

  const userId = useSelector((state) => state.authReducer.id);

  const handleSubmit = (event) => {
    event.preventDefault();

    //get this for analytics
    const formTitleElem = event.target.querySelector("div[data-form-title]");
    const formTitle = formTitleElem
      ? formTitleElem.getAttribute("data-form-title") || props.title
      : props.title;
    const formType = "Calculate form";

    if (value && amount.length) {
      if (chartType === "come-si-calcola-il-tfr") {
        const formTitleElem = document.querySelector("div[data-form-title]");
        const formTitle = formTitleElem
          ? formTitleElem.getAttribute("data-form-title") || props.title
          : props.title;
        const formType = "Calculate form";

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "tfr_invio_form",
          form_name: formTitle + ": Capitolo " + (props.chapterNumber || ""),
          form_type: formType,
        });

        let tfr_result = amount / 13.5;

        setResponse({ value: tfr_result });

        const formData = new FormData();
        formData.append("tfr_result", tfr_result);
        formData.append("userId", userId);

        if (props.uuid) {
          fetch(`${TFR_API_URL}/userdata/${props.uuid}`, {
            method: "POST",
            body: formData,
          })
            .then((response) => response.json())
            .catch((err) => console.log(err));
        }

      } else {
        fetch(
          `${TFR_API_URL}/calculate?type=${chartType}&starting_ral=${amount}&years=${value}`,
          {
            method: "POST",
          }
        )
          .then((response) => response.json())
          .then((data) => {
            setResponse(data);
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "tfr_invio_form",
              form_name:
                formTitle + ": Capitolo " + (props.chapterNumber || ""),
              form_type: formType,
            });
          });
      }
    } else {
      alert("Si prega di compilare il modulo correttamente!");
    }
  };

  const HandleNext = () => {
    switch (chartType) {
      case "come-si-calcola-il-tfr":
        return <Result data={response} reset={() => setResponse(null)} />;
      case "in_azienda":
        return (
          <ChartInAzienda data={response} reset={() => setResponse(null)} />
        );
      case "fondo_aperto":
        return (
          <ChartFondoAperto data={response} reset={() => setResponse(null)} />
        );
      case "fondo_chiuso":
        return (
          <ChartFondoChiuso data={response} reset={() => setResponse(null)} />
        );
      default:
        return null;
    }
  };

  return !response ? (
    <form onSubmit={handleSubmit} className={`${style.bgWrapper}`}>
      <div data-form-title={title} className={`${style.title}`}>
        {title}
      </div>
      <div className={`${style.subtitle}`}>{subtitle}</div>
      {quote && <div className={`${style.quote}`}>{quote}</div>}
      <div className={`${style.contentWrapper} row`}>
        <div className={`${style.textFiledsWrapper} col-6`}>
          <div className={`${style.fieldsTitle}`}>{inputTitle}</div>
          {inputSubtitle && (
            <div className={`${style.fieldsSubtitle}`}>{inputSubtitle}</div>
          )}
          <input
            className={style.input}
            value={amount}
            type="number"
            max={999000000}
            onChange={(event) => setAmount(event.target.value)}
            required
          />
        </div>
        <div className={`${style.sliderWrapper} col-6`}>
          <div className={`${style.fieldsTitle}`}>{sliderTitle}</div>
          {sliderSubtitle && (
            <div className={`${style.fieldsSubtitle}`}>{sliderSubtitle}</div>
          )}
          <RangeSlider value={value} setValue={(value) => setValue(value)} />
        </div>
      </div>
      {bottomHtml && (
        <div
          className={`${style.textWrapper}`}
          dangerouslySetInnerHTML={{ __html: bottomHtml }}
        />
      )}
      {bottomText && <div className={`${style.text}`}>{bottomText}</div>}
      <div className={`${style.buttonWrapper}`}>
        <button
          className={`${style.calcolaButton} btn btn-lg btn-block btn-primary`}
        >
          {buttonText}
        </button>
      </div>
    </form>
  ) : (
    <HandleNext />
  );
}
