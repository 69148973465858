import gql from "graphql-tag";

export const PODCAST = gql`
  query podcast($slug: ID!) {
    podcast(id: $slug, idType: SLUG) {
      title
      dateGmt
      slug
      content
      uri
      podcastId
      tags(first: 30) {
        nodes {
          name
          slug
        }
      }
      categories {
        nodes {
          parent {
            node {
              slug
            }
          }
          slug
          name
          uri
        }
      }
      podcast_meta {
        podcastAudioUrl
      }
      featuredImage {
        node {
          altText
          sourceUrl
          mediaDetails {
            sizes {
              width
              height
            }
          }
        }
      }
    }
    posts(first: 3, where: { status: PUBLISH }) {
      nodes {
        dateGmt
        title
        uri
        slug
        databaseId
        readingTime
        categories(where: { exclude: "64" }) {
          edges {
            node {
              slug
              name
              uri
              parent {
                node {
                  name
                  uri
                  slug
                  parent {
                    node {
                      name
                    }
                  }
                }
              }
            }
          }
        }
        author {
          node {
            name
          }
        }
        sottotitolo {
          sottotitolo
        }
        featuredImage {
          node {
            link
            sourceUrl
            altText
            mediaDetails {
              sizes {
                height
                width
              }
            }
          }
        }
      }
    }
    videos(first: 3) {
      nodes {
        title
        dateGmt
        slug
        uri
        categories {
          nodes {
            slug
            name
          }
        }
        VideosMeta {
          videoUrl
          sottotitolo
          description
          videoImage {
            link
            sourceUrl
            altText
            mediaDetails {
              sizes {
                height
                width
              }
            }
          }
        }
      }
    }
  }
`;

export const PODCAST_CORELATI = gql`
  query podcastCorelati($slug: String, $id: [ID]) {
    podcasts(first: 3, where: { categoryName: $slug, notIn: $id }) {
      nodes {
        title
        dateGmt
        slug
        uri
        categories {
          nodes {
            slug
            name
          }
        }
        podcast_meta {
          podcastAudioUrl
        }
        featuredImage {
          node {
            altText
            sourceUrl
            mediaDetails {
              sizes {
                width
                height
              }
            }
          }
        }
      }
    }
  }
`;
