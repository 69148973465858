import { GRAPHQL_API_URL } from "./config";
import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import Template from "./Template";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import {
  ApolloClient,
  ApolloProvider,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  from,
  gql,
} from "@apollo/client";
import { buildAxiosFetch } from "@lifeomic/axios-fetch";

import "bootstrap/dist/css/bootstrap.css";

import { persistor, store } from "./store";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { refreshToken, deleteToken } from "./store/actions/auth";

import "./global.scss";
import { decrement, increment, resetCounter } from "./store/actions/loading";

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
    pollInterval: 300000, // 5 minutes in milliseconds
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
};


export const refreshClient = new ApolloClient({
  uri: GRAPHQL_API_URL,
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions
});

export async function refreshAuthToken(callback = null) {
  try {
    const tokenResponse = await refreshClient.mutate({
      mutation: gql`
        mutation refreshToken($refreshToken: String!) {
          refreshJwtAuthToken(input: { jwtRefreshToken: $refreshToken }) {
            authToken
          }
        }
      `,
      variables: {
        refreshToken: store.getState().authReducer.refreshToken,
      },
    });

    const newToken = tokenResponse.data.refreshJwtAuthToken.authToken;
    store.dispatch(refreshToken(newToken));
    if (callback) callback();
    return newToken;
  } catch (error) {
    console.log("Error refreshing auth token:", error);
    store.dispatch(deleteToken());
    // throw error; // Re-throw the error so it can be caught in the interceptor or wherever this function is used
  }
}

const instance = axios.create({
  baseURL: GRAPHQL_API_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
});


// Request Interceptor
instance.interceptors.request.use(
  async (config) => {
    store.dispatch(increment()); // Increment the counter on every request
    return config;
  },
  async (error) => {
    store.dispatch(decrement()); // Decrement the counter on request error
    return Promise.reject(error);
  }
);

// Response interceptor
instance.interceptors.response.use(
  async (response) => {
    store.dispatch(decrement());  // Decrement the counter on response
    return response;
  },
  async (error) => {
    store.dispatch(decrement()); // Decrement the counter on response error

    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const tokenResponse = await refreshClient.mutate({
          mutation: gql`
            mutation refreshToken($refreshToken: String!) {
              refreshJwtAuthToken(input: { jwtRefreshToken: $refreshToken }) {
                authToken
              }
            }
          `,
          variables: {
            refreshToken: store.getState().authReducer.refreshToken,
          },
        });

        const newToken = tokenResponse.data.refreshJwtAuthToken.authToken;
        store.dispatch(refreshToken(newToken));
        
        // Update the authorization in headers
        instance.defaults.headers.common['Authorization'] = 'Bearer ' + newToken;
        originalRequest.headers['Authorization'] = 'Bearer ' + newToken;

        // Retry the request with updated token
        return instance(originalRequest);
      } catch (refreshError) {
        console.log(refreshError);
        store.dispatch(deleteToken());
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);


const authMiddleware = new ApolloLink((operation, forward) => {
  if (operation.operationName !== "navbarMenu") {
    const token = store.getState().authReducer.token;
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: token.length ? `Bearer ${token}` : null,
      },
    }));
  }
  return forward(operation).map((data) => {
    return data;
  });
});

const httpLink = new HttpLink({
  uri: GRAPHQL_API_URL,
  fetch: buildAxiosFetch(instance),
});

const client = new ApolloClient({
  link: from([authMiddleware, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ApolloProvider client={client}>
          <Template />
        </ApolloProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

try {
  serviceWorkerRegistration.register();
} catch (err) {
  console.log(err);
}
