import { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import SwiperCore, { EffectCoverflow } from "swiper";

import style from "./style.module.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { BANNERS_DATA } from "./querys";

SwiperCore.use([EffectCoverflow]);

export default function OldProductsSlider() {
  const activeTab = useSelector((state) => state.navbarReducer.active);
  const swiperRef = useRef();

  const { data, loading } = useQuery(BANNERS_DATA);

  if (loading) {
    return null;
  }

  const oldProducts = [];

  if (activeTab === "caddys") {
    data.banners.nodes.forEach((banner, index) => {
      if (banner.page == "Caddys" && banner.section == "bottom") {
        oldProducts[index] = banner;
      }
    });
  } else {
    data.banners.nodes.forEach((banner, index) => {
      if (banner.page == "BeautyStar" && banner.section == "bottom") {
        oldProducts[index] = banner;
      }
    });
  }

  return (
    <div className={style.container}>
      <div className={style.sliderContainer}>
        <div
          className={`${style.iconContainer} ${style.leftIcon}`}
          onClick={() => swiperRef.current.swiper.slidePrev()}
        >
          <svg className={style.icon} viewBox="0 0 24 24">
            <path d="M10.6,12.71a1,1,0,0,1,0-1.42l4.59-4.58a1,1,0,0,0,0-1.42,1,1,0,0,0-1.41,0L9.19,9.88a3,3,0,0,0,0,4.24l4.59,4.59a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.42Z" />
          </svg>
        </div>
        <div className={style.sliderContent}>
          <Swiper
            className={style.sliderWrapper}
            speed={1000}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={1}
            loop={true}
            effect={"coverflow"}
            coverflowEffect={{
              rotate: 0,
              stretch: -20,
              depth: 240,
              modifier: 1,
              slideShadows: false,
            }}
            pagination={true}
            ref={swiperRef}
          >
            {oldProducts.map((product, index) => (
              <SwiperSlide key={index}>
                <Link
                  to={product.follow}
                  className={`${style.imageContainer} d-block`}
                >
                  <img
                    src={product.image}
                    alt={product.title}
                    className={style.image}
                  />
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div
          className={`${style.iconContainer} ${style.rightIcon}`}
          onClick={() => swiperRef.current.swiper.slideNext()}
        >
          <svg className={style.icon} viewBox="0 0 24 24">
            <path d="M15.4,9.88,10.81,5.29a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42L14,11.29a1,1,0,0,1,0,1.42L9.4,17.29a1,1,0,0,0,1.41,1.42l4.59-4.59A3,3,0,0,0,15.4,9.88Z" />
          </svg>
        </div>
      </div>
    </div>
  );
}
