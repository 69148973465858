import { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

import style from './style.module.scss';
import 'swiper/swiper.min.css';
import { Link } from 'react-router-dom';

export default function BlueSlider() {
  const swiperRef = useRef();
  const [sliderState, setSliderState] = useState({
    isBeginning: true,
    isEnd: true,
  });
  return (
    <div className={style.container}>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-lg-3 '>
            <p className={style.title}>Formazione</p>
            <p className={style.content}>
              Progetti e piani di Formazione del Gruppo DMO dedicati al
              personale dei negozi e di sede, attraverso e-learning e corsi in
              presenza. Dalla sicurezza, all’utilizzo di gestionali e software,
              allo sviluppo delle competenze comunicative.
            </p>
          </div>
          <div className='col-12 col-lg-8 offset-lg-1 d-md-block'>
            <div className={style.sliderContainer}>
              <div
                className={`${style.iconContainer} d-lg-none`}
                style={{ opacity: sliderState.isBeginning ? '0.6' : '1' }}
                onClick={() => swiperRef.current.swiper.slidePrev()}>
                <svg className={style.icon} viewBox='0 0 24 24'>
                  <path d='M10.6,12.71a1,1,0,0,1,0-1.42l4.59-4.58a1,1,0,0,0,0-1.42,1,1,0,0,0-1.41,0L9.19,9.88a3,3,0,0,0,0,4.24l4.59,4.59a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.42Z' />
                </svg>
              </div>
              <div className={style.sliderContent}>
                <div className={style.sliderWrapper}>
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    breakpoints={{
                      992: {
                        slidesPerView: 2,
                      },
                    }}
                    onInit={(swiper) => {
                      const { isBeginning, isEnd } = swiper;
                      setSliderState({ isBeginning, isEnd });
                    }}
                    onSlideChange={(swiper) => {
                      const { isBeginning, isEnd } = swiper;
                      setSliderState({ isBeginning, isEnd });
                    }}
                    ref={swiperRef}>
                    <SwiperSlide>
                      <Link
                        to='/mondo-hr/sicurezza-a-portata-di-click-con-la-formazione-e-learning-dmo'
                        className={`${style.imageContainer} d-block`}>
                        <img
                          src='https://contentsa1.fra1.cdn.digitaloceanspaces.com/laborability/media/2021/11/e-learning-1.jpg'
                          alt='Ilustration'
                          className={style.image}
                        />
                      </Link>
                    </SwiperSlide>
                    <SwiperSlide>
                      <Link
                        to='https://lbt-for-react.vercel.app/mondo-hr/il-nuovo-piano-di-formazione-del-personale-del-gruppo-dmo-2'
                        className={`${style.imageContainer} d-block`}>
                        <img
                          src='https://contentsa1.fra1.cdn.digitaloceanspaces.com/laborability/media/2021/11/formazione-dmo.jpg'
                          alt='Ilustration'
                          className={style.image}
                        />
                      </Link>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
              <div
                className={`${style.iconContainer} d-lg-none`}
                style={{ opacity: sliderState.isEnd ? '0.1' : '1' }}
                onClick={() => swiperRef.current.swiper.slideNext()}>
                <svg className={style.icon} viewBox='0 0 24 24'>
                  <path d='M15.4,9.88,10.81,5.29a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42L14,11.29a1,1,0,0,1,0,1.42L9.4,17.29a1,1,0,0,0,1.41,1.42l4.59-4.59A3,3,0,0,0,15.4,9.88Z' />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
