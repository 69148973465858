import gql from "graphql-tag";

export const PODCASTS = gql`
  query podcasts {
    category(id: "podcast", idType: SLUG) {
      name
      description
      children {
        nodes {
          name
          description
          slug
          podcasts {
            nodes {
              dateGmt
              title
              uri
              slug
              categories {
                nodes {
                  slug
                  uri
                  name
                }
              }
              podcast_meta {
                podcastAudioUrl
              }
              content
              featuredImage {
                node {
                  sourceUrl
                  mediaDetails {
                    sizes {
                      height
                      width
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
