import { Fragment, useState } from "react";
import { useQuery } from "@apollo/client";
import { useRouteMatch } from "react-router";
import lodash from "lodash";

import JobCard from "../../components/JobCard";
import Error from "../../components/Error";

import style from "./style.module.scss";

import { CATEGORIES } from "./querys";

const paginationSize = 12;

export default function JobsPage() {
  const router = useRouteMatch();
  const subcategory = lodash.compact(router.url.split("/"));

  let index;
  if (lodash.nth(subcategory, -2) === "jobs") {
    index = lodash.nth(subcategory, -1);
  } else {
    index = 1;
  }

  const jobs = useQuery(CATEGORIES, {
    variables: {
      offset: (index - 1) * paginationSize,
    },
  });

  if (jobs.loading) {
    return null;
  }

  const pageInfo = { ...jobs.data.jobs.pageInfo };
  pageInfo.current = index;
  pageInfo.size = paginationSize;

  if (!jobs.data.jobs.nodes.length) {
    return <Error />;
  }

  return (
    <Fragment>
      <div className="container pt-4">
        {/* <Breadcrumb category={categoryData} /> */}
        <h1 className={style.mainTitle}>Posizioni aperte</h1>
        {/* <h3 className={style.sectionDescription}>{categoryData.description}</h3> */}
      </div>

      <div className="container">
        <div className="row pb-4">
          {jobs.data.jobs.nodes.map((postData, index) => (
            <div className="col-12 col-md-6 col-lg-4 my-3" key={postData.slug}>
              <JobCard data={postData} />
            </div>
          ))}
        </div>
      </div>
      {/* {categoryData.children.nodes.length === 0 ? (
        <div className="pt-2 pb-4">
          <Pagination data={pageInfo} />
        </div>
      ) : null} */}
    </Fragment>
  );
}
