import { gql } from "@apollo/client";

export const SUBMIT_NEWSLETTER = gql`
  mutation submitNewsLetter($data: CreateSubmissionInput!) {
    createSubmission(input: $data) {
      data
      success
    }
  }
`;
