import { Fragment, useState } from "react";
import { useQuery } from "@apollo/client";
import { useRouteMatch } from "react-router";
import lodash from "lodash";

import Pagination from "../../components/Pagination";
import Breadcrumb from "../../components/BradcrumbNew";
import BigCardVertical from "../../components/BigCardVertical";
import Slider from "../../components/Slider";
import SmallCardVertical from "../../components/SmallCardVertical";
import Error from "../../components/Error";

// import FilterCategory from "../../components/Filter-Category";

import level1 from "./level1.module.scss";
import level2 from "./level2.module.scss";

import { store } from "../../store";
import { beautyStarTab, caddysTab } from "../../store/actions/navbar";

import { CATEGORIES } from "./querys";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const paginationSize = 12;

export default function PostsPage() {
  const activeTab = useSelector((state) => state.navbarReducer.active);

  const routerController = useHistory();
  const router = useRouteMatch();
  const subcategory = lodash.compact(router.url.split("/"));
  const category = subcategory[1];

  let subcategoryString;
  let index;
  if (lodash.nth(subcategory, -2) === "page") {
    subcategoryString = lodash.nth(subcategory, -3);
    index = lodash.nth(subcategory, -1);
  } else {
    subcategoryString = lodash.nth(subcategory, -1);
    index = 1;
  }

  const categories = useQuery(CATEGORIES, {
    variables: {
      slug: subcategoryString,
      categorySlug: subcategoryString,
      offset: (index - 1) * paginationSize,
    },
  });

  if (categories.loading) {
    return null;
  }

  const data = categories.data;
  const postData = data.posts.nodes;

  const pageInfo = { ...data.posts.pageInfo };
  pageInfo.current = index;
  pageInfo.size = paginationSize;

  const categoryData = data.category;
  pageInfo.url = categoryData.uri;

  if (!data.category) {
    return <Error />;
  }

  if (router.url.includes("caddys")) {
    store.dispatch(caddysTab());
  } else if (router.url.includes("beautystar")) {
    store.dispatch(beautyStarTab());
  }

  return (
    <Fragment>
      <div className="container pt-4">
        <Breadcrumb category={categoryData} />
        <h1 className={level2.mainTitle}>{categoryData.name}</h1>
        <h3 className={level2.sectionDescription}>
          {categoryData.description}
        </h3>
      </div>
      {category === "mondo-hr" ? (
        <div className="container my-5">
          <img
            src="/assets/images/mondohr-banner-mobile.png"
            alt="Banner 1"
            className="d-lg-none w-100"
          />
          <img
            src="/assets/images/mondohr-banner.png"
            alt="Banner 1"
            className="d-none d-lg-block w-100"
          />
        </div>
      ) : null}
      <div className="container mb-5">
        {categoryData.children?.nodes.length !== 0 && (
          <div>
            <div className={`${level1.doubleSideFlex} pt-5`}>
              <h2 className={level1.sectionTitle}>Ultimi articoli</h2>
            </div>
            <Slider className="row">
              {categoryData.posts.nodes.map((post) => (
                <div className="col-12 col-md-6 col-lg-3 my-3" key={post.slug}>
                  <SmallCardVertical data={post} />
                </div>
              ))}
            </Slider>
          </div>
        )}
        {categoryData.children?.nodes.length !== 0 ? (
          categoryData.children.nodes.map((subCategory) =>
            subCategory.posts.nodes.length === 0 ||
            (subCategory.imagecategory.caddysbeautystar !== activeTab &&
              subCategory.imagecategory.caddysbeautystar !==
                "caddysbeautystar") ? null : (
              <div key={subCategory.slug}>
                <div className={`${level1.doubleSideFlex} pt-5`}>
                  <Link to={subCategory.uri}>
                    <h2 className={level1.sectionTitle}>{subCategory.name}</h2>
                  </Link>

                  <Link
                    className={`${level1.moreArticles} d-none d-md-block`}
                    to={subCategory.uri}
                  >
                    Vedi altro
                  </Link>
                </div>

                <Slider className="row" more={subCategory.uri}>
                  {subCategory.posts.nodes.map((post) => (
                    <div
                      className="col-12 col-md-6 col-lg-3 my-3"
                      key={post.slug}
                    >
                      <SmallCardVertical data={post} />
                    </div>
                  ))}
                </Slider>
              </div>
            )
          )
        ) : (
          <div className="row pb-4">
            {postData.map((postData, index) => (
              <div
                className="col-12 col-md-6 col-lg-4 my-3"
                key={postData.slug}
              >
                <BigCardVertical data={postData} />
              </div>
            ))}
          </div>
        )}
      </div>
      {categoryData.children.nodes.length === 0 ? (
        <div className="pt-2 pb-4">
          <Pagination data={pageInfo} />
        </div>
      ) : null}
    </Fragment>
  );
}
