import { combineReducers } from "redux";

import { loadingReducer } from "./loading";
import { navbarReducer } from "./navbar";
import { authReducer } from "./auth";
import notificationsReducer from "./notificationsReducer";

export default combineReducers({
  loadingReducer,
  navbarReducer,
  authReducer,
  notificationsReducer,
});
