import { useRouteMatch } from "react-router";
import { useQuery } from "@apollo/client";
import { Fragment } from "react";
import lodash from "lodash";

import Button from "../../components/Button";
import Slider from "../../components/Slider";
import BigCardVertical from "../../components/BigCardVertical";
import LinkSection from "../../components/LinkSection";
import VideoCard from "../../components/VideoCard";
import Breadcrumb from "../../components/Breadcrumb";

import { BOOK } from "./querys";

import style from "./style.module.scss";

export default function EbookPage() {
  const router = useRouteMatch();

  const slug = lodash.nth(lodash.compact(router.url.split("/")), -1);

  const book = useQuery(BOOK, {
    variables: {
      slug,
    },
  });

  if (book.loading) {
    return null;
  }

  const postsData = book.data.posts.nodes;

  const videosData = book.data.videos.nodes;

  const bookData = book.data.ebook;

  return (
    <Fragment>
      <div className="container pt-4">
        <Breadcrumb uri={`/ebook/${bookData.slug}`} />
        <div className="row pt-5">
          <div className="col-12 col-md-7 col-lg-7">
            <div className={style.imageContainer}>
              <img
                className={style.mainImage}
                src={bookData.EbooksMeta.ebookImage.sourceUrl}
                alt={bookData.EbooksMeta.ebookImage.altText}
              />
              <label className={style.bookTitle}>{bookData.title}</label>
            </div>
          </div>
          <div className="col-12 col-md-5 col-lg-5 pt-0 p-md-5">
            <h1 className={style.mainTitle}>{bookData.title}</h1>
            <h2 className={style.mainSubtitle}>
              {bookData.EbooksMeta.ebookSottotitolo}
            </h2>
            <h3
              className={style.sectionDescription}
              dangerouslySetInnerHTML={{
                __html: bookData.EbooksMeta.description,
              }}
            />
            <div className="mt-5">
              <a
                className={`button blue ${style.download}`}
                href={bookData.EbooksMeta.urlEbook}
              >
                <Button className="button blue">Scarica ora</Button>
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className={style.doubleSideFlex}>
            <h2 className={style.sectionTitle}>Articoli correlati</h2>
            <a
              className={`${style.moreArticles} d-none d-md-block`}
              href="/tema"
            >
              Vedi altri articoli
            </a>
          </div>
          <Slider className="row">
            {postsData.map((postData) => (
              <div className="col-12 col-md-4" key={postData.slug}>
                <BigCardVertical data={postData} />
              </div>
            ))}
          </Slider>
        </div>
        <div className="row py-5">
          <div className={style.doubleSideFlex}>
            <h2 className={style.sectionTitle}>Novità per il lavoro</h2>
            <a
              className={`${style.moreArticles} d-none d-md-block`}
              href="/video"
            >
              Vedi altri video
            </a>
          </div>
          <Slider className="row" more="/video">
            {videosData.map((videoData) => (
              <div
                className="col-12 col-md-4 my-3 my-md-0"
                key={videoData.slug}
              >
                <VideoCard data={videoData} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="mt-5">
        <LinkSection video />
      </div>
    </Fragment>
  );
}
