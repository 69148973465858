import style from "./breadcrumb.module.css";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Breadcrumb(props) {
  let crumbs = new Array();
  const sortedArray = new Array();
  crumbs.push({
    name: props.category.name,
    id: props.category.id,
    parentId: props.category.parentId,
    uri: props.category.uri,
  });
  props.category.ancestors?.nodes.forEach((node) => {
    crumbs.push({
      name: node.name,
      id: node.id,
      parentId: node.parentId,
      uri: node.uri,
    });
  });
  let currentId = null;
  while (crumbs.length > 0) {
    const inLineCrumb = crumbs.find((crumb) => crumb.parentId === currentId);
    crumbs = crumbs.filter((crumb) => crumb.parentId !== currentId);
    currentId = inLineCrumb?.id;
    sortedArray.push(inLineCrumb);
  }
  if (props.tutti) {
    sortedArray.push({ name: "Ultimi articoli" });
  }
  let last = sortedArray.pop();

  return (
    <div className="style.directoryContainer">
      <Link className={style.pathLink} to="/">
        Homepage
      </Link>
      {sortedArray.map((category, index) => (
        <span key={category.id}>
          <svg className={style.separator} viewBox="0 0 320 512">
            <path
              fill="var(--color-primary)"
              d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
            />
          </svg>
          <Link className={style.pathLink} to={category?.uri}>
            {category?.name}
          </Link>
        </span>
      ))}
      <span key={last.id}>
        <svg className={style.separator} viewBox="0 0 320 512">
          <path
            fill="var(--color-primary)"
            d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
          />
        </svg>
        <span className={style.pathLink}>{last?.name}</span>
      </span>
    </div>
  );
}
