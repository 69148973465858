import style from "./breadcrumb.module.css";
import { useEffect, useState } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";

export default function Breadcrumb(props) {
  let { categories, uri, ancestors } = props;
  let sorted = [];
  function capitalize(string) {
    if (string === "ebook") return "eBook";
    const capitalized = string.charAt(0).toUpperCase() + string.slice(1);
    return capitalized?.replace(/-/g, " ");
  }
  const nest = (items, id = null, link = "parentId") =>
    items
      .filter((item) => item[link] === id)
      .map((item) => ({ ...item, children: nest(items, item.id) }));

  function sortRecursive(arr) {
    if (arr.lenght === 0 || arr[0] === undefined) return;

    sorted.push(arr[0]);
    if (arr[0].children) sortRecursive(arr[0].children);
  }

  if (props.categories || props.ancestors) {
    let flated = [];
    if (categories && categories.edges)
      _.each(categories.edges, (v) => {
        flated.push(v.node);
      });

    if (props.ancestors) flated = ancestors;

    flated = flated.filter(function (el) {
      return el.slug != "articoli-scelti";
    });

    const breadcrumb = nest(flated);

    sortRecursive(breadcrumb);

    return (
      <div className="style.directoryContainer" id="breadcrumb">
        <Link className={style.pathLink} to="/">
          Homepage
        </Link>
        {!sorted.length
          ? null
          : sorted.map((cat) => {
              return (
                <span key={cat.id}>
                  <svg className={style.separator} viewBox="0 0 320 512">
                    <path
                      fill="var(--color-primary)"
                      d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                    />
                  </svg>
                  <Link className={style.pathLink} to={`${cat.uri}`}>
                    {cat.name}
                  </Link>
                </span>
              );
            })}
      </div>
    );
  } else if (props.uri) {
    const path = _.compact(props.uri.split("/"));

    return (
      <div className="style.directoryContainer">
        <Link className={style.pathLink} to="/">
          Homepage
        </Link>
        {!path.length
          ? null
          : path.map((category, index) =>
              category === "tema" ? null : (
                <span key={category}>
                  <svg className={style.separator} viewBox="0 0 320 512">
                    <path
                      fill="var(--color-primary)"
                      d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                    />
                  </svg>
                  <Link
                    className={style.pathLink}
                    to={`/${path.slice(0, index + 1).join("/")}`}
                  >
                    {capitalize(category)}
                  </Link>
                </span>
              )
            )}
      </div>
    );
  }
}
