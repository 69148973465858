import { gql, useMutation } from "@apollo/client";

export const RESET = gql`
  mutation ResetUserPassword(
    $key: String!
    $login: String!
    $password: String!
  ) {
    resetUserPassword(
      input: {
        clientMutationId: ""
        key: $key
        login: $login
        password: $password
      }
    ) {
      clientMutationId
      user {
        jwtAuthToken
        jwtRefreshToken
        databaseId
        id
      }
    }
  }
`;

export const useResetUserPasswordMutation = () => {
  const [mutation, mutationResults] = useMutation(RESET);

  const resetUserPassword = (key, login, password) => {
    return mutation({
      variables: {
        key,
        login,
        password,
      },
    });
  };

  return { resetUserPassword, results: mutationResults };
};
