import { useQuery } from "@apollo/client";
import { Fragment } from "react";

import Breadcrumb from "../../components/Breadcrumb";
import BookCardBig from "../../components/Book-Card-Big";
import LinkSection from "../../components/LinkSection";

import { EBOOKS } from "./querys";

import style from "./style.module.scss";

export default function EbookIndexPage() {
  const ebooks = useQuery(EBOOKS);

  if (ebooks.loading) {
    return null;
  }

  const booksData = ebooks.data.ebooks.nodes;
  const categoryData = ebooks.data.category;

  return (
    <Fragment>
      <div className="container pt-4">
        <Breadcrumb uri="/ebook" />
        <h1 className={style.mainTitle}>{categoryData.name}</h1>
        <h2 className={style.sectionDescription}>{categoryData.description}</h2>
        <div className="row">
          {booksData.map((bookData) => (
            <div className="col-12 col-md-6 my-5" key={bookData.slug}>
              <BookCardBig data={bookData} />
            </div>
          ))}
        </div>
      </div>
      <div className="mt-5 pt-5">
        <LinkSection video />
      </div>
    </Fragment>
  );
}
