import gql from "graphql-tag";

export const EBOOKS = gql`
  query ebooks {
    ebooks {
      nodes {
        EbooksMeta {
          ebookSottotitolo
          ebookImage {
            altText
            link
            sourceUrl
            mediaDetails {
              sizes {
                height
                width
              }
            }
          }
        }
        title
        slug
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }
    category(id: "dGVybToxNTgw") {
      name
      description
    }
  }
`;
