export const INCREMENT = "INCREMENT";
export const DECREMENT = "DECREMENT";
export const RESET_LOAD_COUNTER = "RESET_LOAD_COUNTER";

export function increment() {
  return {
    type: INCREMENT,
  };
}

export function decrement() {
  return {
    type: DECREMENT,
  };
}

export function resetCounter() {
  return {
    type: RESET_LOAD_COUNTER,
  };
}
