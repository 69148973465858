import Modal from 'react-bootstrap/Modal';
import { Col, Container, Row } from 'react-bootstrap';
import { useState } from 'react';
import arrowUp from './arrow-up.svg';
import arrowDown from './arrow-down.svg';
import style from './style.module.css';
export default function SelectInputComponent({ contentKey, optionsList, propFunctionFromParent }) {
    const [submited, setSubmited] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const toggling = () => setIsOpen(!isOpen);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedValue, setSelectedValue] = useState();

    const onOptionClicked = (contentKey, value) => () => {
        setSelectedOption(value.option);
        setSelectedValue(value.value);
        setIsOpen(false);

        propFunctionFromParent(contentKey, value);
    };

    const OptionsComponents = ({ optionsData }) => {
        return <div className={`${style.options}`}>{optionsData.option}</div>;
    };
     
    if (!submited) {
        return (
            <div className={`${style.dropDownContainer}`}>
                <div
                    className={
                        `${style.dropDownHeader}` +
                        (isOpen ? ` ${style.headerOpened}` : ' ')
                    }
                    onClick={toggling}>
                    <div className={`${style.selectedOption}`}>
                        {selectedOption ?? "Scegliere un'opzione"}
                    </div>
                    <img
                        src={arrowDown}
                        className={
                            `${style.image}` +
                            (isOpen ? ` ${style.imageOpened}` : ' ')
                        } />
                </div>
                {isOpen && (
                    <div className={`${style.dropDownOptionsWrapper}`}>
                        <div className={`${style.dropDownOptions}`}>
                            {optionsList.map((options, index) => (
                                <div
                                    key={index}
                                    onClick={onOptionClicked(contentKey, options)}
                                    >
                                    <OptionsComponents 
                                        optionsData={options} 
                                        />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
