import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { store } from "../../store";
import { resetCounter } from "../../store/actions/loading";
import style from "./style.module.scss";

export default function Loading() {
  const loading = Boolean(useSelector((state) => state.loadingReducer.loading));
  const router = useLocation();
  useEffect(() => {
    if (router.pathname === "/login") {
      store.dispatch(resetCounter());
    }
  }, [router]);
  return loading ? (
    <div className={style.container}>
      <img
        src="/assets/svgs/loading.svg"
        alt="Loading"
        className={style.image}
      />
    </div>
  ) : null;
}
