import gql from "graphql-tag";

export const QUERY_SEARCH_POSTS = gql`
  query getPostsPerOffset($searchString: String, $offset: Int) {
    posts(
      where: {
        search: $searchString
        offsetPagination: { offset: $offset, size: 12 }
        status: PUBLISH
      }
    ) {
      nodes {
        dateGmt
        title
        uri
        slug
        databaseId
        readingTime
        categories(where: { exclude: "64" }) {
          edges {
            node {
              slug
              name
              uri
              parent {
                node {
                  name
                  uri
                  slug
                  parent {
                    node {
                      name
                    }
                  }
                }
              }
            }
          }
        }
        author {
          node {
            name
          }
        }
        sottotitolo {
          sottotitolo
        }
        featuredImage {
          node {
            link
            sourceUrl
            altText
            mediaDetails {
              sizes {
                height
                width
              }
            }
          }
        }
      }
      pageInfo {
        offsetPagination {
          hasMore
          hasPrevious
          total
        }
      }
    }
  }
`;

export const QUERY_SEARCH_VIDEOS = gql`
  query getVideosPerOffset($searchString: String, $offset: Int) {
    videos(
      where: {
        search: $searchString
        offsetPagination: { offset: $offset, size: 9 }
      }
    ) {
      nodes {
        title
        dateGmt
        slug
        categories {
          nodes {
            slug
            name
          }
        }
        VideosMeta {
          videoUrl
          sottotitolo
          description
          videoImage {
            link
            sourceUrl
            altText
            mediaDetails {
              sizes {
                height
                width
              }
            }
          }
        }
      }
      pageInfo {
        offsetPagination {
          hasMore
          hasPrevious
          total
        }
      }
    }
  }
`;

export const QUERY_SEARCH_BOOKS = gql`
  query getBooksPerOffset($searchString: String, $offset: Int) {
    ebooks(
      where: {
        search: $searchString
        offsetPagination: { offset: $offset, size: 12 }
      }
    ) {
      nodes {
        slug
        dateGmt
        title
        EbooksMeta {
          ebookSottotitolo
          description
          ebookImage {
            altText
            sourceUrl
            mediaDetails {
              sizes {
                height
                width
              }
            }
          }
        }
      }
      pageInfo {
        offsetPagination {
          hasMore
          hasPrevious
          total
        }
      }
    }
  }
`;
