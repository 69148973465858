import gql from "graphql-tag";

export const LOGIN = gql`
  mutation loginUser($email: String!, $password: String!) {
    login(
      input: {
        clientMutationId: "uniqueId"
        username: $email
        password: $password
      }
    ) {
      authToken
      refreshToken
      user {
        id
        name
        firstName
        email
        databaseId
      }
    }
  }
`;
