import GraphCard from './GraphCard';
import ListCard from './AdvantagesDisadvantages';

export default function ExtraComponent({
    uuid,
    title,
    shortcodeName,
    shortcodeConfig,
    chapterNumber
}) {
    switch (shortcodeName) {
        case 'simulator_slider':
            return (
                <GraphCard
                    uuid={uuid}
                    title={title}
                    shortcodeName={shortcodeName}
                    shortcodeConfig={shortcodeConfig}
                    chapterNumber={chapterNumber}
                />
            );
        case 'yes_no_list':
            return (
                <ListCard
                    title={title}
                    shortcodeName={shortcodeName}
                    shortcodeConfig={shortcodeConfig}
                />
            );
        default:
            return null;
    }
}
