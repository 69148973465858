import { useQuery } from "@apollo/client";
import { Fragment } from "react";

import Slider from "../../components/Slider";
import SmallCardVertical from "../../components/SmallCardVertical";
import { ULTIMI_ARTICOLI } from "./querys";

import style from "./style.module.scss";

export default function UltimiArticoli(props) {
  const { ids } = props;
  const ultimiArticoli = useQuery(ULTIMI_ARTICOLI, {
    variables: {
      ids,
    },
  });

  if (ultimiArticoli.loading) {
    return null;
  }

  const ultimiArticoliData = ultimiArticoli.data.posts.nodes;

  return ultimiArticoliData.length === 0 ? null : (
    <Fragment>
      <h2 className={style.sectionTitle}>Ultimi articoli</h2>
      <Slider className={style.verticalFlex}>
        {ultimiArticoliData.map((data) => (
          <div className="my-3" key={data.slug}>
            <SmallCardVertical data={data} desktop />
          </div>
        ))}
      </Slider>
    </Fragment>
  );
}
