import { Fragment } from "react";
import { useRouteMatch } from "react-router";
import lodash from "lodash";
import { useQuery } from "@apollo/client";

import VideoCard from "../../components/VideoCard";
import LinkSection from "../../components/LinkSection";
import Pagination from "../../components/Pagination";
import Error from "../../components/Error";

import style from "./style.module.scss";

import { VIDEOS_BY_SLUG } from "./querys";
import { Link } from "react-router-dom";

const paginationSize = 9;

export default function VideosPage() {
  const router = useRouteMatch();

  let index;
  let categorySlug;

  const category = lodash.compact(router.url.split("/"));

  if (lodash.nth(category, -2) === "page") {
    index = lodash.nth(category, -1);
    categorySlug = lodash.nth(category, -3);
  } else {
    index = 1;
    categorySlug = lodash.nth(category, -1);
  }

  const videos = useQuery(VIDEOS_BY_SLUG, {
    variables: {
      slug: categorySlug,
      categorySlug: categorySlug,
      offset: (index - 1) * paginationSize,
    },
  });

  if (videos.loading) {
    return null;
  }

  const data = videos.data;

  const videosData = data.videos.nodes;
  if (videosData.length === 0) {
    return <Error />;
  }
  const pageInfo = { ...data.videos.pageInfo };

  pageInfo.current = index;
  pageInfo.size = paginationSize;
  pageInfo.url = `/video/tema/${categorySlug}`;
  const categoryData = data.category;

  return (
    <Fragment>
      <div className="container pt-4">
        <div className="style.directoryContainer">
          <Link className={style.pathLink} to="/">
            Homepage
          </Link>
          <svg className={style.separator} viewBox="0 0 320 512">
            <path
              fill="var(--color-primary)"
              d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
            />
          </svg>
          <Link className={style.pathLink} to={`/video`}>
            Video
          </Link>
        </div>
        <h1 className={style.mainTitle}>{categoryData.name}</h1>
        <h2 className={style.sectionDescription}>{categoryData.description}</h2>
        <div className="row pb-4">
          {videosData.map((videoData) => (
            <div className="col-12 col-md-4 my-5" key={videoData.slug}>
              <VideoCard data={videoData} />
            </div>
          ))}
        </div>
      </div>
      <div className="pt-2 pb-4">
        <Pagination data={pageInfo} />
      </div>
      <div className="mt-5">
        <LinkSection book />
      </div>
    </Fragment>
  );
}
