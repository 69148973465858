import { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

import LocationCard from "../LocationCard";

import style from "./style.module.scss";
import "swiper/swiper.min.css";
import { useState } from "react";

export default function LocationsSlider(props) {
  const swiperRef = useRef();
  const [controlls, setControlls] = useState({ next: false, prev: false });
  const handleControlls = (swiper) => {
    setControlls({ next: !swiper.isEnd, prev: !swiper.isBeginning });
  };
  return (
    <div className={style.container}>
      <div className="container">
        <p className={style.title}>Prossime aperture</p>
        <div className={style.sliderContainer}>
          <div
            className={`${style.iconContainer} ${
              controlls.prev ? "" : "d-none"
            }`}
            onClick={() => swiperRef.current.swiper.slidePrev()}
          >
            <svg
              width="80"
              height="80"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M43 9L20.4142 31.5858"
                stroke="var(--color-background)"
                stroke-width="3"
                stroke-linecap="round"
              />
              <path
                d="M43 54.5858L20.4142 32"
                stroke="var(--color-primary)"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
          </div>
          <div className={style.sliderContent}>
            <div className={style.sliderWrapper}>
              <Swiper
                slidesPerView={3}
                spaceBetween={30}
                loop={true}
                loopedSlides={2}
                centeredSlides={true}
                className={style.slider}
                centerInsufficientSlides={true}
                onSlideChange={handleControlls}
                onResize={handleControlls}
                onInit={handleControlls}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                  },
                  390: {
                    slidesPerView: 1,
                  },
                  400: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  992: {
                    slidesPerView: 3,
                  },
                }}
                ref={swiperRef}
              >
                {props.data.map((location) => (
                  <SwiperSlide>
                    <div className="h-100">
                      <LocationCard data={location} />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
          <div
            className={`${style.iconContainer} ${
              controlls.next ? "" : "d-none"
            }`}
            onClick={() => swiperRef.current.swiper.slideNext()}
          >
            <svg
              width="80"
              height="80"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 9L43.5858 31.5858"
                stroke="var(--color-background)"
                stroke-width="3"
                stroke-linecap="round"
              />
              <path
                d="M21 54.5858L43.5858 32"
                stroke="var(--color-primary)"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}
