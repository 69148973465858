import style from './pagination.module.css';

export default function Pagination(props) {
    function handleClick(value) {
        props.onChangeValue(value);
    }
    return props.current === 1 &&
        !props.data.offsetPagination.hasMore ? null : (
        <div className={style.container}>
            {props.current > 2 ? (
                <div onClick={() => handleClick(props.current - 2)}>
                    <svg className={style.arrow} viewBox='0 0 320 512'>
                        <path
                            fill='var(--color-primary)'
                            d='M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z'
                        />
                    </svg>
                </div>
            ) : null}
            {props.data.offsetPagination.hasPrevious ? (
                <div
                    className={style.itemContainer}
                    onClick={() => handleClick(props.current - 1)}>
                    <p className={style.itemNumber}>{props.current - 1}</p>
                </div>
            ) : null}
            <div className={`${style.itemContainer} ${style.active}`}>
                <p className={style.itemNumber}>{props.current}</p>
            </div>
            {props.data.offsetPagination.hasMore ? (
                <div
                    className={style.itemContainer}
                    onClick={() => handleClick(props.current + 1)}>
                    <p className={style.itemNumber}>{props.current + 1}</p>
                </div>
            ) : null}
            {(props.current + 2) * props.size <
            props.data.offsetPagination.total ? (
                <div onClick={() => handleClick(props.current + 2)}>
                    <svg className={style.arrow} viewBox='0 0 320 512'>
                        <path
                            fill='var(--color-primary)'
                            d='M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z'
                        />
                    </svg>
                </div>
            ) : null}
        </div>
    );
}
