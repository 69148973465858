import _ from "lodash";

import Breadcrumb from "../../components/BreadcrumbTFR";

import style from "./style.module.scss";
import { useEffect, useRef } from "react";
import { render } from "react-dom";

import { Link } from "react-router-dom";

export default function Module(props) {
  return (
    <div className={style.mondoHr}>
      <div className="background-cyan">
        <div className="container pt-4">
          <Breadcrumb uri={"/strumenti/guida-tfr"} />
        </div>
        <div className="container py-4">
          <div className="row">
            <div className="col-12 col-lg-10 offset-lg-1">
              <h1 className={`${style.title} text-center mt-5`}>
                Grazie!
                <br /> La tua risposta è stata inviata
                <br /> correttamente!
              </h1>
              <div className={`${style.contentWrapper} text-center`}>
                <p style={{ lineHeight: "32px" }}>
                  Hai termnato la formazione TFR disponibile fino ad ora,
                  speriamo il contenuto sia stato utile e di suo interesse,
                  presto usciranno nuovi capitoli, scopri l’anteprima!
                </p>
              </div>

              <div
                className={`${style.buttonsContainer} justify-content-evenly`}
              >
                <Link to="/" className={style.nextButton}>
                  Torna a Laborability
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
