import gql from "graphql-tag";

export const VIDEOS = gql`
  query videos($categorySlug: ID!, $offset: Int) {
    videos(where: { offsetPagination: { offset: $offset, size: 12 } }) {
      nodes {
        title
        dateGmt
        slug
        uri
        categories {
          nodes {
            slug
            name
          }
        }
        VideosMeta {
          sottotitolo
          description
          videoUrl
          videoImage {
            link
            sourceUrl
            altText
            mediaDetails {
              sizes {
                height
                width
              }
            }
          }
        }
      }
      pageInfo {
        offsetPagination {
          hasMore
          hasPrevious
          total
        }
      }
    }
    category(id: $categorySlug, idType: SLUG) {
      description
      slug
      name
      parent {
        node {
          name
          slug
        }
      }
    }
  }
`;

// export const VIDEOS = gql`
//   query videos {
//     category(id: "video", idType: SLUG) {
//       name
//       description
//       videos(first: 100) {
//         nodes {
//           title
//           dateGmt
//           slug
//           uri
//           categories {
//             nodes {
//               slug
//               name
//             }
//           }
//           VideosMeta {
//             sottotitolo
//             description
//             videoUrl
//             videoImage {
//               link
//               sourceUrl
//               altText
//               mediaDetails {
//                 sizes {
//                   height
//                   width
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `;
