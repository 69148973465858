import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

import style from "./style.module.scss";
import "swiper/swiper.min.css";
import { Link } from "react-router-dom";
import { useEffect, useRef } from "react";

export default function Jobs(props) {
  const sliderRef = useRef();
  useEffect(() => {
    const interval = setInterval(() => {
      sliderRef.current.swiper.slideNext();
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={style.container}>
      <div className="container">
        <Swiper
          className={`overflow-visible ${style.swiperWrapper}`}
          slidesPerView={1}
          breakpoints={{
            600: {
              slidesPerView: 2,
            },
            992: {
              slidesPerView: 4,
            },
          }}
          loop={true}
          ref={sliderRef}
        >
          {props.jobs &&
            props.jobs.map((job, index) => (
              <SwiperSlide className={style.wrapper} key={index}>
                <div className={style.cardContainer}>
                  <p className={style.title}>{job.title}</p>
                  <p className={style.company}>{job.company}</p>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
}
