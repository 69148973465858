import style from './style.module.css';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useEffect, useState, useLayoutEffect } from 'react';
import { map, update } from 'lodash';

Chart.register(ChartDataLabels);
const formater = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
});

export default function ChartsFondoAperto({ data, reset }) {
    const chartOptions = (isVertical) => ({
        plugins: {
            legend: {
                display: false,
                labels: {
                    color: 'rgb(255, 99, 132)',
                },
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return new Intl.NumberFormat('it-IT', {
                            style: 'currency',
                            currency: 'EUR',
                            maximumFractionDigits: 0,
                            minimumFractionDigits: 0,
                        }).format(tooltipItem.raw);
                    },
                },
            },
            datalabels: {
                // display: isVertical ? true : false,
                // formatter: function (value, context) {
                //     return Math.round(value);
                // },
                anchor: (context) => {
                    const anchor = [];
                    if (
                        context.dataset.data[context.dataIndex] < 0 &&
                        isVertical
                    ) {
                        anchor.push('start');
                    } else {
                        anchor.push('end');
                    }
                    return anchor;
                },
                align: (context) => {
                    const align = [];
                    if (
                        context.dataset.data[context.dataIndex] < 0 &&
                        isVertical
                    ) {
                        align.push('start');
                    } else {
                        align.push('end');
                    }
                    return align;
                },

                rotation: isVertical ? 360 : -90,
                color: '#FE777B',
                formatter: function (value, context) {
                    return formater.format(value);
                },
                labels: {
                    value: {
                        color: '#000000',
                    },
                },
                font: {
                    weight: '500',
                    family: 'Montserrat',
                    size: 9.5,
                },
            },
        },
        responsive: true,
        interaction: {
            intersect: false,
        },
        labels: {
            color: 'red',
        },

        // indexAxis: isVertical ? 'x' : 'y',
        scales: {
            yaxis: {
                id: 'bar-stack',
                stacked: true,
                beginAtZero: true,
                title: {
                    display: false,
                    text: 'psd',
                },

                suggestedMin: data.body.graph.min_value,
                suggestedMax: data.body.graph.max_value,

                ticks: {
                    beginAtZero: true,
                    // stepSize: 35000,
                    callback: function (value) {
                        return formater.format(value);
                    },

                    padding: 10,
                    color: '#BCBCBC',
                    font: {
                        weight: 600,
                        family: 'Montserrat',
                    },
                },
                grid: {
                    ticks: {
                        show: false,
                    },
                    drawBorder: true,
                    borderDash: [7, 4],
                    circular: true,
                    color: '#f3f3f3',
                    // drawBorder: true,
                    color: function (context) {
                        if (context.type === 'tick') {
                            if (context.tick.value === 0) {
                                return '#C4C4C4';
                            } else if (context.tick.value < 0) {
                                return '#f3f3f3';
                            } else if (context.tick.value > 0) {
                                return '#f3f3f3';
                            }
                        }
                        return '#f3f3f3';
                    },
                },
            },

            xaxis: {
                display: isVertical ? true : true,
                rotation: isVertical ? -180 : 180,
                stacked: true,
                ticks: {
                    show: false,
                    color: '#BCBCBC',
                    font: {
                        wheight: '600',
                        size: 12,
                        family: 'Montserrat',
                    },
                    padding: 20,
                    width: 20,
                    rotation: isVertical ? 360 : 360,
                },
                grid: {
                    drawBorder: false,
                    // borderWidth: [2],
                    color: '#f3f3f3',
                    opacity: '0.4',
                },
                gridLines: {
                    zeroLineColor: 'transparent',
                },
            },
        },
    });

    const [isVertical, setIsVertical] = useState(true);
    const barWidth = isVertical ? 55 : 20;
    const chartData = !isVertical
        ? {
              labels: [
                  ['Capitale', 'versato'],
                  ['Interessi', 'maturati'],
                  ['Tasse da', 'versare'],
              ],
              datasets: [
                  {
                      label: '',
                      data: data.body.graph.graph_data[0] ?? [], //[107381, 85167, -41555],
                      stack: 'Stack 0',
                      borderRadius: 8,
                      backgroundColor: ['#0263FF'],
                      borderColor: ['#0263FF'],
                      borderWidth: 1,
                      barThickness: barWidth,
                  },
                  {
                      label: '',
                      data: data.body.graph.graph_data[1] ?? [], //[107381, 150167, -33064],
                      stack: 'Stack 1',
                      borderRadius: 8,
                      backgroundColor: ['#67A1FF'],
                      borderColor: ['#67A1FF'],
                      borderWidth: 1,
                      barThickness: barWidth,
                  },
                  {
                      label: '',
                      data: data.body.graph.graph_data[2] ?? [], //[107381, 85167, -41555],
                      stack: 'Stack 2',
                      borderRadius: 8,
                      backgroundColor: ['#012866'],
                      borderColor: ['#012866'],
                      borderWidth: 1,
                      barThickness: barWidth,
                  },
              ],
          }
        : {
              labels: [
                  'Capitale versato',
                  'Interessi maturati',
                  'Tasse da versare',
              ],
              datasets: [
                  {
                      label: '',
                      data: data.body.graph.graph_data[0] ?? [], //[107381, 85167, -41555],
                      stack: 'Stack 0',
                      borderRadius: 8,
                      backgroundColor: ['#0263FF'],
                      borderColor: ['#0263FF'],
                      borderWidth: 1,
                      barThickness: barWidth,
                  },
                  {
                      label: '',
                      data: data.body.graph.graph_data[1] ?? [], //[107381, 150167, -33064],
                      stack: 'Stack 1',
                      borderRadius: 8,
                      backgroundColor: ['#67A1FF'],
                      borderColor: ['#67A1FF'],
                      borderWidth: 1,
                      barThickness: barWidth,
                  },
                  {
                      label: '',
                      data: data.body.graph.graph_data[2] ?? [], //[107381, 85167, -41555],
                      stack: 'Stack 2',
                      borderRadius: 8,
                      backgroundColor: ['#012866'],
                      borderColor: ['#012866'],
                      borderWidth: 1,
                      barThickness: barWidth,
                  },
              ],
          };
    const [actions, setActions] = useState({});

    const handleChange = (event) => setIsVertical(event.matches);
    useLayoutEffect(() => {
        const watcher = window.matchMedia('(min-width: 768px)');
        handleChange(watcher);
        watcher.addEventListener('change', handleChange);
        return () => watcher.removeEventListener('change', handleChange);
    }, []);
    return (
        <div className={`${style.container} container`}>
            <div className={`row`}>
                <div className={`${style.bgWrapper} `}>
                    <div className={`${style.title}`}>
                        Simula il calcolo del tuo TFR in un fondo pensione
                    </div>
                    <div className={`${style.subtitle}`}>
                        In base ai dati che ci hai fornito, alle ipotesi su
                        crescita della RAL e rendimento del fondo pensione e
                        agli attuali parametri di legge, questo è un possibile
                        scenario sul tuo TFR.
                    </div>
                    <div className={`${style.legendWrapper}`}>
                        <div className={`${style.legendContainer}`}>
                            <div className={`${style.legendContent}`}>
                                <div
                                    className={`${style.wrap} ${style.nowrap}`}>
                                    <div
                                        className={`${style.legendSquare}`}></div>
                                    <div className={`${style.legendTitle}`}>
                                        TFR senza versamento extra
                                    </div>
                                </div>
                                <div
                                    className={`${style.legendNetto} ${style.nowrap}`}>
                                    TFR lordo:{' '}
                                    <span className={`${style.netto}`}>
                                        <b>
                                            {' '}
                                            {formater.format(
                                                data.body.graph.raw_values
                                                    .tfr_senza_versamento_extra
                                                    .tfr_lordo
                                            )}{' '}
                                        </b>
                                    </span>
                                </div>
                                {/* <div className={`${style.divider}`}></div> */}
                                <div
                                    className={`${style.legendNetto} ${style.nowrap}`}>
                                    TFR netto:{' '}
                                    <span className={`${style.netto}`}>
                                        <b>
                                            {' '}
                                            {formater.format(
                                                data.body.graph.raw_values
                                                    .tfr_senza_versamento_extra
                                                    .tfr_netto
                                            )}{' '}
                                        </b>
                                    </span>
                                </div>
                            </div>
                            <div
                                className={`${style.legendContent} ${style.legendContentRow2}`}>
                                <div
                                    className={`${style.legendNetto} ${style.nowrap}`}>
                                    Capitale versato{' '}
                                    <span className={`${style.netto}`}>
                                        <b>
                                            {' '}
                                            {formater.format(
                                                data.body.graph.raw_values
                                                    .tfr_senza_versamento_extra
                                                    .capitale_versato
                                            )}{' '}
                                        </b>
                                    </span>
                                </div>
                                {/* <div className={`${style.divider}`}></div> */}
                                <div
                                    className={`${style.legendNetto} ${style.nowrap}`}>
                                    Interessi maturati{' '}
                                    <span className={`${style.netto}`}>
                                        <b>
                                            {' '}
                                            {formater.format(
                                                data.body.graph.raw_values
                                                    .tfr_senza_versamento_extra
                                                    .interessi_maturati
                                            )}{' '}
                                        </b>
                                    </span>
                                </div>
                                {/* <div className={`${style.divider}`}></div> */}
                                <div
                                    className={`${style.legendNetto} ${style.nowrap}`}>
                                    Tasse da versare{' '}
                                    <span className={`${style.netto}`}>
                                        <b>
                                            {' '}
                                            {formater.format(
                                                data.body.graph.raw_values
                                                    .tfr_senza_versamento_extra
                                                    .tasse_da_versare
                                            )}{' '}
                                        </b>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className={`${style.legendContainer}`}>
                            <div className={`${style.legendContent}`}>
                                <div
                                    className={`${style.wrap} ${style.nowrap}`}>
                                    <div
                                        className={`${style.legendSquare}`}
                                        style={{ background: '#67A1FF' }}></div>
                                    <div className={`${style.legendTitle}`}>
                                        TFR con versamento extra
                                    </div>
                                </div>
                                <div
                                    className={`${style.legendNetto} ${style.nowrap}`}>
                                    TFR lordo:{' '}
                                    <span className={`${style.netto}`}>
                                        <b>
                                            {' '}
                                            {formater.format(
                                                data.body.graph.raw_values
                                                    .tfr_con_versamento_extra
                                                    .tfr_lordo
                                            )}{' '}
                                        </b>
                                    </span>
                                </div>
                                <div className={`${style.divider}`}></div>
                                <div
                                    className={`${style.legendNetto} ${style.nowrap}`}>
                                    TFR netto:{' '}
                                    <span className={`${style.netto}`}>
                                        <b>
                                            {' '}
                                            {formater.format(
                                                data.body.graph.raw_values
                                                    .tfr_con_versamento_extra
                                                    .tfr_netto
                                            )}{' '}
                                        </b>
                                    </span>
                                </div>
                            </div>
                            <div
                                className={`${style.legendContent} ${style.legendContentRow2}`}>
                                <div
                                    className={`${style.legendNetto} ${style.nowrap}`}>
                                    Capitale versato{' '}
                                    <span className={`${style.netto}`}>
                                        <b>
                                            {' '}
                                            {formater.format(
                                                data.body.graph.raw_values
                                                    .tfr_con_versamento_extra
                                                    .capitale_versato
                                            )}{' '}
                                        </b>
                                    </span>
                                </div>
                                {/* <div className={`${style.divider}`}></div> */}
                                <div
                                    className={`${style.legendNetto} ${style.nowrap}`}>
                                    Interessi maturati{' '}
                                    <span className={`${style.netto}`}>
                                        <b>
                                            {' '}
                                            {formater.format(
                                                data.body.graph.raw_values
                                                    .tfr_con_versamento_extra
                                                    .interessi_maturati
                                            )}{' '}
                                        </b>
                                    </span>
                                </div>
                                {/* <div className={`${style.divider}`}></div> */}
                                <div
                                    className={`${style.legendNetto} ${style.nowrap}`}>
                                    Tasse da versare{' '}
                                    <span className={`${style.netto}`}>
                                        <b>
                                            {' '}
                                            {formater.format(
                                                data.body.graph.raw_values
                                                    .tfr_con_versamento_extra
                                                    .tasse_da_versare
                                            )}{' '}
                                        </b>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <hr />
                        {/* for pip */}
                        <div className={`${style.legendContainer}`}>
                            <div className={`${style.legendContent}`}>
                                <div
                                    className={`${style.wrap} ${style.nowrap}`}>
                                    <div
                                        className={`${style.legendSquare}`}
                                        style={{
                                            background: ' #012866',
                                        }}></div>
                                    <div className={`${style.legendTitle}`}>
                                        Piano Individuale Pensionistico
                                    </div>
                                </div>
                                <div
                                    className={`${style.legendNetto} ${style.nowrap}`}>
                                    TFR lordo:{' '}
                                    <span className={`${style.netto}`}>
                                        <b>
                                            {' '}
                                            {formater.format(
                                                data.body.graph.raw_values.pip
                                                    .tfr_lordo
                                            )}{' '}
                                        </b>
                                    </span>
                                </div>
                                <div className={`${style.divider}`}></div>
                                <div
                                    className={`${style.legendNetto} ${style.nowrap}`}>
                                    TFR netto:{' '}
                                    <span className={`${style.netto}`}>
                                        <b>
                                            {' '}
                                            {formater.format(
                                                data.body.graph.raw_values.pip
                                                    .tfr_netto
                                            )}{' '}
                                        </b>
                                    </span>
                                </div>
                            </div>
                            <div
                                className={`${style.legendContent} ${style.legendContentRow2}`}>
                                <div
                                    className={`${style.legendNetto} ${style.nowrap}`}>
                                    Capitale versato{' '}
                                    <span className={`${style.netto}`}>
                                        <b>
                                            {' '}
                                            {formater.format(
                                                data.body.graph.raw_values.pip
                                                    .capitale_versato
                                            )}{' '}
                                        </b>
                                    </span>
                                </div>
                                {/* <div className={`${style.divider}`}></div> */}
                                <div
                                    className={`${style.legendNetto} ${style.nowrap}`}>
                                    Interessi maturati{' '}
                                    <span className={`${style.netto}`}>
                                        <b>
                                            {' '}
                                            {formater.format(
                                                data.body.graph.raw_values.pip
                                                    .interessi_maturati
                                            )}{' '}
                                        </b>
                                    </span>
                                </div>
                                {/* <div className={`${style.divider}`}></div> */}
                                <div
                                    className={`${style.legendNetto} ${style.nowrap}`}>
                                    Tasse da versare{' '}
                                    <span className={`${style.netto}`}>
                                        <b>
                                            {' '}
                                            {formater.format(
                                                data.body.graph.raw_values.pip
                                                    .tasse_da_versare
                                            )}{' '}
                                        </b>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isVertical ? (
                        <Bar
                            key='vertical'
                            id='chart'
                            data={chartData}
                            options={chartOptions(true)}
                            width={'400'}
                            height={'150'}
                        />
                    ) : (
                        <Bar
                            key='horizontal'
                            id='chart'
                            data={chartData}
                            options={chartOptions(false)}
                            width={'150'}
                            height={'180'}
                        />
                    )}
                    <div className={`${style.text}`}>
                        Il capitale versato più gli interessi maturati
                        corrispondono al TFR lordo. Sottraendo le tasse da
                        versare al momento della liquidazione (cioè quando si
                        andrà in pensione), si ottiene il TFR netto.
                    </div>
                    <div className={`${style.buttonWrapper}`}>
                        <button
                            className={`${style.calcolaButton} cursor-pointer btn btn-lg btn-block btn-primary`}
                            onClick={reset}>
                            Ricalcola
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
