import BreadCrumb from "../../components/Breadcrumb";
import BigCardVerticalStrumenti from "../../components/BigCardVerticalStrumenti";

import style from "./style.module.scss";

export default function ArticoliPiuLetti() {
  const data = [
    {
      category: "STRUMENTI",
      title: "Assegno unico e universale: istruzioni per l’uso",
      label: "Guida",
      content:
        "Dal 1° marzo 2022 l'assegno unico universale sostituisce i vecchi assegni familiari. Abbiamo realizzato questa guida per rendere ogni persona in grado di presentare la domanda dell'assegno unico in autonomia.",
      image: "/assets/images/Laborability-cover-assegno-unico.png",
      url: "/strumenti/guida-assegno-unico-universale",
    },
    {
      category: "STRUMENTI",
      title: "Guida al TFR, per investirlo in modo più consapevole",
      label: "Formazione",
      content:
        "Il TFR è una risorsa finanziaria a disposizione di molti lavoratori, che spesso sottovalutata perché non sono chiare le opzioni a disposizione. Se gestito in maniera oculata, può dare un contributo alla propria pensione integrativa.",
      image: "/assets/images/Laborability-cover-formazione-tfr.png",
      url: "/strumenti/guida-tfr",
    },
  ];

  return (
    <div className="container pt-4 pb-4">
      <BreadCrumb uri="/strumenti" />
      <h1 className={style.mainTitle}>Strumenti</h1>
      <h3 className={style.sectionDescription}>
        In questa sezione si trovano diverse risorse per aiutare chi lavora a
        comprendere come funzionano le principali misure disponibili.
      </h3>
      <div className="row">
        {data.map((element) => (
          <div className="col-lg-4 col-md-4">
            <BigCardVerticalStrumenti data={element} />
          </div>
        ))}
      </div>
    </div>
  );
}
