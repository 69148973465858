import PeopleSlider from "../PeopleSlider";
import OldProductsSlider from "../OldProductsSlider";
import ProductCard from "../ProductCard";
import style from "./style.module.scss";

export default function ProductSection(props) {
  return (
    <div className={style.container}>
      <div className="container">
        <div className="row align-items-stretch">
          <div className="col-12 mb-5 col-lg-7 mb-lg-0">
            <PeopleSlider data={props.sliderData} />
          </div>
          <div className="col-12 col-lg-5">
            {/* <ProductCard product={props.product} /> */}
            <OldProductsSlider product={props.sliderData} />
          </div>
        </div>
      </div>
    </div>
  );
}
