import { useQuery } from '@apollo/client';
import { Fragment, useEffect, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import lodash from 'lodash';

import { JOB_DATA } from './querys';

import style from './style.module.scss';
import LinkSection from '../../components/LinkSection';

export default function JobPage() {
  const router = useRouteMatch();

  const slug = lodash.last(lodash.compact(router.url.split('/')));
  const job = useQuery(JOB_DATA, {
    variables: {
      slug,
    },
  });

  if (job.loading) {
    return null;
  }

  const date = new Date(job.data.job.dateGmt);

  return (
    <Fragment>
      <div className={`container pt-4 py-0 px-lg-5 ${style.container}`}>
        <div className='row'>
          <div className={`${style.postColumn} col-12 ${style.postarticle}`}>
            <h1 className={style.mainTitle}>{job.data.title}</h1>
            <div className={`${style.doubleSideFlex} py-3`}>
              <h4 className={style.info}>
                {date.toLocaleString('IT-it', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                })}
              </h4>
            </div>
            <div className={style.mainImage}>
              <img
                className={style.image}
                src={job.data.job.featuredImage.node.sourceUrl}
                alt={job.data.job.featuredImage.node.altText}
              />
            </div>
            <div
              className={style.postWraper}
              dangerouslySetInnerHTML={{
                __html: job.data.job.content,
              }}
            />
          </div>
        </div>
      </div>
      <div className='mt-5 pt-5'>
        <LinkSection video book />
      </div>
    </Fragment>
  );
}
