import { useQuery } from "@apollo/client";
import SmallCardMinimal from "../../components/Small-Card-Minimal-Logo";
import { VIDEO_CORELATI } from "./querys";

import style from "./style.module.scss";

export default function VideoCorelati(props) {
  const { category, id } = props;

  const video = useQuery(VIDEO_CORELATI, {
    variables: {
      slug: category,
      id,
    },
  });

  if (video.loading) {
    return null;
  }

  const videoCorrelatiData = video.data.videos.nodes;

  return (
    <div className={`${style.postColumn} col-12 col-lg-4`}>
      <h5 className={style.sectionTitle}>Video correlati</h5>
      <div className={style.verticalFlex}>
        {videoCorrelatiData.map((data) => (
          <div className="my-3" key={data.slug}>
            <SmallCardMinimal data={data} video />
          </div>
        ))}
      </div>
    </div>
  );
}
