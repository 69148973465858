import style from "./style.module.scss";

export default function HeroBanner(props) {
  const { image, imageMobile, children } = props;
  return (
    <div className={style.container}>
      <div className={style.imageContainer}>
        <img
          src={image}
          alt="Banner"
          className={`${style.image} d-none d-lg-block`}
        />
        <img
          src={imageMobile}
          alt="Banner"
          className={`${style.image} d-lg-none`}
        />
        <div className={style.textOverlay}>
          <div className="container">
            <div className={style.textContainer}>
              <div className="container">
                <span className="La-piattaforma-dedic" className={style.text}>
                  La piattaforma dedicata alla <br /> community dei lavoratori e{" "}
                  <br />
                  professionisti della cura e bellezza
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.content}>{children}</div>
    </div>
  );
}
