import { CADDYS_TAB, BEAUTY_STAR_TAB } from "../actions/navbar";

export function navbarReducer(prevState = { active: "caddys" }, action) {
  switch (action.type) {
    case CADDYS_TAB:
      return { ...prevState, active: "caddys" };
    case BEAUTY_STAR_TAB:
      return { ...prevState, active: "beautystar" };
    default:
      return prevState;
  }
}
