
import { TFR_API_URL } from "../../config";
import _ from "lodash";

import Breadcrumb from "../../components/BreadcrumbTFR";
import Select from "../../components/Tfr/Select";
import Rate from "../../components/Tfr/Rate";

import style from "./style.module.scss";
import { Fragment, useEffect, useRef } from "react";
import { render } from "react-dom";
import Conclusions from "../../components/Tfr/FinalConclusions";

import { useState } from "react";
import { useSelector } from "react-redux";

import { uuidv4 } from "../../tools/helpers";

import { Link } from "react-router-dom";

// const config = {
//   questions: [
//     {
//       title: "Dopo aver concluso il corso, il TFR per me non ha più segreti",
//       solution: 0,
//       options: [
//         "Completamente d’accordo",
//         "Abbastanza d’accordo",
//         "Poco d’accordo",
//         "Per nulla d’accordo",
//       ],
//     },
//     {
//       title:
//         "Dopo aver concluso il corso, penso che cambierò la destinazione del mio TFR",
//       solution: 1,
//       options: [
//         "Completamente d’accordo",
//         "Abbastanza d’accordo",
//         "Poco d’accordo",
//         "Per nulla d’accordo",
//       ],
//     },
//     {
//       title:
//         "Dopo aver concluso il corso, penso di aver comunque bisogno di consultarmi con uno specialista (e.g. consulente finanziario, consulente del lavoro) prima di cambiare le mie scelte sul TFR",
//       solution: 1,
//       options: [
//         "Completamente d’accordo",
//         "Abbastanza d’accordo",
//         "Poco d’accordo",
//         "Per nulla d’accordo",
//       ],
//     },
//     {
//       title:
//         "Mi piacerebbe se il corso diventasse più ricco, aggiungendo altri capitoli più specifici su alcuni aspetti del TFR",
//       solution: 1,
//       options: [
//         "Completamente d’accordo",
//         "Abbastanza d’accordo",
//         "Poco d’accordo",
//         "Per nulla d’accordo",
//       ],
//     },
//     {
//       title:
//         "Mi piacerebbe se al termine del corso mi venissero dati anche dei suggerimenti concreti su come investire il mio TFR",
//       solution: 1,
//       options: [
//         "Completamente d’accordo",
//         "Abbastanza d’accordo",
//         "Poco d’accordo",
//         "Per nulla d’accordo",
//       ],
//     },
//   ],
// };

export default function Module(props) {
  const [state, setState] = useState({});
  const userId = useSelector((state) => state.authReducer.id);

  useEffect(() => {
    const selectContainer = document.getElementById("checkbox-wrapper");
    if (selectContainer) {
      render(<Select />, selectContainer);
    }
  }, []);

  const conclusionsRef = useRef();

  const [newsLetter, setNewsLetter] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [error, setError] = useState(false);
  const [privacyError, setPrivacyError] = useState(false);

  const formName = "Congratulazioni";
  const formType = "Userdata form";

  // const handleSubmit = () => {
  //   let uuid = window.localStorage.getItem("tfr_token");
  //   if (!uuid) {
  //     uuid = uuidv4();
  //     window.localStorage.setItem("tfr_token", uuid);
  //   }

  //   const data = conclusionsRef.current.getState();
  //   if (data?.error) {
  //     // alert('Si prega di compilare l'intero modulo!');
  //     setError(true);
  //     window.scrollTo({ top: 0, behavior: "smooth" });
  //   } else if (!privacy) {
  //     // alert('Si prega di accettare l'informativa sulla privacy!');
  //     // window.scrollTo({ bottom: privacyMsg, behavior: 'smooth' });
  //     setPrivacyError(true);
  //   } else {
  //     const formData = new FormData();
  //     formData.append("name", data.name);
  //     formData.append("email", data.email);
  //     formData.append("occupation", data.use);
  //     formData.append("recommendation", data.rate);
  //     formData.append("course_answers", JSON.stringify(data.state));
  //     formData.append("userId", userId);

  //     fetch(`${TFR_API_URL}/userdata/${uuid}`, {
  //       method: "POST",
  //       body: formData,
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         window.dataLayer = window.dataLayer || [];
  //         window.dataLayer.push({
  //           event: "tfr_invio_form",
  //           form_name: formName,
  //           form_type: formType,
  //         });

  //         setTimeout(function () {
  //           window.location.href = "/strumenti/guida-tfr/grazie";
  //         }, 400);
  //       });
  //     setError(false);
  //     setPrivacyError(false);
  //   }
  // };
  return (
    <div className={style.mondoHr}>
      <div className="background-cyan">
        <div className="container pt-4">
          <Breadcrumb uri={"/strumenti/guida-tfr"} />
        </div>
        <div className="container py-4">
          <div className="row">
            <div className="col-12 col-lg-10 offset-lg-1">
              {error && (
                <div className={style.errorMessage} id="errorMsg">
                  Si prega di compilare l'intero modulo!
                </div>
              )}
              <h1 className={style.title}>{formName}!</h1>
              <div className={style.contentWrapper}>
                <p style={{ paddingBottom: 20 }}>
                  Hai terminato la formazione TFR disponibile fino ad ora,
                  speriamo il contenuto sia stato utile e di tuo interesse,
                  presto usciranno nuovi capitoli, scopri l’anteprima!
                </p>
              </div>

              {/* <Fragment>
                <Conclusions
                  ref={conclusionsRef}
                  config={config}
                  module={props.moduleData}
                />
                <Rate module={props.moduleData} datatype="userdata" />
              </Fragment> */}

              {/* <div className={style.privacyContainer}>
                <div className={style.checkboxWrapper}>
                  <input
                    id="privacyPolicy"
                    type="checkbox"
                    className={style.checkbox}
                    checked={privacy}
                    onChange={(event) => setPrivacy(event.target.checked)}
                    required="required"
                  />
                  <label
                    htmlFor="privacyPolicy"
                    className={style.checkboxLabel}
                  >
                    Ho preso visione della{" "}
                    <a target="_blank" href="/privacy-policy">
                      privacy policy
                    </a>{" "}
                    e presto il consenso al trattamento dei dati per ricevere
                    newsletter e/o comunicazioni commerciali
                  </label>
                </div>
                {privacyError && (
                  <div className={style.errorMessage} id="privacyMsg">
                    Si prega di accettare l'informativa sulla privacy!
                  </div>
                )}
                <div className={style.checkboxWrapper}>
                  <input
                    id='newsLetter'
                    type='checkbox'
                    className={style.checkbox}
                    checked={newsLetter}
                    onChange={(event) =>
                        setNewsLetter(event.target.checked)
                    }
                  />
                  <label
                    htmlFor='newsLetter'
                    className={style.checkboxLabel}>
                    Acconsento all’iscrizione della
                    Newsletter
                  </label>
                </div>
              </div> */}
              <div
                className={`${style.buttonsContainer} justify-content-evenly`}
              >
                <Link to="/" className={style.prevButton}>
                  Torna a Laborability
                </Link>
                {/* <button className={style.nextButton} onClick={handleSubmit}>
                  Invia la tua risposta
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
