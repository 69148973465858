import style from "./style.module.scss";

export default function JobCard(props) {
  const date = new Date(Date.parse(props.data.dateGmt));
  return (
    <a href={props.data.uri}>
      <div className={style.cardContainer}>
        <div className={style.imageContainer}>
          <img
            className={style.image}
            src={props.data.featuredImage?.node.sourceUrl}
            alt={props.data.featuredImage?.node.altText}
          />
        </div>
        <div className={style.cardContent}>
          <div className={style.data}>
            <span className={style.category}>{props.data.company}</span>
            <h6 className={style.date}>
              {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
            </h6>
          </div>
          <a className={style.title} href={props.data.uri}>
            {props.data.title}
          </a>
        </div>
      </div>
    </a>
  );
}
