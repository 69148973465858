import { METRICS_API_URL } from "../../config";
import Swiper from "swiper";
import { useMutation, useQuery } from "@apollo/client";
import { Fragment, useEffect, useState } from "react";
import { useRouteMatch, useHistory, useParams } from "react-router-dom";
import { isMobile, isBrowser, isSafari } from "react-device-detect";
import lodash from "lodash";

import Breadcrumb from "../../components/Breadcrumb";
import LinkSection from "../../components/LinkSection";

import { POST_DATA } from "./querys";

import style from "./style.module.scss";
import ArticoliCorelati from "./articoliCorelati";
import { filterCategory } from "../../tools/filter";
import { SUBMIT_NEWSLETTER } from "./mutations";
import { useSelector } from "react-redux";
import Share from "../../components/Share";

import "swiper/swiper.scss";
import "./style.css";

export default function PostPage() {
  const router = useRouteMatch();
  const routerParams = useParams();
  const routerController = useHistory();
  const [error, setError] = useState([]);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const userId = useSelector((state) => state.authReducer.id);
  const slug = lodash.last(lodash.compact(router.url.split("/")));
  const [device_id, set_device_id] = useState();
  const [loadSlider, setLoadSlider] = useState(false);
  const post = useQuery(POST_DATA, {
    variables: {
      slug,
    },
  });

  const script = document.createElement("script");
  useEffect(() => {
    script.src = "https://www.tiktok.com/embed.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [script]);

  const [nrOfLikes, setNrOfLikes] = useState(0);
  const [liked, setLiked] = useState(false);

  useEffect(() => {
    const links = Array.from(document.querySelectorAll("a.scroll-link"));
    links.forEach((link) => {
      link.addEventListener("click", () => {
        const idString = link.getAttribute("idToScroll");
        document.getElementById(idString).scrollIntoView({
          behavior: "smooth",
        });
      });
      link.removeAttribute("href");
    });

    const hash = routerController.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  }, [loadSlider, routerController]);

  useEffect(() => {
    set_device_id(isBrowser ? 1 : 2);
  }, []);

  const updateSwiperNavs = (swiper, images, leftArrow, rightArrow) => {
    console.log('fretertretert', swiper.activeIndex);

    if(swiper.activeIndex < 1){
        leftArrow.classList.add('d-none');
    }else{
        leftArrow.classList.remove('d-none');
    }

    if(swiper.activeIndex >= (images.length-1)){
        rightArrow.classList.add('d-none');
    }else{
        rightArrow.classList.remove('d-none');
    }
}

  useEffect(() => {
    if (loadSlider) {
      const gallery = document.querySelector(".gallery");
      if (gallery) {
        const images = gallery.querySelectorAll("img");
        gallery.innerHTML = "";
        gallery.classList.add("swiper");
        const swiperWrapper = document.createElement("div");
        swiperWrapper.classList.add("swiper-wrapper");
        gallery.appendChild(swiperWrapper);
        for (let image of images) {
          const slideWrapper = document.createElement("div");
          slideWrapper.classList.add("swiper-slide");
          slideWrapper.appendChild(image);
          swiperWrapper.appendChild(slideWrapper);
        }
        const leftArrow = document.createElement("img");
        leftArrow.src = "/assets/svgs/angle-left-solid.svg";
        leftArrow.classList.add("arrow-left");
        leftArrow.onclick = () => {
          swiper.slidePrev();
        };
        gallery.appendChild(leftArrow);
        const rightArrow = document.createElement("img");
        rightArrow.src = "/assets/svgs/angle-right-solid.svg";
        rightArrow.classList.add("arrow-right");
        rightArrow.onclick = () => {
          swiper.slideNext();
        };
        gallery.appendChild(rightArrow);
        const swiper = new Swiper(".gallery", {
          autoHeight: true,
        });

        // initial
        updateSwiperNavs(swiper, images, leftArrow, rightArrow);

        // on each slide
        swiper.on('transitionStart', () => {
            updateSwiperNavs(swiper, images, leftArrow, rightArrow);
        });

      }
    }
  }, [loadSlider]);

  useEffect(() => {
    if (post.data) {
      const interval = setInterval(() => {
        fetch(
          `${METRICS_API_URL}/${post.data.post.postId}/${userId}/${device_id}`
        );
      }, 5000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [post.data, userId, device_id]);

  const [submitNewsLetter, newsLetter] = useMutation(SUBMIT_NEWSLETTER);
  if (post.loading) {
    return null;
  } else if (post.data.post === null) {
    routerController.push("/");
  }
  const postData = post.data.post;
  const date = new Date(postData.dateGmt);
  let okPost = postData.content;
  var secCounter = 0;
  let listHeadres = "";
  okPost.match(/<h5>(.*?)<\/h5>/g)?.forEach((val) => {
    listHeadres += `<li><a className="target_cls scroll-link" idToScroll="section_${secCounter}" href="#section_${secCounter++}">${val
      .replace(/<span className="hidden(.*?)<\/span>/g, "")
      .replace(/(<([^>]+)>)/gi, "")}</a></li>`;
  });
  secCounter = 0;
  okPost = okPost.replace(/<h5/g, function () {
    return `<h3 id="section_${secCounter++}"`;
  });
  okPost = okPost.split("</h5>").join("</h3>");
  if (!loadSlider) setLoadSlider(true);
  if (newsLetter.data) {
    // window.dataLayer.push({
    //   event: "opinionSubmitted",
    // });
    routerController.push("/contattaci-grazie");
  } else if (newsLetter.error) {
    console.log(error);
  }
  async function handleSubmit(event) {
    event.preventDefault();
    if (email.length === 0 || message.length === 0) {
      setError([{ message: "Si prega di compilare tutti i campi" }]);
    } else {
      const data = {
        email,
        messaggio: message,
      };
      submitNewsLetter({
        variables: { data },
      });
    }
  }

  return (
    <Fragment>
      <div className={`container pt-4 py-0 px-lg-5 ${style.container}`}>

        <Breadcrumb categories={postData.categories} />
        <div className="row">
          <div
            className={`${style.postColumn} col-12 col-lg-8 ${style.postarticle}`}
          >
            <h1 className={style.mainTitle}>{postData.title}</h1>
            <div className={`${style.doubleSideFlex} py-3`}>
              <h4 className={style.info}>
                {date.toLocaleString("IT-it", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              </h4>
              <h4 className={style.info}>
                Tempo di lettura: {postData.readingTime} min
              </h4>
            </div>
            <img
              className={style.mainImage}
              src={postData.featuredImage.node.sourceUrl}
              alt={postData.featuredImage.node.altText}
            />
            <h3 className={style.origin}>
              {postData.comment_foto.commentFoto}
            </h3>
            <h2 className={style.description}>
              {postData.sottotitolo.sottotitolo}
            </h2>
            <Share
              uri={postData.uri}
              id={postData.postId}
              nrOfLikes={nrOfLikes}
              setNrOfLikes={(value) => setNrOfLikes(value)}
              liked={liked}
              setLiked={(value) => setLiked(value)}
            />
            <ul
              className={style.mapContainer}
              dangerouslySetInnerHTML={{
                __html: listHeadres,
              }}
            />
            <div
              className={`${style.postWraper} postWrapper`}
              dangerouslySetInnerHTML={{
                __html: okPost,
              }}
            />
            {postData.Link_Mondo_HR.linkMondoHr ? (
              <div className="text-center my-5">
                <a
                  className={style.mondoHrButton}
                  href={postData.Link_Mondo_HR.linkMondoHr}
                >
                  Scopri di più
                </a>
              </div>
            ) : null}
            <div className={style.tagContainer}>
              {postData.tags.nodes.map((tag) => (
                <a
                  className={style.tag}
                  href={`/tag/${tag.slug}`}
                  key={tag.slug}
                >
                  {tag.name}
                </a>
              ))}
            </div>
            <Share
              className={`d-block d-lg-none`}
              uri={postData.uri}
              id={postData.postId}
              nrOfLikes={nrOfLikes}
              setNrOfLikes={(value) => setNrOfLikes(value)}
              liked={liked}
              setLiked={(value) => setLiked(value)}
            />
          </div>
          <div
            className={`${style.postColumn} col-12 col-lg-4 ${style.postrightside}`}
          >
            <ArticoliCorelati
              id={postData.postId}
              category={
                filterCategory(postData.categories)
                  ? filterCategory(postData.categories).slug
                  : postData.categories.nodes[0].slug
              }
            />
          </div>
        </div>
      </div>
      <div className="mt-5">
        <LinkSection video book />
      </div>
    </Fragment>
  );
}
