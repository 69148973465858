import style from './button.module.css';

export default function Button(props) {
    function handleClick() {
        if (props.onClick) {
            props.onClick();
        }
    }
    return (
        <button
            className={`${style.button} ${
                props.white ? `${style.white} white` : style.blue
            }`}
            onClick={() => handleClick}>
            {props.children}
        </button>
    );
}
