import Modal from "react-bootstrap/Modal";
import { Col, Container, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
// import Image from 'next/dist/client/image';
import arrowUp from "./arrow-up.svg";
import arrowDown from "./arrow-down.svg";
import style from "./style.module.css";
export default function SelectComponent({ moduleData }) {
  //moduleData.tFRModule.parent.node.menuOrder
  const chapterNumber = moduleData
    ? moduleData.tFRModule.parent.node.menuOrder
    : "";

  const [submited, setSubmited] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => setIsOpen(!isOpen);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedValue, setSelectedValue] = useState();

  const onOptionClicked = (value) => () => {
    setSelectedOption(value.option);
    setSelectedValue(value.value);
    setIsOpen(false);
  };

  const formTitle = "Vuoi sapere se a te spetta il TFR?";

  useEffect(() => {
    if (submited) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "tfr_invio_form",
        form_name: `${formTitle}: Capitolo ${chapterNumber}`,
        form_type: "Select form",
      });
    }
  }, [submited]);

  const optionsList = [
    {
      option: " Lavoratore assunto a tempo indeterminato",
      value: true,
    },
    {
      option: "Lavoratore assunto a tempo determinato",
      value: true,
    },
    {
      option: " Lavoratore stagionale",
      value: true,
    },
    {
      option: " Lavoratore in prova",
      value: true,
    },
    {
      option: " Apprendista",
      value: true,
    },
    {
      option: " Lavoratore autonomo",
      value: false,
    },
    {
      option: " Agente di Commercio",
      value: false,
    },
    {
      option: "Lavoratore occasionale - voucher",
      value: false,
    },
    {
      option: "Libero professionista",
      value: false,
    },
    {
      option: "Imprenditore",
      value: false,
    },
    {
      option: " Socio di società di capitali",
      value: false,
    },
    {
      option: " Amministratore di società",
      value: false,
    },
  ];
  const OptionsComponents = ({ optionsData }) => {
    return <div className={`${style.options}`}>{optionsData.option}</div>;
  };

  if (!submited) {
    return (
      <div className={`${style.container} container`}>
        <div className={`row`}>
          <div className={`${style.selectBg} col-lg-12`}>
            <div className={`row`}>
              <div className={`col-lg-10`}>
                <div className={`${style.selectTitle}`}>{formTitle}</div>

                <div className={`${style.selectSubtitle}`}>Scoprilo subito</div>
              </div>
              <div className={`col-lg-9 d-flex align-items-center`}>
                <div className={`${style.dropDownContainer}`}>
                  <div
                    className={
                      `${style.dropDownHeader}` +
                      (isOpen ? ` ${style.headerOpened}` : " ")
                    }
                    onClick={toggling}
                  >
                    {selectedOption ?? "Seleziona il tuo tipo di impiego"}
                    {/* <Image
                                            src={arrowDown}
                                            className={
                                                `${style.image}` +
                                                (isOpen
                                                    ? ` ${style.imageOpened}`
                                                    : ' ')
                                            }></Image> */}
                  </div>
                  {isOpen && (
                    <div className={`${style.dropDownOptionsWrapper}`}>
                      <div className={`${style.dropDownOptions}`}>
                        {optionsList.map((options, index) => (
                          <div key={index} onClick={onOptionClicked(options)}>
                            <OptionsComponents optionsData={options} />
                          </div>
                        ))}
                        {/* {firstSelectData.map(
                                                    (options1, index) => (
                                                        <div
                                                            key={index}
                                                            onClick={onOptionClicked(
                                                                options1
                                                            )}>
                                                            <FirstSelectDataOptions
                                                                firstOptionsData={
                                                                    options1
                                                                }
                                                            />
                                                        </div>
                                                    )
                                                )} */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={`col-lg-3`}>
                <button
                  className={`${style.selectButton} btn btn-lg btn-block btn-primary  col-12 `}
                  onClick={() => setSubmited(true)}
                >
                  Calcola
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return selectedValue ? (
      <div className={`${style.container} container`}>
        <div className={`row`}>
          <div className={`${style.selectBg} col-lg-12`}>
            <div className={`row`}>
              <div className={`col-lg-10 `}>
                <div className={`${style.selectTitle}`}>Risultato</div>

                <div
                  className={`${style.selectSubtitle} mb-0`}
                  style={{
                    color: "rgba(60, 170, 130, 1)",
                    fontWeight: 700,
                  }}
                >
                  Hai diritto al TFR!
                </div>
                <div className={`${style.selectSubtitle}`}>
                  In base alle tua tisposta “
                  <b style={{ fontWeight: 700 }}>
                    Dipendente con contratto indeterminato full-time
                  </b>
                  ”, abbiamo rilevato che hai diritto percepire il TFR{" "}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button
                className={`${style.selectButton} btn btn-lg btn-block btn-primary`}
                onClick={() => setSubmited(false)}
              >
                Ricalcola
              </button>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className={`${style.container} container`}>
        <div className={`row`}>
          <div className={`${style.selectBg} col-lg-12`}>
            <div className={`row`}>
              <div className={`col-lg-10 `}>
                <div className={`${style.selectTitle}`}>Risultato</div>

                <div
                  className={`${style.selectSubtitle} mb-0`}
                  style={{
                    color: "rgba(234, 59, 59, 1)",
                    fontWeight: 700,
                  }}
                >
                  Non hai diritto al TFR!
                </div>
                <div className={`${style.selectSubtitle}`}>
                  In base alle tua tisposta “
                  <b style={{ fontWeight: 700 }}>Partita iva</b>
                  ”, abbiamo rilevato che non hai diritto di percepire il TFR.
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button
                className={`${style.selectButton} btn btn-lg btn-block btn-primary`}
                onClick={() => setSubmited(false)}
              >
                Ricalcola
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
