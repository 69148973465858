import gql from "graphql-tag";

export const JOB_DATA = gql`
  query jobData($slug: ID!) {
    job(id: $slug, idType: SLUG) {
      title
      slug
      dateGmt
      content
      featuredImage {
        node {
          sourceUrl
          altText
        }
      }
    }
  }
`;
