import gql from "graphql-tag";

export const NAVBAR_MENU = gql`
  query navbarMenu {
    menus {
      nodes {
        id
        name
        slug
        menuItems(first: 200) {
          nodes {
            parentId
            id
            path
            label
          }
        }
      }
    }
  }
`;

export const SEARCH_CATEGORY = gql`
  query searchCategory($slug: ID!) {
    category(id: $slug, idType: SLUG) {
      uri
    }
  }
`;
