import { TFR_API_URL } from "../../../config";

import { useState } from "react";
import style from "./style.module.css";
import { useSelector } from "react-redux";

import { uuidv4 } from "../../../tools/helpers";

export default function Rate({ module, datatype = "chapterdata" }) {
  const [state, setState] = useState({});
  const [value, setValue] = useState(null);
  const [submited, setSubmited] = useState(false);

  const userId = useSelector((state) => state.authReducer.id);

  const handleChange = (value, key) => {
    const temp = { ...state };
    temp[key] = value;
    setState(temp);

    //setSubmited(false);
  };

  const handleSubmit = () => {
    //const uuid = window.localStorage.getItem('tfr_token');

    let uuid = window.localStorage.getItem("tfr_token");
    if (!uuid) {
      uuid = uuidv4();
      window.localStorage.setItem("tfr_token", uuid);
    }

    const formNameElem = document.querySelector("[data-form-name]");
    const formName = formNameElem
      ? formNameElem.getAttribute("data-form-name") || "Moduli interessati"
      : "Moduli interessati";
    const chapterName = module
      ? "Capitolo " + module.tFRModule.parent.node.menuOrder
      : "";
    const formAndChapterName = `${formName}: ${chapterName}`;

    const formType = "Survey form";

    const formData = new FormData();
    if (datatype == "chapterdata") {
      formData.append(
        "chapter_id",
        module ? module.tFRModule.parent.node.databaseId : null
      );
    }

    formData.append("module_interested", value);
    formData.append("userId", userId);

    fetch(`${TFR_API_URL}/${datatype}/${uuid}`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setSubmited(true);

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "tfr_invio_form",
          form_name: formAndChapterName,
          form_type: formType,
        });
      });
  };

  return (
    <div className={style.container}>
      <p
        data-form-name={"Quali strumenti potrebbero interessarti in futuro?"}
        className={style.title}
      >
        Quali strumenti potrebbero interessarti in futuro?
      </p>
      <p className={style.description}>
        Lavoriamo sempre allo sviluppo di nuovi strumenti per aiutarti a capire
        meglio il mondo del lavoro. Quale ti piacerebbe fosse il prossimo? Puoi
        selezionare una sola preferenza:
      </p>
      <div className="row">
        <div className="col-12 col-md-4">
          <div className={style.optionWrapper}>
            <input
              className={style.indicator}
              type="radio"
              name="rate"
              id="Pensione"
              onChange={(event) => setValue(event.target.id)}
              disabled={submited}
            />
            <label className={style.label} htmlFor="Pensione">
              Pensione
            </label>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className={style.optionWrapper}>
            <input
              className={style.indicator}
              type="radio"
              name="rate"
              id="Fondi di sanità integrativa"
              onChange={(event) => setValue(event.target.id)}
              disabled={submited}
            />
            <label
              className={style.label}
              htmlFor="Fondi di sanità integrativa"
            >
              Fondi di sanità integrativa
            </label>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className={style.optionWrapper}>
            <input
              className={style.indicator}
              type="radio"
              name="rate"
              id="Assicurazioni sul lavoro"
              onChange={(event) => setValue(event.target.id)}
              disabled={submited}
            />
            <label className={style.label} htmlFor="Assicurazioni sul lavoro">
              Assicurazioni sul lavoro
            </label>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className={style.optionWrapper}>
            <input
              className={style.indicator}
              type="radio"
              name="rate"
              id="Riscatto degli anni di studio"
              onChange={(event) => setValue(event.target.id)}
              disabled={submited}
            />
            <label
              className={style.label}
              htmlFor="Riscatto degli anni di studio"
            >
              Riscatto degli anni di studio
            </label>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className={style.optionWrapper}>
            <input
              className={style.indicator}
              type="radio"
              name="rate"
              id="Cessione del quinto"
              onChange={(event) => setValue(event.target.id)}
              disabled={submited}
            />
            <label className={style.label} htmlFor="Cessione del quinto">
              Cessione del quinto
            </label>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className={style.optionWrapper}>
            <input
              className={style.indicator}
              type="radio"
              name="rate"
              id="Sussidi di disoccupazione"
              onChange={(event) => setValue(event.target.id)}
              disabled={submited}
            />
            <label className={style.label} htmlFor="Sussidi di disoccupazione">
              Sussidi di disoccupazione
            </label>
          </div>
        </div>
        <div className="col-12 col-md-4 pb-4">
          <div className={style.optionWrapper}>
            <input
              className={style.indicator}
              type="radio"
              name="rate"
              id="Certificazione ISEE"
              onChange={(event) => setValue(event.target.id)}
              disabled={submited}
            />
            <label className={style.label} htmlFor="Certificazione ISEE">
              Certificazione ISEE
            </label>
          </div>
        </div>
      </div>
      {/* <div className={style.buttonsContainer}>
                <button
                    className={style.prevButton}
                    type='submit'
                    onClick={() => handleSubmit()}
                    disabled={submited}>
                    Invia
                </button>
            </div> */}
    </div>
  );
}
