import style from "./style.module.css";
import SucessImg from "./Sucsses-sign.svg";
import ErrorImg from "./Error-Sign.svg";

const AdvDisadvComponent = ({ optionsData }) => {
  return (
    <>
      <div className={`${style.content}`}>
        {optionsData.advantage ? (
          <img src={SucessImg} className={`${style.imageContainer}`} />
        ) : (
          <img src={ErrorImg} className={`${style.imageContainer}`} />
        )}
        <div className={`${style.text}`}>{optionsData.option}</div>
      </div>
    </>
  );
};
export default function VantagiComponent(props) {
  const { title, elements } = JSON.parse(props.shortcodeConfig);
  return (
    <div className={`${style.container} container`}>
      <div className={`row`}>
        <div className={`col-lg-10 offset-lg-1`}>
          <div className={`${style.bgContainer}`}>
            <div className={`${style.contentWrapper}`}>
              <div className={`${style.header}`}>{title}</div>
              {elements.map((cases, i) => (
                <AdvDisadvComponent index={i + 1} optionsData={cases} key={i} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
