import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { Fragment, useState } from "react";
import { storeToken } from "../../store/actions/auth";
import { SEND_EMAIL } from "./querys";
import { store } from "../../store";

import style from "./style.module.scss";

export default function ResetPage(props) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [succes, setSucces] = useState(false);
  const [email, setEmail] = useState("");

  const [submitRESET] = useMutation(SEND_EMAIL);

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    submitRESET({
      variables: {
        email: email,
      },
    })
      .then(() => {
        setError(null);
        setSucces(true);
      })
      .catch((error) => {
        console.log(error);
        setError("Nessun Utente Registrato Con Quell`Indirizzo E-Mail");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Fragment>
      <div className={style.background}>
        <form className={style.form} action="submit" onSubmit={handleSubmit}>
          <h5 className={style.title}>Reimposta la password</h5>
          <h6 className={style.description}>
            Crea una nuova password per il tuo account
          </h6>
          {Boolean(error) && (
            <div className="error">
              <div className={style.error}>{error}</div>
            </div>
          )}
          {Boolean(succes) && (
            <div className={style.succes}>
              Ti abbiamo inviato un link per reimpostare la password. Controlla
              nella tua email.
            </div>
          )}
          <label className={style.label}>Nome utente o e-mail</label>

          <input
            required="required"
            className={style.input}
            type="email"
            placeholder="Inserisci nome utente o e-mail"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <button className={style.button} disabled={loading}>
            {loading ? (
              <svg viewBox="0 0 512 512">
                <path
                  fill="currentColor"
                  d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"
                />
              </svg>
            ) : (
              "Reimposta la password"
            )}
          </button>
        </form>
      </div>
    </Fragment>
  );
}
