export const CADDYS_TAB = "CADDYS_TAB";
export const BEAUTY_STAR_TAB = "BEAUTY_STAR_TAB";

export function caddysTab() {
  return {
    type: CADDYS_TAB,
  };
}

export function beautyStarTab() {
  return {
    type: BEAUTY_STAR_TAB,
  };
}
