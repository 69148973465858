import { useQuery } from "@apollo/client";
import { Fragment } from "react";

import Breadcrumb from "../../components/Breadcrumb";
import Slider from "../../components/Slider";
import PodcastCard from "../../components/PodcastCard";
import LinkSection from "../../components/LinkSection";

import { PODCASTS } from "./querys";

import style from "./style.module.scss";
import { Link } from "react-router-dom";

export default function PodcastPageIndex() {
  const podcasts = useQuery(PODCASTS);

  if (podcasts.loading) {
    return null;
  }

  const podcastData = podcasts.data.category.children.nodes;

  const categoryData = podcasts.data.category;

  return (
    <Fragment>
      <div className="container pt-4">
        <Breadcrumb uri="/podcast" />
        <h1 className={style.mainTitle}>{categoryData.name}</h1>
        <h3 className={style.sectionDescription}>{categoryData.description}</h3>
        {podcastData.map((data) =>
          data.podcasts.nodes.length ? (
            <div key={data.slug}>
              <div className={`${style.doubleSideFlex} pt-5 pb-4`}>
                <Link to={`/podcast/tema/${data.slug}`}>
                  <h2 className={style.sectionTitle}>{data.name}</h2>
                </Link>
                <Link
                  className={`${style.moreArticles} d-none d-md-block`}
                  to={`/podcast/tema/${data.slug}`}
                >
                  Vedi altro
                </Link>
              </div>
              <p className={style.sectionDescription}>{data.description}</p>
              <Slider className="row" more={`/podcast/tema/${data.slug}`}>
                {data.podcasts.nodes.map((data) => (
                  <div className="col-12 col-md-4 my-3" key={data.slug}>
                    <PodcastCard data={data} />
                  </div>
                ))}
              </Slider>
            </div>
          ) : null
        )}
      </div>
      <div className="mt-5 pt-5">
        <LinkSection book />
      </div>
    </Fragment>
  );
}
