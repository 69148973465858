import { WEBSITE_URL, BACKEND_URL } from "../../config";
import style from "./style.module.scss";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Clipboard from "react-clipboard.js";

export default function Share(props) {
  const nrOfLikes = props.nrOfLikes;
  const setNrOfLikes = props.setNrOfLikes;
  const liked = props.liked;
  const setLiked = props.setLiked;

  const { id, uri } = props;

  const [visible, setVisible] = useState(false);
  const userId = useSelector((state) => state.authReducer.id);
  useEffect(() => {
    fetch(`${BACKEND_URL}/wp-json/laborability/v1/get_likes/${id}/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        setNrOfLikes(data.count);
        setLiked(data.is_liked);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleLike = () => {
    fetch(`${BACKEND_URL}/wp-json/laborability/v1/like-post/${id}/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        setLiked(data);
        if (data) {
          setNrOfLikes(nrOfLikes + 1);
        } else {
          setNrOfLikes(nrOfLikes - 1);
        }
      });
  };

  return (
    <div
      className={`${style.wraper} 
        ${props.uri.startsWith("/video") ? style.shareVideo : ""}
      `}
    >
      <div className={style.container}>
        <div className={style.iconContainer} onClick={handleLike}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="27"
            viewBox="0 0 30 27"
            fill="none"
          >
            <path
              d="M20.9986 21.41C20.366 21.8971 19.8183 22.3168 19.3388 22.684C17.1908 24.33 16.4673 24.8844 15.6144 25.7436C15.4446 25.9145 15.2224 26 14.9999 26C14.7776 26 14.5552 25.9145 14.3856 25.7436C13.7389 25.0921 13.1335 24.6258 11.9271 23.6968C11.1873 23.127 10.2664 22.418 8.98975 21.4009L8.9675 21.3833C5.60494 18.7023 1 15.0305 1 9.74232C1 4.34399 5.25303 1.00263 9.19342 1.00263C11.422 1.00263 13.4478 1.99653 14.9999 3.8269C16.5535 1.99495 18.5821 1 20.8142 1C24.751 1 29 4.32193 29 9.68856C29 15.2539 23.795 19.2586 20.9986 21.41"
              stroke={liked ? "#ED4956" : "#ffffff"}
              fill={liked ? "#ED4956" : "none"}
              stroke-width="1.5"
            />
          </svg>
          <p className={style.likeCount} translate="no">
            {nrOfLikes} Likes
          </p>
        </div>
        <div className={style.seperator} />
        <div className={style.iconContainer}>
          <a
            target="_blank"
            href={`mailto:?body=${WEBSITE_URL}${uri}`}
            rel="noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="19"
              viewBox="0 0 24 19"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.3435 15.5085C22.3435 16.5135 21.532 17.3313 20.5349 17.3313H3.46448C2.46732 17.3313 1.65583 16.5135 1.65583 15.5085V3.49146C1.65583 3.24707 1.70508 3.01414 1.79223 2.80093L9.01295 11.0573C9.76824 11.921 10.857 12.4174 12 12.4174C13.1424 12.4174 14.2311 11.921 14.9877 11.0573L22.2078 2.80093C22.2949 3.01414 22.3435 3.24707 22.3435 3.49146V15.5085ZM20.5349 1.66936C20.677 1.66936 20.814 1.68718 20.9466 1.71836L13.7449 9.95314C13.3034 10.4578 12.6675 10.748 12 10.748C11.3325 10.748 10.6966 10.4578 10.2551 9.95314L3.05336 1.71836C3.18598 1.68718 3.32302 1.66936 3.46448 1.66936H20.5349ZM20.5349 0H3.46448C1.55415 0 0 1.56626 0 3.49146V15.5085C0 17.4337 1.55415 19 3.46448 19H20.5349C22.4458 19 24 17.4337 24 15.5085V3.49146C24 1.56626 22.4458 0 20.5349 0Z"
                fill="white"
              />
            </svg>
          </a>
        </div>
        <div className={style.seperator} />
        <div
          className={style.iconContainer}
          onClick={() => {
            setVisible(true);
            setTimeout(() => setVisible(false), 2000);
          }}
        >
          <Clipboard
            style={{ border: "none", background: "none" }}
            data-clipboard-text={`${WEBSITE_URL}${uri}`}
          >
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
              <mask
                id="mask0_572_2069"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="9"
                width="17"
                height="16"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 9H17V25H0V9Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_572_2069)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.1918 9.94161C14.8137 9.56629 14.3949 9.25736 13.9539 9L12.8134 10.1295C12.3945 10.5452 12.274 11.1401 12.4373 11.6665C12.6408 11.7906 12.8324 11.9361 13.0055 12.1077C14.212 13.3031 14.212 15.2476 13.006 16.443L8.36709 21.0407C7.78445 21.6183 7.00812 21.9364 6.18027 21.9364C5.35191 21.9364 4.57609 21.6183 3.99448 21.0418C2.788 19.8459 2.788 17.9014 3.99345 16.706L6.715 14.0088C6.3683 13.13 6.18182 12.1874 6.18182 11.2141C6.18182 10.8434 6.21685 10.4788 6.26888 10.1188L1.80818 14.5399C-0.602727 16.9296 -0.602727 20.8176 1.80818 23.2074C3.01312 24.4023 4.5967 25.0002 6.18027 25.0002C7.76385 25.0002 9.34742 24.4023 10.5524 23.2074L15.1918 18.6091C17.6027 16.2193 17.6027 12.3314 15.1918 9.94161Z"
                  fill="white"
                />
              </g>
              <mask
                id="mask1_572_2069"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="8"
                y="0"
                width="17"
                height="16"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8 0H25V16H8V0Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask1_572_2069)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M23.1919 1.7923C21.987 0.597434 20.4034 0 18.8198 0C17.2362 0 15.6526 0.597434 14.4472 1.7923L9.80819 6.3905C7.39727 8.78024 7.39727 12.6682 9.80819 15.0579C10.1863 15.4332 10.6051 15.7421 11.0461 16L12.1861 14.87C12.6055 14.4543 12.726 13.8594 12.5622 13.333C12.3587 13.2089 12.1671 13.0634 11.9945 12.8923C10.788 11.6964 10.788 9.75196 11.9935 8.55658L16.633 3.95889C17.2151 3.38137 17.9914 3.06376 18.8198 3.06376C19.6476 3.06376 20.424 3.38137 21.0056 3.95838C22.2121 5.15376 22.2121 7.09823 21.0061 8.29361L18.284 10.9913C18.7734 12.2341 18.9187 13.5771 18.7296 14.8827L23.1919 10.4597C25.6028 8.07047 25.6028 4.18204 23.1919 1.7923Z"
                  fill="white"
                />
              </g>
            </svg>
          </Clipboard>
        </div>
      </div>
      <div
        className={style.messageContainer}
        style={{ opacity: visible ? 1 : 0 }}
      >
        <p>Copiato negli appunti</p>
      </div>
    </div>
  );
}
