import { Link } from "react-router-dom";
import style from "./small-card-minimal.module.css";

export default function SmallCardVertical(props) {
  const date = new Date(props.data.dateGmt);
  const selectStyle = () => {
    if (props.video) {
      return style.blue;
    } else if (props.podcast) {
      return style.yellow;
    } else {
      return style.white;
    }
  };
  return (
    <Link to={props.data.uri}>
      <div className={style.cardContainer}>
        <div className={style.imageContainer}>
          <img
            className={style.image}
            src={
              props.data.VideosMeta?.videoImage.sourceUrl ||
              props.data.featuredImage?.node.sourceUrl
            }
            alt={
              props.data.VideosMeta?.videoImage.altText ||
              props.data.featuredImage?.node.altText
            }
          />
          <Link className={style.playIcon} to={props.data.uri}>
            {props.video ? (
              <svg viewBox="0 0 448 512">
                <path
                  fill="var(--color-secondary)"
                  d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
                ></path>
              </svg>
            ) : null}
            {props.podcast ? (
              <img
                src="/assets/svgs/podcast.svg"
                alt="icon"
                height="19"
                width="12"
              />
            ) : null}
          </Link>
        </div>
        <div className={`${style.cardContent} ${selectStyle()}`}>
          <h3>
            <Link className={style.title} to={props.data.uri}>
              {props.data.title}
            </Link>
          </h3>
          <p className={style.date}>
            {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
          </p>
        </div>
      </div>
    </Link>
  );
}
