import style from './style.module.css';
import textImg from './Group_339.svg';
export default function TextImage() {
    return (
        <div className={`${style.container}`}>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-lg-6'>
                        <p className={`${style.title}`}>
                            Descrizione del corso
                        </p>

                        <p className={`${style.content}`}>
                            Frequentando questo corso, imparerai tutto ciò che
                            c’è da sapere sul TFR e su quali sono i possibili
                            usi che se ne possono fare. Tratteremo il tema in
                            modo semplice e diretto, con piccoli moduli della
                            durata di pochi minuti, che puoi seguire anche di
                            tanto in tanto nei ritagli di tempo. Al termine del
                            corso, sarai capace di prendere una scelta
                            consapevole su una componente fondamentale delle tue
                            finanze.
                        </p>
                    </div>
                    <div
                        className={`${style.imageContainer} col-lg-5 offset-lg-1 mt-5 mt-sm-0`}>
                        <div className={`${style.imageWrapper} `}>
                            <img src={textImg} className={style.image} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
