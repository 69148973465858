import style from "./style.module.scss";
import { filterCategory } from "../../tools/filter";
import { Link } from "react-router-dom";

export default function FeaturedVideoCard(props) {
  const date = new Date(Date.parse(props.data.dateGmt));

  const categoryData = filterCategory(props.data.categories);

  return (
    <div className={style.cardContainer}>
        <Link to={props.data.uri}>
          <img
            className={style.image}
            src={props.data.VideosMeta?.videoImage.sourceUrl}
            alt={props.data.VideosMeta?.videoImage.altText}
          />
        </Link>
      <div className={style.cardContent}>
        <div className={style.data}>

          { categoryData && (
            <Link 
              className={style.category}
              to={categoryData?.uri}
            >
              {categoryData?.name}
          </Link>
          )}

          <h6 className={style.date}>
            {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
          </h6>
        </div>
        <h3>
          <Link className={style.title} to={props.data.uri}>
            {props.data.title}
          </Link>
        </h3>
        <Link className={style.content} to={props.data.uri}>
          {props.data?.VideosMeta?.description}
        </Link>
      </div>
    </div>
  );
}
