import gql from "graphql-tag";

export const PODCASTS_BY_SLUG = gql`
  query podcastsBySlug($slug: ID!, $categorySlug: String, $offset: Int) {
    podcasts(
      where: {
        categoryName: $categorySlug
        offsetPagination: { offset: $offset, size: 9 }
      }
    ) {
      nodes {
        dateGmt
        title
        uri
        slug
        categories {
          nodes {
            slug
            uri
            name
          }
        }
        podcast_meta {
          podcastAudioUrl
        }
        content
        featuredImage {
          node {
            sourceUrl
            mediaDetails {
              sizes {
                height
                width
              }
            }
          }
        }
      }
      pageInfo {
        offsetPagination {
          hasMore
          hasPrevious
          total
        }
      }
    }
    category(id: $slug, idType: SLUG) {
      description
      slug
      name
      parent {
        node {
          name
          slug
        }
      }
    }
  }
`;
