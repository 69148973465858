import { gql } from "@apollo/client";

export const MARK_NOTIFICATION_READ = gql`
  mutation MarkNotificationAsRead($id: String!) {
    markNotificationAsRead(input: {id: $id}) {
      notification {
        id
        title
        body
        link
        is_sent
        is_read
      }
    }
  }
`;