export const WEBSITE_URL = `https://dmo.laborability.com`;
export const BACKEND_URL = `https://lbt-for.angel1.al`;

export const GRAPHQL_API_URL = `${BACKEND_URL}/graphql`;
export const TFR_API_URL = `${BACKEND_URL}/wp-json/tfr/v1`;
export const PARDOT_API_URL = `${BACKEND_URL}/wp-json/pardot/v1`;
export const METRICS_API_URL = `${BACKEND_URL}/wp-json/laborability/v1/metrics`;
export const CLICK_TRACKING_API_URL = `${BACKEND_URL}/wp-json/laborability/v1/click_tracking`;
export const NOTIFICATIONS_API_URL = `${BACKEND_URL}/wp-json/dmo/v1/notifications`;

export const VAPID_KEY =
  "BF0goMcSRgc3b9yzQXEm0fv7j-pwoNzlLNWtGuJA2VoZSmYJ845luj2XsWbPYXsQWKVgjj44TbfM_9G2j23cklU";

export const THEMES = {
  caddys: {
    primary: "#01549c",
    secondary: "#ff007b",
  },
  beautystar: {
    primary: "#230144",
    secondary: "#fab3ac",
  },
};
