import { gql, useMutation } from "@apollo/client";

export const SEND_EMAIL = gql`
  mutation SendEmail($email: String!) {
    sendPasswordResetEmail(input: { username: $email }) {
      clientMutationId
    }
  }
`;

export const RESET = gql`
  mutation ResetUserPassword(
    $key: String!
    $login: String!
    $password: String!
  ) {
    resetUserPassword(
      input: { key: $key, login: $login, password: $password }
    ) {
      clientMutationId
    }
  }
`;

export const useResetUserPasswordMutation = () => {
  const [mutation, mutationResults] = useMutation(RESET);

  const resetUserPassword = (key, login, password) => {
    return mutation({
      variables: {
        key,
        login,
        password,
      },
    });
  };

  return { resetUserPassword, results: mutationResults };
};
