import gql from "graphql-tag";

export const VIDEOS_BY_SLUG = gql`
  query videosById($slug: String, $categorySlug: ID!, $offset: Int) {
    videos(
      where: {
        categoryName: $slug
        offsetPagination: { offset: $offset, size: 9 }
      }
    ) {
      nodes {
        title
        uri
        dateGmt
        slug
        categories {
          nodes {
            slug
            name
          }
        }
        VideosMeta {
          sottotitolo
          description
          videoImage {
            link
            sourceUrl
            altText
            mediaDetails {
              sizes {
                height
                width
              }
            }
          }
        }
      }
      pageInfo {
        offsetPagination {
          hasMore
          hasPrevious
          total
        }
      }
    }
    category(id: $categorySlug, idType: SLUG) {
      description
      slug
      name
      parent {
        node {
          name
          slug
        }
      }
    }
  }
`;
