import gql from "graphql-tag";

export const QUERY_ARTICOLI_PIU_LETTI = gql`
  query GetArticoliPiuLetti {
    posts(where: { onlySticky: true }, first: 12) {
      nodes {
        dateGmt
        title
        uri
        slug
        databaseId
        readingTime
        categories(where: { exclude: "64" }) {
          edges {
            node {
              slug
              name
              uri
              parent {
                node {
                  name
                  uri
                  slug
                  parent {
                    node {
                      name
                    }
                  }
                }
              }
            }
          }
        }
        author {
          node {
            name
          }
        }
        sottotitolo {
          sottotitolo
        }
        featuredImage {
          node {
            link
            sourceUrl
            altText
            mediaDetails {
              sizes {
                height
                width
              }
            }
          }
        }
      }
    }
  }
`;
