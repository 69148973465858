import TfrGuide from "../../components/Tfr/Tfr-Guide";
import TextImage from "../../components/Tfr/Text-Image";
import Dropdown from "../../components/Tfr/Dropdown";

import style from "./style.module.scss";

import { useQuery } from "@apollo/client";
import { TFR_DATA } from "./querys";

export default function GuidaTfr() {
  const chapters = useQuery(TFR_DATA);

  return (
    <div className="pt-4 pb-4">
      <TfrGuide modules={chapters.data} />
      <TextImage />
      <div className="container">
        <div className="row">
          <div className="col-lg-10">
            <div className={style.formazioneTitle}>Contenuto del corso</div>
            {chapters.data?.tFRModules?.nodes.map((chapter, index) => (
              <div className="my-2" key={index}>
                <Dropdown data={chapter} isActive={index === 0} index={index} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
