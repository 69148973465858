import style from "./slider.module.css";
import React, { useEffect, useRef, useState } from "react";
import disableScroll from "disable-scroll";
import { Link } from "react-router-dom";

const activeWidth = 976;

export default function Slider(props) {
  const [curentWidth, setCurrentWidth] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [dragValue, setDragValue] = useState(0);
  const [cardWidht, setCardWidth] = useState(0);
  const [childrens, setChildrens] = useState(new Array());
  const [onSlide, setOnSlide] = useState(false);
  const [dragStart, setDragStart] = useState(0);
  const [curentDragPosition, setCurentDragPosition] = useState(0);
  const [activeSlide, setActiveSlide] = useState(false);
  const container = useRef();
  const indicators = [...Array(childrens.length).keys()];
  useEffect(() => {
    window.addEventListener("resize", () => {
      setCurrentWidth(window.innerWidth);
    });
    setCurrentWidth(window.innerWidth);
  }, []);
  useEffect(() => {
    setCardWidth(container.current?.getBoundingClientRect().width);
    const rawChildren = props.children.map((children, index) =>
      React.cloneElement(children, {
        className: `${style.sliderCard} ${onSlide ? style.inTransition : null}`,
        style: {
          transform: `translateX(-${
            activeIndex !== 0
              ? activeIndex * cardWidht * 0.9 +
                2 * activeIndex * 10 +
                dragValue -
                (activeIndex === indicators.length - 1
                  ? 0.1 * cardWidht
                  : 0.05 * cardWidht)
              : 0 + dragValue
          }px)`,
        },
        key: index,
      })
    );
    if (props.more) {
      rawChildren.push(
        React.cloneElement(
          <div>
            <img
              className={style.moreImage}
              src={props.moreSrc ?? "/assets/images/more-image.jpg"}
              alt="More background"
            />
            <Link className={style.moreText} to={props.more}>
              Vedi altro
              <svg className={style.leftArrow} viewBox="0 0 320 512">
                <path
                  fill="currentColor"
                  d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
                />
              </svg>
            </Link>
          </div>,
          {
            className: `${style.sliderCard} ${style.shade} ${
              onSlide ? style.inTransition : null
            } d-md-none`,
            style: {
              transform: `translateX(-${
                activeIndex !== 0
                  ? activeIndex * cardWidht * 0.9 +
                    2 * activeIndex * 10 +
                    dragValue -
                    (activeIndex === indicators.length - 1
                      ? 0.1 * cardWidht
                      : 0.05 * cardWidht)
                  : 0 + dragValue
              }px)`,
            },
            key: rawChildren.length,
          }
        )
      );
    }
    setChildrens(rawChildren);
  }, [curentWidth, activeIndex, dragValue]);
  function handleStart(event) {
    setDragStart(event.targetTouches[0].clientX);
    setOnSlide(true);
  }
  function handleMove(event) {
    if (onSlide) {
      setCurentDragPosition(event.targetTouches[0].clientX);
    }
    if (Math.abs(dragValue) > 100) {
      setActiveSlide(true);
    }
  }
  function handleStop() {
    setActiveSlide(false);
    setOnSlide(false);
    if (Math.abs(dragValue / cardWidht) > 0.2) {
      if (dragValue / cardWidht > 0) {
        setActiveIndex(activeIndex + Math.ceil(dragValue / cardWidht));
      } else {
        setActiveIndex(activeIndex + Math.floor(dragValue / cardWidht));
      }
    }
    setDragValue(0);
  }
  useEffect(() => {
    if (activeSlide) {
      disableScroll.on();
    } else {
      disableScroll.off();
    }
  }, [activeSlide]);
  useEffect(() => {
    if (activeIndex > childrens.length - 1 && activeIndex !== 0) {
      setActiveIndex(childrens.length - 1);
    }
  }, [activeIndex]);
  useEffect(() => {
    if (
      (activeIndex >= indicators.length - 1 &&
        dragStart - curentDragPosition >= 0) ||
      (activeIndex === 0 && dragStart - curentDragPosition <= 0)
    ) {
      setDragValue(0);
    } else {
      setDragValue(dragStart - curentDragPosition);
    }
  }, [curentDragPosition]);
  return (
    <>
      <div className="d-block d-md-none">
        <div className={style.container}>
          <div
            className={style.cardContainer}
            onTouchStart={(event) => handleStart(event)}
            onTouchMove={(event) => handleMove(event)}
            onTouchEnd={(event) => handleStop(event)}
          >
            {childrens}
          </div>
          <div className={style.indicatorContainer} ref={container}>
            {indicators.map((index) => (
              <div
                className={`${style.indicator} ${
                  index === activeIndex ? style.active : null
                }`}
                onClick={() => setActiveIndex(index)}
                key={index}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="d-none d-md-block">
        <div className={props.className}>{props.children}</div>
      </div>
    </>
  );
}
