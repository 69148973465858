import _ from "lodash";

import BreadcrumbTFR from "../../components/BreadcrumbTFR";
import Header from "../../components/Tfr/Header";
import ExtraComponent from "../../components/Tfr/ExtraComponent";
import Select from "../../components/Tfr/Select";
import Rate from "../../components/Tfr/Rate";
import FormNewsletter from "../../components/Tfr/FormNewsletter";
import FormLeadGenerali from "../../components/Tfr/Form-Lead-Generali";

import { useState } from "react";

import { uuidv4 } from "../../tools/helpers";

import { useQuery } from "@apollo/client";
import { TFR_HEADER_DATA } from "./querys";
import { TFR_MODULE_QUERY } from "./querys";

import style from "./style.module.scss";
import { Fragment, useEffect } from "react";
import { render } from "react-dom";
import Conclusions from "../../components/Tfr/Conclusions";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";

export default function Module(props) {
  const [uuid, setUuid] = useState(null);

  const headerData = useQuery(TFR_HEADER_DATA);

  const router = useRouteMatch();
  const slug = router.url;
  const moduleData = useQuery(TFR_MODULE_QUERY, {
    variables: {
      slug,
    },
  });

  useEffect(() => {
    let uuid = window.localStorage.getItem("tfr_token");
    if (!uuid) {
      uuid = uuidv4();
      window.localStorage.setItem("tfr_token", uuid);
    }

    setUuid(uuid);

    const selectContainer = document.getElementById("checkbox-wrapper");
    if (selectContainer) {
      render(<Select moduleData={moduleData} />, selectContainer);
    }

    const timer = setTimeout(() => {
      const mobileTableElem = document.querySelector(".table-mobile");
      //make sure it exists.
      if (mobileTableElem) {
        mobileTableElem
          .querySelectorAll(".item-header")
          .forEach(function (headerItem, index) {
            var parentLiElem = headerItem.closest("li");

            if (index === 0) {
              parentLiElem.classList.add("opened");
            }

            //for each header, handle click event
            headerItem.addEventListener("click", function (evt) {
              //add or remove an "opened" class in the parent <li> element
              parentLiElem.classList.toggle("opened");
            });
          });
      }
    }, 1000);
  }, []);

  return (
    <div className={style.mondoHr}>
      <style
        dangerouslySetInnerHTML={{
          __html: `q
            .active{
                display:block;
            }
            .hidden{
                display:none;
            }
             li{
                font-size:18px;
             }
             li::marker{
                font-size:18px;
             }
             .style_indicator__3i1hu{
                min-width: 24px;
             }

             @media screen and (max-width: 768px) {
                .style_content__18U5m > div:first-of-type {
                    width: 40px;
                }
                .style_text__3GVHI {
                    max-width: 100% !important;
                    flex: 1;
                }
                .style_bgContainer__20BxS{
                    padding: 40px 24px;
                }
                .style_content__18U5m{
                    margin-bottom: 24px;
                }
                .style_content__18U5m:last-of-type{
                    margin-bottom: 0px;
                }
                .background-cyan > .container .container.undefined {
                    padding-right: 0px;
                    padding-left: 0px;
                }
            }
            @media screen and (min-width: 768px) {
                .table-mobile{
                    display: none;
                }
            }

            /* youtube 16/9 */
            .wp-embed-aspect-16-9.aligncenter .wp-block-embed__wrapper{
                height:0;
                padding-bottom:56.25%;
                position:relative;
            }
            .wp-embed-aspect-16-9.aligncenter .wp-block-embed__wrapper iframe{
                height:100%;
                left:0;
                position:absolute;
                top:0;
                width:100%
            }`,
        }}
      />
      <div className="background-cyan">
        <div className="container pt-4">
          <BreadcrumbTFR />
        </div>
        <div className="container py-4">
          <Header
            data={headerData}
            state={{
              chapter: moduleData.data?.tFRModule?.parent.node.menuOrder,
              module: moduleData.data?.tFRModule?.menuOrder,
            }}
          />
          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
              <p className={style.moduleNumber}>
                {moduleData.data?.tFRModule?.shortcodeName !== "conclusion"
                  ? `Modulo ${moduleData.data?.tFRModule?.menuOrder}`
                  : `Riassunto capitolo ${moduleData.data?.tFRModule?.parent.node.menuOrder}`}
              </p>

              <h1 className={style.title}>
                {moduleData.data?.tFRModule?.title}
              </h1>
              {moduleData.data?.tFRModule?.shortcodeName !== "conclusion" && (
                <div className={style.durationContainer}>
                  <span className={style.durationLabel}>DURATA: </span>{" "}
                  <span className={style.duration}>
                    {moduleData.data?.tFRModule?.moduleLength}
                  </span>
                </div>
              )}

              <div
                className={style.contentWrapper}
                dangerouslySetInnerHTML={{
                  __html: moduleData.data?.tFRModule?.content,
                }}
              />

              {moduleData.data?.tFRModule?.shortcodeName === "conclusion" && (
                <Fragment>
                  <div className={style.buttonsContainer}>
                    <Link
                      to={`/${
                        moduleData.data?.tFRModule?.moduleNavs.next &&
                        moduleData.data?.tFRModule?.moduleNavs.next.length > 1
                          ? moduleData.data?.tFRModule?.moduleNavs.next
                          : "strumenti/guida-tfr/congratulazioni"
                      }`}
                      className={style.nextButton}
                    >
                      {moduleData.data?.tFRModule?.moduleNavs.next.length !== 0
                        ? " Vai al prossimo capitolo"
                        : "Vai avanti"}
                    </Link>
                  </div>
                  <Conclusions
                    config={JSON.parse(
                      moduleData.data?.tFRModule?.shortcodeConfig
                    )}
                    module={moduleData}
                  />
                  <Rate module={moduleData} />
                </Fragment>
              )}

              {/* {moduleData.data?.tFRModule?.showNewsletterForm && (
                <FormNewsletter />
              )} */}

              {moduleData.data?.tFRModule?.shortcodeName.length !== 0 && (
                <ExtraComponent
                  uuid={uuid}
                  title={moduleData.data?.tFRModule?.title}
                  shortcodeName={moduleData.data?.tFRModule?.shortcodeName}
                  shortcodeConfig={moduleData.data?.tFRModule?.shortcodeConfig}
                  chapterNumber={
                    moduleData.data?.tFRModule?.parent.node.menuOrder
                  }
                />
              )}

              <div className={style.buttonsContainer}>
                <Link
                  to={`/${
                    moduleData.data?.tFRModule?.moduleNavs.prev ??
                    "strumenti/guida-tfr"
                  }`}
                  className={style.prevButton}
                >
                  Torna indietro
                </Link>

                <Link
                  to={`/${
                    moduleData.data?.tFRModule?.moduleNavs.next.length !== 0
                      ? moduleData.data?.tFRModule?.moduleNavs.next
                      : "strumenti/guida-tfr/congratulazioni"
                  }`}
                  className={style.nextButton}
                >
                  {moduleData.data?.tFRModule?.shortcodeName === "conclusion" &&
                  moduleData.data?.tFRModule?.moduleNavs.next.length != 0
                    ? "Vai al prossimo capitolo"
                    : "Vai avanti"}
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* {moduleData.data?.tFRModule?.showLeadGenerationForm && (
          <FormLeadGenerali />
        )} */}
      </div>
    </div>
  );
}
