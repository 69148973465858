import { TFR_API_URL } from "../../../config";
import Modal from "react-bootstrap/Modal";
import { Col, Container, Row } from "react-bootstrap";
import { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import style from "./style.module.css";
import SelectInputComponent from "../SelectInput";

import { uuidv4 } from "../../../tools/helpers";
import { useHistory } from "react-router-dom";

const inputContent = [
  {
    question: "Quanti anni hai?",
    key: "age",
    type: "number",
  },
  {
    question: "Che tipo di lavoratore sei?",
    key: "work_type",
    type: "custom-select",
  },
  {
    question: "Da quani anni lavori come dipendente?",
    key: "work_experience",
    type: "number",
  },
  {
    question: "Cosa hai fatto fino ad ora con il tuo TFR?",
    key: "tfr_use_before",
    type: "custom-select",
  },
  {
    question: "Come mai sei interessato a questa formazione?",
    key: "interest_reason",
    type: "custom-select",
  },
  {
    question: "Source",
    key: "source",
    type: "hidden",
  },
  {
    question: "User Id",
    key: "userId",
    type: "hidden",
  },
];

const formName = "Formazione personalizzata";
const formType = "Userdata form";

export default function TfrGuide({ modules }) {
  const [state, setState] = useState({});
  const [error, setError] = useState(null);

  const activeTab = useSelector((state) => state.navbarReducer.active);
  const userId = useSelector((state) => state.authReducer.id);

  useEffect(() => {
    setState({
      source: activeTab,
      userId: userId,
    });
  }, []);

  const handleSubmit = (event) => {
    let uuid = window.localStorage.getItem("tfr_token");
    if (!uuid) {
      uuid = uuidv4();
      window.localStorage.setItem("tfr_token", uuid);
    }

    event.preventDefault();
    if (Object.keys(state).length === inputContent.length) {
      const form = new FormData();
      Object.keys(state).forEach((key) => {
        form.append(key, state[key]);
      });

      const options = {
        method: "POST",
        body: form,
      };

      fetch(`${TFR_API_URL}/userdata/${uuid}`, options)
        .then((response) => response.json())
        .then((response) => {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "tfr_invio_form",
            form_name: formName,
            form_type: formType,
          });

          setTimeout(function () {
            router.push(modules.tFRModules.nodes[0].children.nodes[0].uri);
          }, 400);
        })
        .catch((err) => console.error(err));
    } else {
      setError("Tutti i campi sono richiesti!");
    }
  };

  const router = useHistory();

  const handleStart = () => {
    if (!window.localStorage.getItem("tfr_token")) {
      window.localStorage.setItem("tfr_token", uuidv4());
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "tfr_inizio_formazione",
    });
    router.push(modules.tFRModules.nodes[0].children.nodes[0].uri);
  };

  const setDataReceivedFromChild = (contentKey, optionItem) => {
    const temp = {
      ...state,
    };
    temp[contentKey] = optionItem.option;
    setState(temp);
  };

  return (
    <div className={`${style.container}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <p className={`${style.title}`}>
              Guida al TFR, per investirlo in modo più consapevole
            </p>
            <p className={`${style.subtitle}`}></p>
            <p className={`${style.content}`}>
              Al termine della carriera lavorativa o alla fine di ogni
              esperienza lavorativa, ciascun lavoratore ha diritto a ricevere il{" "}
              <b>trattamento di fine rapporto.</b>
              In alcuni casi si tratta di somme importanti, a maggior ragione se
              la carriera lavorativa è intercorsa per molti anni con una stessa
              azienda. Il lavoratore può decidere, sin dall’inizio,
              <b> dove e come custodire o investire il TFR.</b>
            </p>
            <div className="col-lg-7 offset-lg-0 col-sm-12 offset-sm-0 col-md-8 offset-md-2">
              <button
                className={`${style.articoloButton} btn btn-lg btn-block btn-primary guida-button col-12 mt-4 mb-4 mt-lg-5 mb-lg-5`}
                type="submit"
                onClick={handleStart}
              >
                Inizia la formazione
              </button>
            </div>
          </div>

          <div className="col-lg-6 mb-5 mt-5">
            <div className={`${style.cardWrapper} card h-100`}>
              <form
                onSubmit={handleSubmit}
                className={`${style.cardBody} card-body `}
              >
                <p className={`${style.cardTitle} mt-4`}>{formName}</p>
                <p className={style.description}>
                  Rispondi a queste semplici domende e ti consiglieremo il
                  percorso più utile alle tue esigenze di formazione.
                </p>
                {error && (
                  <div className="error">
                    <div className={style.error}>{error}</div>
                  </div>
                )}
                <div className={`row`}>
                  {inputContent.map((content, index) => (
                    <div
                      key={index}
                      className={`${style.taxSection} col-lg-6 col-md-6`}
                      style={{
                        height: content.type == "hidden" ? "0" : "inherit",
                        overflow:
                          content.type == "hidden" ? "hidden" : "inherit",
                        marginBottom:
                          content.type == "hidden" ? "0" : "inherit",
                      }}
                    >
                      {/* HIDDEN FIELD */}
                      {content.type == "hidden" ? (
                          <input
                            id={content.key}
                            name="input-name"
                            className={`${style.input} form-control`}
                            value={state[content.key]}
                            type={content.type}
                          />
                      ) : (
                        <div className={`${style.taxSection}`}>
                          <div className={style.hoverContent}>
                            <label
                              htmlFor={content.key}
                              className={style.question}
                            >
                              {content.question}
                            </label>
                          </div>

                          {content.type == "custom-select" &&
                          content.key == "work_type" ? (
                            <SelectInputComponent
                              contentKey={content.key}
                              optionsList={[
                                {
                                  option: "Lavoratore a tempo determinato",
                                  value: true,
                                },
                                {
                                  option: "Lavoratore a tempo indeterminato",
                                  value: true,
                                },
                                {
                                  option: "Libero professionista",
                                  value: true,
                                },
                                {
                                  option: "Altro",
                                  value: true,
                                },
                              ]}
                              propFunctionFromParent={setDataReceivedFromChild}
                            />
                          ) : content.type == "custom-select" &&
                            content.key == "tfr_use_before" ? (
                            <SelectInputComponent
                              contentKey={content.key}
                              optionsList={[
                                {
                                  option: "L'ho tenuto liquido in azienda",
                                  value: true,
                                },
                                {
                                  option:
                                    "L'ho versato in un fondo pensione aperto",
                                  value: true,
                                },
                                {
                                  option:
                                    "L'ho versato nel fondo pensione di categoria",
                                  value: true,
                                },
                                {
                                  option: "Non lo so",
                                  value: true,
                                },
                                {
                                  option: "Non ho mai maturato TFR",
                                  value: true,
                                },
                              ]}
                              propFunctionFromParent={setDataReceivedFromChild}
                            />
                          ) : content.type == "custom-select" &&
                            content.key == "interest_reason" ? (
                            <SelectInputComponent
                              contentKey={content.key}
                              optionsList={[
                                {
                                  option:
                                    "Non so nulla sul TFR, voglio informarmi",
                                  value: true,
                                },
                                {
                                  option:
                                    "So qualcosa sul TFR, mi interessa approfondire",
                                  value: true,
                                },
                                {
                                  option:
                                    "Mi sento già preparato sul TFR, ora voglio scoprirne tutti i segreti",
                                  value: true,
                                },
                                {
                                  option:
                                    "Sono al mio primo impiego e voglio prendere una scelta consapevole sul mio TFR",
                                  value: true,
                                },
                                {
                                  option:
                                    "Sto per andare in pensione e vorrei capire come mi sarà liquidato il TFR",
                                  value: true,
                                },
                                {
                                  option:
                                    "Sto per cambiare lavoro e vorrei capire cosa succederà al mio TFR",
                                  value: true,
                                },
                              ]}
                              propFunctionFromParent={setDataReceivedFromChild}
                            />
                          ) : (
                            <input
                              id={content.key}
                              name={content.key}
                              className={`${style.input} form-control`}
                              min={1}
                              max={99}
                              value={state[content.key]}
                              type={content.type}
                              onChange={(event) => {
                                const temp = {
                                  ...state,
                                };
                                temp[content.key] = event.target.value;
                                setState(temp);
                              }}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                <div
                  className={`d-flex align-items-center justify-content-center`}
                >
                  <div
                    className={`${style.buttonContainer} mt-auto col-lg-7 col-md-8 col-sm-12 col-12`}
                  >
                    <button
                      className={`${style.button}  btn btn-lg btn-block btn-primary col-12 mt-3 mb-3`}
                      type="submit"
                    >
                      Inizia il percorso
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
