import { useQuery } from "@apollo/client";
import { Fragment } from "react";
import { useRouteMatch } from "react-router";
import lodash from "lodash";

import Breadcrumb from "../../components/Breadcrumb";
import LinkSection from "../../components/LinkSection";
import Pagination from "../../components/Pagination";
import PodcastCard from "../../components/PodcastCard";
import Error from "../../components/Error";

import { PODCASTS_BY_SLUG } from "./querys";

import style from "./style.module.scss";

const paginationSize = 9;

export default function PodcastsPage() {
  const router = useRouteMatch();

  const category = lodash.compact(router.url.split("/"));

  let index;
  let categorySlug;

  if (lodash.nth(category, -2) === "page") {
    index = lodash.nth(category, -1);
    categorySlug = lodash.nth(category, -3);
  } else {
    index = 1;
    categorySlug = lodash.nth(category, -1);
  }

  const podcasts = useQuery(PODCASTS_BY_SLUG, {
    variables: {
      slug: categorySlug,
      categorySlug,
      offset: (index - 1) * paginationSize,
    },
  });

  if (podcasts.loading) {
    return null;
  }

  if (!podcasts.data.category) {
    return <Error />;
  }

  const podcastsData = podcasts.data.podcasts.nodes;

  const pageInfo = { ...podcasts.data.podcasts.pageInfo };

  pageInfo.current = index;
  pageInfo.size = paginationSize;
  pageInfo.url = `/podcast/${categorySlug}`;

  const categoryData = podcasts.data.category;

  return (
    <Fragment>
      <div className="container pt-4">
        <Breadcrumb uri={`/podcast`} />
        <h1 className={style.mainTitle}>{categoryData.name}</h1>
        <h2 className={style.sectionDescription}>{categoryData.description}</h2>
        <div className="row pb-4">
          {podcastsData.map((videoData) => (
            <div className="col-12 col-md-4 my-5" key={videoData.slug}>
              <PodcastCard data={videoData} />
            </div>
          ))}
        </div>
      </div>
      <div className="pt-2 pb-4">
        <Pagination data={pageInfo} />
      </div>
      <div className="mt-5">
        <LinkSection book />
      </div>
    </Fragment>
  );
}
